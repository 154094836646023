/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Stack,
  Label,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  ComboBox,
  IComboBoxOption,
  ITag,
  Dropdown,
  IDropdownOption,
  Checkbox,
  Toggle,
  Dialog,
  DialogType,
} from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import _ from "lodash";

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from '../../../Helpers/GenUtil2';
import { eq, safeTrim, NVL, isNull } from '../../../Helpers/GenUtil2';
import * as AppHelper from '../../../Helpers/AppHelper';
import * as GraphDataService from '../../../Services/Altamira/GraphDataService';
import { insertItem, updateItem } from '../../../Services/GraphDataService';
import * as StaticData from "../../../StaticData/Altamira/StaticData";
import * as Config from '../../../Helpers/ConfigData';
import { useStorageExpires } from '../../../Helpers/UseStorage';
import * as ConvertToXml from '../../../Services/Altamira/ConvertToXml';
import * as BigConsts from "../../../Helpers/BigConsts";

import { RichTextArea } from '../../Controls/RichTextArea';
import { AjaxPicker } from '../../Controls/AjaxPicker';

import { ANEntity } from '../../../Models/Altamira/ANEntity';
import { ANType } from '../../../Models/Altamira/ANType';
import { ANVendor } from '../../../Models/Altamira/ANVendor';
import { ANLSCaseManager } from '../../../Models/Altamira/ANLSCaseManager';
import { ANCurrency } from '../../../Models/Altamira/ANCurrency';
import { ANUser } from '../../../Models/Altamira/ANUser';
import { ANConnection, ANConnections } from '../../../Models/Altamira/ANConnection';
import { ANNote, Fields as ANNoteFields } from '../../../Models/Altamira/ANNote';
import { ANTypeOfCost } from '../../../Models/Altamira/ANTypeOfCost';
import { ProposedCostsDetailsItem } from '../../../Models/Altamira/GridModels/ProposedCostsDetails1';
import { BorrowerInfoItem } from '../../../Models/Altamira/GridModels/BorrowerInfo1';
import { LoanInfoItem } from '../../../Models/Altamira/GridModels/LoanInfo1';
import { AssetInfoItem } from '../../../Models/Altamira/GridModels/AssetInfo1';
import { ConnectionDetailsItem } from '../../../Models/Altamira/GridModels/ConnectionDetails1';
import { AssetConnDetail } from '../../../Models/Altamira/GridModels/AssetConnDetail';
import { FluOption, SimpleUserInfo } from '../../../Models/AppModels';

import { ANConnectionDetail } from './ANConnectionDetail';
import { ANConnectionDetailRO } from './ANConnectionDetailRO';
import { BorrowerInfoBody } from './BorrowerInfoBody';
import { LoanInfoBody } from './LoanInfoBody';
import { AssetInfoBody } from './AssetInfoBody';
import { ProposedCostsBody } from './ProposedCostsBody';
import { ANAssetConnDetailRO } from './ANAssetConnDetailRO';

export interface IANDetailProps { }

export const ANDetail: React.FunctionComponent<IANDetailProps> = (props: React.PropsWithChildren<IANDetailProps>) => {

  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: ANDetail]");
  }, []);


  const today = new Date();

  let __tracking: string = '';
  let __badFields = "";

  const params = useParams();

  const { instance, accounts } = useMsal();


  //#region 'msal instance/accounts/identity related'
  //-------------------------

  useEffect(() => {
    // welcome user message
    if (accounts && accounts.length > 0) {
      console.log(`Welcome [${accounts[0].username}]`, accounts, accounts[0]);
    }
  }, [accounts]);


  useEffect(() => {
    // msal instance info
    if (instance != null) {
      console.log("Msal_instance", instance);
    }
  }, [instance]);


  let memoAdmOvrAvail = (() => {
    // admin overrides should only be available for certain users
    if (accounts && accounts.length > 0) {
      let parts = Consts.admNamesWhiteList;
      let curUsername = GenUtil.safeTrim(accounts[0].username);
      for (let i = 0; i < parts.length; i++) {
        const el = GenUtil.safeTrim(parts[i]);
        if (GenUtil.contains(curUsername, el)) return true;
      }
    }
    return false;
  })();


  let memoCurUserDispName = (() => {
    // should return display name, or username
    if (memoAdmOvrAvail && Consts.admOvrUsername())
      return Consts.admOvrUsername();
    else
      return accounts.length > 0 ? (accounts[0].name ? accounts[0].name : accounts[0].username) : '';
  })();


  let memoCurUsername = (() => {
    // should return email address
    if (memoAdmOvrAvail && Consts.admOvrUsername())
      return Consts.admOvrUsername();
    else
      return accounts.length > 0 ? accounts[0].username : '';
  })();

  //#endregion


  //#region 'state for ANNote ID'
  //-------------------------

  // const [stateANItemId, setStateANItemId] = useState<number>(0); // will be 0 or real item integer

  // useEffect(() => {
  //   // get the url "id" from the route, using safeToNumber will return 0 when "new", otherwise the integer passed
  //   setStateANItemId(GenUtil.safeToNumber(params.id));
  // }, [params]);

  // const memoIsNewItem = useMemo<boolean>(() => stateANItemId <= 0, [stateANItemId]);

  const stateANItemId: number = GenUtil.safeToNumber(params.id);

  const memoIsNewItem: boolean = stateANItemId <= 0;

  //#endregion


  //#region 'state for loading'
  //-------------------------

  const [statePageLoading, setStatePageLoading] = useState<boolean>(true);
  const [stateLoadingANItem, setStateLoadingANItem] = useState<boolean>(true);
  const [stateLoadingConnection, setStateLoadingConnection] = useState<boolean>(true);
  const [stateSaving, setStateSaving] = useState<boolean>(false);

  // useEffect(() => {
  //   // scroll to top of page when loading
  //   window.scrollTo(0, 0);
  // }, [statePageLoading, stateLoadingANItem]);

  //#endregion


  //#region 'setup prevent "leave" warnings'
  //-------------------------

  const setupNavAwayWarning = () => {
    // make sure user cannot navigate away easily losing all their form changes
    resetWinDirty();
    if (Consts.disableNavAwayWarning) {
      return;
    }
    else {
      (window as any).addEventListener("beforeunload", function (e: any) {
        if (getWinDirty()) {
          // default message if left unset: 'Changes you made may not be saved.'
          let confirmationMessage = '';
          (e || window.event).returnValue = confirmationMessage; //Gecko + IE
          return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        }
      });
    }
  }

  useEffect(() => {
    // setup the warning on page load
    setupNavAwayWarning();

    // set the form as dirty after N secs
    // #todo maybe we should only set the form as dirty if the fields are actually touched?
    setTimeout(() => {
      console.log('Timer: Setting form as touched');
      setWinDirty();
    }, 10 * 1000);
  }, []);

  function setWinDirty() {
    (window as any).dirty = true;
  }

  function resetWinDirty() {
    (window as any).dirty = false;
  }

  function getWinDirty() {
    if (Consts.disableNavAwayWarning) return false;
    else return !!((window as any).dirty);
  }

  //#endregion


  //#region 'get lookup data (from cache or Graph/SharePoint)'
  //-------------------------

  const [cacheANListData, setCacheANListData] = useStorageExpires<any[]>('cacheANListDataAltamirav2', [], true, Consts.cacheExpiresMinutes);

  const [stateAllANEntities, setStateAllANEntities] = useState<ANEntity[]>([]);
  const [stateAllANTypes, setStateAllANTypes] = useState<ANType[]>([]);
  const [stateAllANVendors, setStateAllANVendors] = useState<ANVendor[]>([]);
  const [stateAllANLSCaseManagers, setStateAllANLSCaseManagers] = useState<ANLSCaseManager[]>([]);
  const [stateAllANUsers, setStateAllANUsers] = useState<ANUser[]>([]);
  const [stateAllTypeOfCosts, setStateAllTypeOfCosts] = useState<ANTypeOfCost[]>([]);
  const [stateAllANCurrencys, setStateAllANCurrencys] = useState<ANCurrency[]>([]);
  const [stateANNotesListWebUrl, setStateANNotesListWebUrl] = useState<string>('');


  useEffect(function loadLookupDataFromSP() {
    // load all Lookup Data from SP
    let ignore = false;

    if (Consts.cacheEnabled && cacheANListData.length > 0) {
      console.log('Cache enabled and prior data was saved, use cache', cacheANListData);

      let [ents, antypes, vendors, lscm, anusers, tocs, currs, anList] = cacheANListData;

      setStateAllANEntities(ents);
      setStateAllANTypes(antypes);
      setStateAllANVendors(vendors);
      setStateAllANLSCaseManagers(lscm);
      setStateAllANUsers(anusers);
      setStateAllTypeOfCosts(tocs);
      setStateAllANCurrencys(currs);
      setStateANNotesListWebUrl(anList);

      setStatePageLoading(false);

    }
    else {
      console.log('Cache is not enabled, or cache is expired, or list data missing, load lists using graph');

      setTimeout(async () => {

        let [ents, antypes, vendors, lscm, anusers, tocs, currs, anList] = await Promise.all([
          GraphDataService.getANEntities(accounts, instance),
          GraphDataService.getANTypes(accounts, instance),
          GraphDataService.getANVendors(accounts, instance),
          GraphDataService.getANLSCaseManagers(accounts, instance),
          GraphDataService.getANUsers(accounts, instance),
          GraphDataService.getANTypeOfCosts(accounts, instance),
          GraphDataService.getANCurrencys(accounts, instance),
          GraphDataService.getANNotesList(accounts, instance),
        ]);

        let err = false;

        if (ents.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Entities from SharePoint: Msg=${ents.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Entities', ents);
          err = true;
        }

        if (antypes.httpStatus >= 400) {
          AppHelper.toastError(`Error loading AN Types from SharePoint: Msg=${antypes.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading ANTypes', antypes);
          err = true;
        }

        if (vendors.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Vendors from SharePoint: Msg=${vendors.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Vendors', vendors);
          err = true;
        }

        if (lscm.httpStatus >= 400) {
          AppHelper.toastError(`Error loading LSCaseManagers from SharePoint: Msg=${lscm.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading LSCaseManagers', lscm);
          err = true;
        }

        if (anusers.httpStatus >= 400) {
          AppHelper.toastError(`Error loading AN Users from SharePoint: Msg=${anusers.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading AN Users', anusers);
          err = true;
        }

        if (tocs.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Type Of Costs from SharePoint: Msg=${tocs.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Type Of Costs', tocs);
          err = true;
        }

        if (currs.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Currencys from SharePoint: Msg=${currs.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Currencys', currs);
          err = true;
        }

        if (anList.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Advisory Notes list from SharePoint: Msg=${anList.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, 'Error loading Advisory Notes list', anList);
          err = true;
        }

        if (err) return;

        // sorting (optional)
        ents.value = _.sortBy(ents.value, o => { return o.fields.Title });
        antypes.value = _.sortBy(antypes.value, o => { return o.fields.Title }); // Title = AN Type name
        vendors.value = _.sortBy(vendors.value, o => { return o.fields.Title }); // Title = VendorName
        lscm.value = _.sortBy(lscm.value, o => { return o.fields.Title }); // Title = User displayname
        anusers.value = _.sortBy(anusers.value, o => { return o.fields.Title });
        tocs.value = _.sortBy(tocs.value, o => { return o.fields.Title });
        currs.value = _.sortBy(currs.value, o => { return o.fields.Title });

        // remove special "Other" from Vendors
        vendors.value = vendors.value.filter(o => !AppHelper.isVendorOther(o.fields.Title));

        if (!ignore) {

          Consts.cacheEnabled && setCacheANListData([
            ents.value, antypes.value, vendors.value, lscm.value, anusers.value, tocs.value, currs.value, anList.webUrl || ''
          ]);

          setStateAllANEntities(ents.value);
          setStateAllANTypes(antypes.value);
          setStateAllANVendors(vendors.value);
          setStateAllANLSCaseManagers(lscm.value);
          setStateAllANUsers(anusers.value);
          setStateAllTypeOfCosts(tocs.value);
          setStateAllANCurrencys(currs.value);
          setStateANNotesListWebUrl(anList.webUrl || ''); // save the list URL, it may differ from list Title (ex: title='Advisory Notes Framework' vs url='ANFramework')

          if (statePageLoading) {
            setStatePageLoading(false);
          }
        }

      }, Consts.sleepMsAjax);
    }
    return () => { ignore = true; }
  }, []);

  //#endregion


  //#region 'get AN from SharePoint list if provided by querystring'
  //-------------------------

  const [stateANNote, setStateANNote] = useState<ANNote | null | undefined>();


  // can use this memo without worrying if its null/undefined, making every field value optional (ex. x[col] = string | undefined)
  const memoANNote = useMemo<Partial<ANNoteFields>>(() => {
    return stateANNote ? stateANNote.fields : {};
  }, [stateANNote]);


  // use this memo for Form Status, since we use it everywhere, plus we can provide an admin override to force edit mode
  const memoFormStatus = useMemo<string>(() => {
    if (memoAdmOvrAvail && Consts.admOvrEditMode()) return StaticData.wfStatusDraft;
    else return GenUtil.NVL(memoANNote.FormStatus, StaticData.wfStatusDraft);
  }, [memoANNote, memoAdmOvrAvail]);


  // determine if form is readonly (needed up here because used in connection fetch)
  const memoFormIsReadOnly = useMemo<boolean>(() => {
    // form is only enabled when: Is_New_Mode, or the formstatus is Draft, Saved, Servicer
    if (memoIsNewItem || GenUtil.inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft, StaticData.wfStatusServicer))
      return false;
    else
      return true;
  }, [memoIsNewItem, memoFormStatus]);


  const memoFormIsReadOnlyCES1 = useMemo<boolean>(() => {
    // form is only enabled when: Is_New_Mode, or the formstatus is Draft, Saved, Servicer, CES1
    // special version of this memo, includes CES1, change as of 5/25/23 (found out a bunch of sections/fields are editable for CES1 too)
    if (memoIsNewItem || GenUtil.inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft, StaticData.wfStatusServicer, StaticData.wfStatusCES1))
      return false;
    else
      return true;
  }, [memoIsNewItem, memoFormStatus]);


  useEffect(function loadANItemFromSP() {
    // load AN Note SPListItem from SP
    let ignore = false;

    let _id = stateANItemId;

    if (_id <= 0) {
      setStateLoadingANItem(false);
      setStateLoadingConnection(false);
    }
    else {
      setStateLoadingANItem(true);
      setStateLoadingConnection(true);

      setTimeout(async () => {

        let an = await GraphDataService.getANNote(accounts, instance, _id);

        if (an.httpStatus >= 400) {
          AppHelper.toastError(`Error loading Advisory Note from SharePoint: ItemId=${_id}; Msg=${an.httpStatusText}`);
          AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error loading Advisory Note`, an);
          return;
        }

        console.log('Loaded AN from SharePoint', an);

        if (!ignore) {
          // order of saving state is important, as it will trigger other hooks before this hook may finish, first set the simple fields, then set the overall ANNote, then load connections, finally other 1:N sections


          // top section:

          if (!GenUtil.isNull(an.fields.Project))
            setStateSelProject(an.fields.Project);

          if (!GenUtil.isNull(an.fields.AdvisoryNoteType))
            setStateSelANType(an.fields.AdvisoryNoteType);

          setStateMarkAsUrgent(GenUtil.safeToBool(an.fields.MarkAsUrgent));




          // rich text mlots (if they are blank/null, then set them to the templates)
          setStatePropertyDetailsBgInfo(GenUtil.NVL(an.fields.Property_Details, StaticData.htmlDefaultPropertyDetailsBgInfo));
          setStateProposalInfo(GenUtil.NVL(an.fields.Proposal, StaticData.htmlDefaultProposalInfo));
          setStateSupportingTables(GenUtil.NVL(an.fields.SupportingTables, StaticData.htmlDefaultSupportingTables));
          setStateRecComments(GenUtil.NVL(an.fields.Comments, StaticData.htmlDefaultRecComments));




          setStateALMCollectionsUW(GenUtil.safeTrim(an.fields.CMGrossUW));
          setStateALMCollectionsRevBP(GenUtil.safeTrim(an.fields.CMGrossBP));
          setStateALMMultipleUW(GenUtil.safeTrim(an.fields.CMMultUW));
          setStateALMMultipleRevBP(GenUtil.safeTrim(an.fields.CMMultBP));
          setStateALMIRRUW(GenUtil.safeTrim(an.fields.CMIRRUW));
          setStateALMIRRRevBP(GenUtil.safeTrim(an.fields.CMIRRBP));
          setStateALMWALUW(GenUtil.safeTrim(an.fields.CMWALUM));
          setStateALMWALRevBP(GenUtil.safeTrim(an.fields.CMWALBP));




          // approvers
          if (!GenUtil.isNull(an.fields.LSCaseManager))
            setStateSelLSCaseManagerUser(an.fields.LSCaseManager);

          if (!GenUtil.isNull(an.fields.CaseManager))
            setStateSelCaseManagerUser(an.fields.CaseManager);





          // workflow sections:
          // NOTE: restore BBP choices, and comments, but actions are NOT restored, they are used to determine submit action
          //   do i really want to load the actions? I think we only want to save the "overall" action during the workflow steps, these act as user options to move through the workflow, not persistant user controls

          setStateSelApp6BBP(GenUtil.safeTrim(an.fields.App6BBP));

          if (!GenUtil.isNull(an.fields.App6BBP) && GenUtil.isNull(an.fields.App7BBP)) {
            // if app7bbp has no value, but app6bbp does have a value, init to app6bbp value
            setStateSelApp7BBP(GenUtil.safeTrim(an.fields.App6BBP));
          }
          else
            setStateSelApp7BBP(GenUtil.safeTrim(an.fields.App7BBP));

          setStateApp3Comment(GenUtil.safeTrim(an.fields.App3Comments));
          setStateApp6Comment(GenUtil.safeTrim(an.fields.App6Comments));
          setStateApp1Comment(GenUtil.safeTrim(an.fields.App1Comments));
          setStateApp7Comment(GenUtil.safeTrim(an.fields.App7Comments));
          setStateApp2Comment(GenUtil.safeTrim(an.fields.App2Comments));
          setStateApp5Comment(GenUtil.safeTrim(an.fields.App5Comments));
          setStateApp4Comment(GenUtil.safeTrim(an.fields.App4Comments));
          setStateApp10Comment(GenUtil.safeTrim(an.fields.App10Comments));






          // now set and trigger loading the main ANNote
          setStateANNote(an);



          // then load connections
          let conns = ConvertToXml.cvtXml2ConnectionDetailsItem(GenUtil.safeTrim(an.fields.ConnectionDetails));
          let connIds = conns
            .filter(x => !GenUtil.isNull(x.rpt_ConnecID))
            .map(x => GenUtil.safeTrim(x.rpt_ConnecID));
          setStateANSavedConnDetails(conns); // set this first so each status can be updated
          setStateCurANConnIDs(connIds); // setting these connectionIds will trigger the load of these connections in a useEffect below





          // then load 1:N sections
          setStateBorrowerInfo(GenUtil.NVL(an.fields.BorrowerInfo, new Date().getTime().toString()));
          setStateLoanInfo(GenUtil.NVL(an.fields.LoanInfo, new Date().getTime().toString()));
          setStateAssetInfo(GenUtil.NVL(an.fields.AssetInfo, new Date().getTime().toString()));

          // independent xml sections (not affiliated with connections)
          setStateProposedCostsDetails(GenUtil.NVL(an.fields.ProposedCostsDetails, new Date().getTime().toString()));

          // memoAssetConnDetailItems: never load this from saved JSON, always load it from avail loaded connections and selected asset(s)





          // reset loaders
          setStateLoadingANItem(false);

          if (connIds.length <= 0)
            setStateLoadingConnection(false); // if no connections to load, hide the spinner, otherwise defer this while conns are loaded

        }

      }, Consts.sleepMsAjax);
    }

    return () => { ignore = true; }
  }, [stateANItemId]);

  //#endregion


  //#region 'urgent checkbox'
  //-------------------------

  // checkbox
  const [stateMarkAsUrgent, setStateMarkAsUrgent] = useState<boolean>(false);
  function onChangeMarkAsUrgent() { setStateMarkAsUrgent(p => !p); }

  //#endregion


  //#region 'Field: Project Name (lookup)'
  //-------------------------

  const [stateSelProject, setStateSelProject] = useState<string>(''); // from lookup data Entities
  const onProjectNameChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelProject(option ? option.key + '' : ''));

  const [stateSelProjectItem, setStateSelProjectItem] = useState<ANEntity | null>(null);


  // for workflow
  const memoSelProjectLegalTitleHolder = useMemo<string>(() => {
    return stateSelProjectItem && GenUtil.safeTrim(stateSelProjectItem.fields.wh8i) !== '' ?
      GenUtil.safeTrim(stateSelProjectItem.fields.wh8i) : 'N/A';
  }, [stateSelProjectItem]);


  // for workflow
  const memoSelProjectAssetManager = useMemo<string>(() => {
    return stateSelProjectItem && GenUtil.safeTrim(stateSelProjectItem.fields.vs0j) !== '' ?
      GenUtil.safeTrim(stateSelProjectItem.fields.vs0j) : 'N/A';
  }, [stateSelProjectItem]);


  // for workflow
  const memoSelProjectIsCountryDutch = useMemo<boolean>(() => {
    return stateSelProjectItem && GenUtil.eq(stateSelProjectItem.fields.gtdb, "Netherlands") ?
      true : false;
  }, [stateSelProjectItem]);


  // for workflow
  const memoSelProjectIsLoanCo = useMemo<boolean>(() => {
    return stateSelProjectItem && GenUtil.eq(stateSelProjectItem.fields.LoanCo, "Yes") ?
      true : false;
  }, [stateSelProjectItem]);


  useEffect(() => {
    // get selected Project listitem (the combobox only selects the key/name)
    let obj = null;
    if (stateSelProject !== '') {
      let objs = stateAllANEntities.filter(o => o.fields.Title === stateSelProject);
      if (objs.length > 0) obj = objs[0];
    }
    setStateSelProjectItem(obj);
  }, [stateAllANEntities, stateSelProject]);


  useEffect(() => {
    // when entities are loaded, and there is only a single entity, lets load it by default
    if (stateAllANEntities.length === 1 && stateSelProject === '') {
      let obj = stateAllANEntities[0];
      setStateSelProject(obj.fields.Title);
      setStateSelProjectItem(obj);
    }
  }, [stateAllANEntities, stateSelProject]);


  //#endregion


  //#region 'Fields: AN Type (lookup)'
  //-------------------------

  const [stateANTypeOptions, setStateANTypeOptions] = useState<any[]>([]);

  const [stateSelANType, setStateSelANType] = useState<string>('');
  const onANTypeChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelANType(option ? option.key + '' : ''));

  const [stateSelANTypeObject, setStateSelANTypeObject] = useState<ANType | null>(null);


  const memoSelANTypeIsPortfolioLevel = useMemo<boolean>(() => {
    // determine if selected ANType is "portfolio level" related, this selection implies the form becomes very simple (no connections, assets, loans, borrowers)
    return GenUtil.contains(stateSelANType, 'PORTFOLIO LEVEL');
  }, [stateSelANType]);


  const memoSelANTypeIsPaymentOfCosts = useMemo<boolean>(() => {
    // determine if selected ANType is "payment of costs" related, this selection is used to determine a workflow branching step for lanes 4/5
    return GenUtil.contains(stateSelANType, 'PAYMENT OF COSTS');
  }, [stateSelANType]);


  const memoSelANTypeIsREO = useMemo<boolean>(() => {
    // replaces "memoSelProjectIsREO" memo
    if (stateSelANTypeObject && eq(stateSelANTypeObject.fields.ProjectType, "REOCO"))
      return true;
    else
      return false;
  }, [stateSelANTypeObject]);


  useEffect(() => {
    // get selected AN Type listitem
    let obj = null;
    if (stateSelANType !== '') {
      let objs = stateAllANTypes.filter(o => o.fields.Title === stateSelANType);
      if (objs.length > 0)
        obj = objs[0];
    }
    setStateSelANTypeObject(obj);
  }, [stateAllANTypes, stateSelANType]);


  useEffect(() => {
    // generate AN Type options
    let col: string[] = [];
    col = stateAllANTypes.map((o) => { return o.fields.Title; });
    setStateANTypeOptions(col.map(o => { return { key: o, text: o }; }));

  }, [stateAllANTypes]);

  const memoIsReocoAssetSaleWordingRequired = useMemo<boolean>(() => {
    return memoSelANTypeIsREO && stateSelANType === 'REOCO - Asset Sale';
  }, [memoSelANTypeIsREO, stateSelANType]);

  useEffect(() => {
    if (!stateProposalInfo || stateProposalInfo === StaticData.htmlDefaultProposalInfo || stateProposalInfo === StaticData.htmlDefaultProposalInfoReocoAssetSale) {
        setStateProposalInfo(
            memoIsReocoAssetSaleWordingRequired
            ? StaticData.htmlDefaultProposalInfoReocoAssetSale
            : StaticData.htmlDefaultProposalInfo
        );
    }
  }, [memoIsReocoAssetSaleWordingRequired]);

  //#endregion


  const [stateSelIsConnGroupId, setStateSelIsConnGroupId] = useState<IDropdownOption | undefined>(GenUtil.safeToFluOption('No'));
  const onChangeIsConnGroupId = (event: any, option?: IDropdownOption, index?: number) => { setStateSelIsConnGroupId(option); };


  // NOTE: override, never show the IsConnGroup control
  const memoShowIsConnGroupId = false;
  // const memoShowIsConnGroupId = useMemo<boolean>(() => {
  //   return memoSelProjectIsREO;
  // }, [memoSelProjectIsREO]);


  const memoIsConnGroupId = useMemo<boolean>(() => {
    return !!stateSelIsConnGroupId && (stateSelIsConnGroupId.key === 'Yes');
  }, [stateSelIsConnGroupId]);

  //#endregion


  //#region 'Field: Connection picker (ajax lookup)'
  //-------------------------

  const [stateSelANConnectionPicker, setStateSelANConnectionPicker] = useState<ITag[]>([]); // connection picker result

  const memoConnPickerId = useMemo<string>(() => {
    return stateSelANConnectionPicker == null ? '' :
      stateSelANConnectionPicker.length <= 0 ? '' : stateSelANConnectionPicker[0].name;
  }, [stateSelANConnectionPicker]);

  const [stateCurANConnIDs, setStateCurANConnIDs] = useState<string[]>([]);
  const [stateSelUniqueConns, setStateSelUniqueConns] = useState<ConnectionDetailsItem[]>([]);
  const [stateSelAllConns, setStateSelAllConns] = useState<ANConnection[]>([]);
  const [stateANSavedConnDetails, setStateANSavedConnDetails] = useState<ConnectionDetailsItem[]>([]);


  useEffect(() => {
    // reset the connection picker when project or antype changes
    setStateSelANConnectionPicker([]);
  }, [stateSelProject, stateSelANType]);


  const memoConnectionPickerLabel = useMemo<string>(() => {
    if (memoSelANTypeIsREO) {
      if (memoIsConnGroupId)
        return StaticData.connPickerLabel_ConnGroupID;
      else
        return StaticData.connPickerLabel_AssetID;
    }
    else {
      return StaticData.connPickerLabel_ConnID;
    }
  }, [memoSelANTypeIsREO, memoIsConnGroupId]);


  const memoShowConnectionPickerSection = useMemo<boolean>(() => {
    // only show when Project is selected, and ANType is selected
    // special case: when ANType is 'portfolio level' connections are not needed
    if (stateSelProject === '' || stateSelANType === '' || memoSelANTypeIsPortfolioLevel === true) {
      return false;
    }
    else {
      return true;
    }
  }, [stateSelProject, stateSelANType, memoSelANTypeIsPortfolioLevel]);


  async function getSuggestedANConnections(filterText: string): Promise<ITag[]> {
    // handler to start searching using the filter text, for Tag Picker

    if (stateSelProjectItem == null || stateSelANTypeObject == null) {
      // don't allow search if no selected project, or AN Type
      return [];
    }

    let filter = GenUtil.safeTrim(filterText);

    // #testing
    if (GenUtil.eq(filter, 'test1'))
      filter = `IND-CR-01-0901`; // LOANCO
    else if (GenUtil.eq(filter, 'test2'))
      filter = `123210`; // REO, asset when project="INDIAN_REO", antype="REOCO - DIL", other asset ids for tesing: [121034, 121035, 121040]

    if (filter.length <= 0) {
      return [];
    }

    const data: ANConnections = await GraphDataService.getANConnectionsForPicker(accounts, instance,
      filter,
      memoSelANTypeIsREO,
      memoIsConnGroupId,
      stateSelProject,
      filter.length >= Consts.graphMinCharsForSearch ? Consts.graphMaxAllItemsCount : 500);

    if (data.httpStatus >= 400) {
      AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error searching for Connection: Project='${stateSelProject}'; IsREO=${memoSelANTypeIsREO + ''}; Filter='${filter}'`, data);
    }

    if (data.httpStatus >= 400 && data.httpStatus !== 422) {
      AppHelper.toastError(`Error searching for Connection in SharePoint: Project='${stateSelProject}'; IsREO=${memoSelANTypeIsREO + ''}; Filter='${filter}'; Msg=${data.httpStatusText}`);
      return [];
    }

    // NOTE: can handle throttling issues here
    // if (data.httpStatus === 422){
    //   toastWarn(`TBD`);
    // }

    if (data == null || data.value == null || data.value.length <= 0) {
      return [];
    }

    let col = data.value;

    let ids: string[] = [];

    if (memoSelANTypeIsREO && !memoIsConnGroupId) {
      // use AssetID
      ids = col.map((o) => {
        return o.fields.Asset_x0020_ID;
      });
    }
    else if (memoSelANTypeIsREO && memoIsConnGroupId) {
      // use ConnectionGroupID
      ids = col.map((o) => {
        return o.fields.ConnectionGroupID;
      });
    }
    else {
      // use ConnectionID
      ids = col.map((o) => {
        return o.fields.Connection_x0020_ID;
      });
    }

    ids = ids.filter(x => !GenUtil.isNull(x));
    ids = _.uniq(ids);
    ids = _.take(ids, Consts.graphMaxForPickerCount);

    return ids.map((o) => {
      return { key: o, name: o } as ITag;
    });
  }


  function onTagsChangedANConnections(items?: ITag[]): void {
    // handler when the connection id ajaxpicker has a result selected
    setStateSelANConnectionPicker(!items ? [] : items);
  }


  function handleAddConnectionId() {
    // assuming a connection is selected in the picker, this further "selects" it and locks that choice in

    // quit if project, antype, and picker are not loaded
    if (stateSelProjectItem == null || stateSelANTypeObject == null || memoConnPickerId === '') {
      return;
    }

    // only add connections if not already added
    if (stateCurANConnIDs.findIndex(x => GenUtil.eq(x, memoConnPickerId)) < 0) {

      setStateLoadingConnection(true);

      // set selected connection id (this could be connection id, asset id, or connection group id)
      setStateCurANConnIDs(p => [...p, memoConnPickerId]);

      setStateSelANConnectionPicker([]); // clear picker
    }
  }


  const refBadIds = useRef<string[]>([]);


  useEffect(function loadConnections() {
    // when a Connection ID is selected by user and the add button is clicked,
    // OR, when the Connection ID is set from a loaded AN record from SP
    // get that Connection from SP
    let ignore = false;

    setTimeout(async () => {

      if (stateSelProject === '' || stateSelProjectItem == null || stateSelANType === '' || stateSelANTypeObject == null) {
        return;
      }

      // get current ConnIds, make sure they are unique (from user input in picker this is guaranteed, but SP item data may be unreliable)
      let connIds: string[] = _.uniq(stateCurANConnIDs.map(x => GenUtil.safeTrim(x)).filter(x => x.length > 0));

      // #testing, add a bunch of other ANs to see how this performs
      // connIds = [...connIds, ...(`IND-CR-01-0001,IND-CR-01-0002,IND-CR-01-0003,IND-CR-01-0004,IND-CR-01-0005,IND-CR-01-0006,IND-CR-01-0007,IND-CR-01-0008,IND-CR-01-0009,IND-CR-01-0010,IND-CR-01-0011,IND-CR-01-0012,IND-CR-01-0013,IND-CR-01-0014,IND-CR-01-0015,IND-CR-01-0016,IND-CR-01-0017,IND-CR-01-0018,IND-CR-01-0019,IND-CR-01-0020,IND-CR-01-0021,IND-CR-01-0022,IND-CR-01-0023,IND-CR-01-0024,IND-CR-01-0025,IND-CR-01-0026,IND-CR-01-0027`.split(','))];

      connIds = _.sortBy(connIds);

      // foreach unique ConnId found, search the Connections List for matches
      // save them all, and also save a unique set of Connection records (based on ConnectionId) that will become ConnectionDetails xml
      // NOTE: searching SP for a single connection at a time
      // #todo optimize this to combine connection ids into OR clause, up to N at a time to make the URL not too long
      let allConns: ANConnection[] = [];

      const getConns = connIds.map((connId: string, idx: number) => {
        return GraphDataService.getANConnections(accounts, instance, connId, memoSelANTypeIsREO, memoIsConnGroupId, stateSelProject, Consts.graphMaxAllItemsCount).then((data) => {

          if (data.httpStatus >= 400) {
            AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, `Error loading Connection: idx=${idx},connId='${connId}',isReo='${memoSelANTypeIsREO}',isConnGroup='${memoIsConnGroupId}',selProj='${stateSelProject}'`, data);
          }

          let col = data.value;

          if (col.length <= 0) {
            /**
             * SPECIAL CASE:
             * this cannot really be <=0, because the picker found the connection, so this must happen from a prior saved AN loading a connection no longer found
             * this is needed in case a prior saved AN is loaded and the connection query does not return any results
             * this could happen if the prior connection is deleted (manually or due to sync), or if the connection id changed
             * it shouldn't be important when the form is in readonly mode, but IS really important when in draft mode
             * this is edge case protection, should not be common
             */

            if (!memoFormIsReadOnly) {
              AppHelper.toastError(`Cannot load prior saved Connection, no matching Connections found for Connection ID='${connId}'.`);
            }

            console.warn(`Cannot load prior saved Connection, no matching Connections found for Connection ID='${connId}'.`);
            // do not return/quit, since we are loading 1 or more connections, some may be successful, some may not...
          }
          else {
            // append connections
            allConns = [...allConns, ...col];
          }

        });
      });

      await Promise.all(getConns);


      // sort returned connections (by connectionid and itemid)
      allConns = _.sortBy(allConns, x => x.fields.Connection_x0020_ID + '|' + x.fields.id);


      // fix html encoding special char issues with Connections list data, 2 columns have common char encoding issues
      allConns.forEach(o => {
        o.fields.ConnectionIdOrig = o.fields.Connection_x0020_ID; // save the original Connection ID into a local copy, since connid will be overwritten with AssetID for REOCO entity/ANs
        o.fields.Borrower_x0020_Name = GenUtil.replaceHtmlSpecialChars(o.fields.Borrower_x0020_Name);
        o.fields.Connection_x0020_Name = GenUtil.replaceHtmlSpecialChars(o.fields.Connection_x0020_Name);
      });

      // for REO connections, use assetId as the connectionId, so when the connections are loaded after the rec is saved, the correct ID is used for the 1:N connection lookups
      if (memoSelANTypeIsREO) {
        allConns.forEach(a => {
          a.fields.Connection_x0020_ID = a.fields.Asset_x0020_ID;
        });
      }


      // connection data integrity check: for each connection id, a single asset row must be found, if not we'll warn the user and remove the bad rows matching these connection ids
      let badConnIds: string[] = [];
      let allConnIds: string[] = _.uniq(allConns.map(x => safeTrim(x.fields.Connection_x0020_ID)));
      allConnIds.forEach(id => {
        if (!allConns.find(x => eq(id, x.fields.Connection_x0020_ID) && eq(NVL(x.fields.ContentTypeImport, x.contentType.name), "Assets")))
          badConnIds.push(id);
      });

      // NOTE: only apply this update for gescobro for now, as other ANForms may need to proceed with connections without assests
      badConnIds.forEach(id => {
        if (!ignore && !refBadIds.current.find(x => eq(id, x))) { // using ref to make sure user is only warned a single time for the same bad connection
          let msg = `Connections loaded matching Connection ID "${id}" does not have any Assets.`;
          // AppHelper.toastWarn(msg);
          console.warn(msg);
          AppHelper.aiTrackDataErr(StaticData.defaultBrokerCode, stateANItemId, msg, id);
        }
        refBadIds.current = [...refBadIds.current, id];
        // allConns = allConns.filter(x => !eq(id, x.fields.Connection_x0020_ID)); // remove the bad connections from entire collection
      });


      // get unique connections from all connections
      let uniqConns: ANConnection[] = [];

      /**
       * #todo
       * get distinct connection ids from allConns
       * iterate on the distinct connIds
       * get matches, find the best not null/empty for each connId, for each property we want to fill
       * we'll be creating these types of objects: ConnectionDetailsItem (the xml mapping object), not ANConnection splistitem object
       *
       */

      allConns.forEach(a => {
        // The unique connections added should be the listitems that are contenttype=Assets, therefore having 'AssetId' and 'CESConnectionIDs' filled in.
        // The loan info listitems do not have these 2 fields set, but DO have borrower and loan info 'BorrowerId' and 'LoanId'.
        // as of 3/21/23, lets use AssetCESConnectionID instead, it has better coverage, finsolutia people say this is OK, andrew says OK
        // NOTE: 5/10/23, found a bug in prod, where the connection id was bad for the asset type conn records, i.e "[id]; [id]", thus the eq match fails when loading the connections from SP, so only the loan type conn records came back, and therefore this section didn't find any unique conns, so the UI was broken

        let _id = GenUtil.safeTrim(GenUtil.NVL(a.fields.AssetCESConnectionID, a.fields.Asset_x0020_ID));

        if (_id.length > 0 && uniqConns.findIndex(b => b.fields.Connection_x0020_ID === a.fields.Connection_x0020_ID) < 0) {
          uniqConns = [...uniqConns, { ...a }];
        }
      });


      // show warnings for potential data issues
      if (connIds.length > 0 && uniqConns.length <= 0) {
        console.warn(`WARNING: no unique connections were found, this is likely a data issue.`);
      }

      if (connIds.length > 0 && allConns.length <= 0) {
        console.warn(`WARNING: no connections found.`);
      }


      // update status property of connections from saved AN connection details
      // NOTE: for REO connections there is no connection status, so just wipe it with restore
      uniqConns.forEach(a => {

        // start with empty connection status, we do not want the status from the Connections list, we only want status from User Input (current or prior saved to listitem)
        let curConnStatus = "";

        if (!memoSelANTypeIsREO) {
          // try to restore the status from the prior saved collection (user input)
          let idx = 0;

          idx = stateSelUniqueConns.findIndex(b => GenUtil.eq(b.rpt_ConnecID, a.fields.Connection_x0020_ID));

          if (idx >= 0) {
            let tmp = stateSelUniqueConns[idx];
            curConnStatus = GenUtil.safeTrim(tmp.rpt_ConnectionStatus);
          }

          // if still empty, try to restore the status from the listitem connectiondetails
          if (GenUtil.isNull(curConnStatus)) {

            idx = stateANSavedConnDetails.findIndex(b => GenUtil.eq(b.rpt_ConnecID, a.fields.Connection_x0020_ID));

            if (idx >= 0) {
              let tmp = stateANSavedConnDetails[idx];
              curConnStatus = GenUtil.safeTrim(tmp.rpt_ConnectionStatus);
            }
          }
        }

        a.fields.Connection_x0020_Status = curConnStatus;
      });

      // save connections to state
      if (!ignore) {

        setStateSelUniqueConns(uniqConns.map(x => {
          return {
            cv_ConnId_Is_AssetId: memoSelANTypeIsREO ? "1" : "0", // the swapping of IDs is above: a.fields.Connection_x0020_ID = a.fields.Asset_x0020_ID;
            rpt_CESConnID: x.fields.AssetCESConnectionID,
            cv_rpt_ConnecID: x.fields.Connection_x0020_ID,
            rpt_ConnecID: x.fields.Connection_x0020_ID,
            cv_rpt_ConnName: x.fields.Connection_x0020_Name,
            rpt_ConnectionName: x.fields.Connection_x0020_Name,
            rpt_ConnectionStatus: x.fields.Connection_x0020_Status
          } as ConnectionDetailsItem;
        }));

        setStateSelAllConns(allConns); // all retrieved connection records

        setStateLoadingConnection(false); // reset loader
      }

    }, 800); // delay this to force the connection picker spinner to render
    // }, Consts.sleepMsAjax);

    return () => { ignore = true; }

  }, [stateSelProject, stateSelProjectItem, stateSelANType, stateSelANTypeObject, memoSelANTypeIsREO, memoIsConnGroupId, memoFormIsReadOnly, stateCurANConnIDs]);


  function handleDeleteAllTables() {
    // clear all other tables and selections

    // clear the connection state
    setStateSelANConnectionPicker([]);
    setStateCurANConnIDs([]);
    setStateSelUniqueConns([]);
    setStateSelAllConns([]);
    setStateANSavedConnDetails([]); // clear the original saved conndetails so status is reset

    // clear the 1:N grids/subtables
    // NOTE: do NOT clear the independent sections (like ProposedCosts)
    // NOTE: using getTime to force updates, see other notes about this
    setStateBorrowerInfo(new Date().getTime().toString());
    setStateLoanInfo(new Date().getTime().toString());
    setStateAssetInfo(new Date().getTime().toString());

    // clear the DDLs
    setStateBorrowerOptions([]);
    setStateLoanOptions([]);
    setStateAssetOptions([]);

    // clear the lookups
    setStateBorrowerObjects([]);
    setStateLoanObjects([]);
    setStateAssetObjects([]);

    // NOTE: do not have to clear/reset the "_Items" state, they are cleared by reseting the "...Info" state to datetime/ticks
  }


  function onChangeConnectionDetail(connId: string, status: string) {
    // merge status change into added connection

    setStateSelUniqueConns(p => {
      let t = [...p];
      let idx = t.findIndex(x => x.rpt_ConnecID === connId);
      if (idx >= 0)
        t[idx].rpt_ConnectionStatus = status;
      return t;
    })
  }


  function onDeleteConnectionDetail(connId: string) {
    // delete the selected connection
    // also, delete any borrowers, loans, assets that use this connection
    // NOTE: not relevant for AN when project=REO

    setStateLoadingConnection(true);

    // this will trigger loading the proper connections, fix the DDLs/options, and remove the connection from the Connection Details section
    setStateCurANConnIDs(p => {
      return [...p].filter(x => x !== connId);
    });

    // but, we need to manually get rid of the objects in the 1:N sections
    // NOTE: we have to cheat and send a prop down to the 1:N sections, and listen for this change
    setStateBorrowerConnId2Del(connId);
    setStateLoanConnId2Del(connId);
    setStateAssetConnId2Del(connId);
  }


  //special 1:N sections that depend on Connection loaded
  // NOTE: plus the other section (ProposedCosts) (it is independent, but relevant here)

  // these are the XML fields from the ANNote list
  // NOTE: using gettime() to generate a long number to enable easy resets
  //   the data from SP may be null/empty, then when calling "reset tables", the change would be "" -> "", which prevents the subcomponent useeffect from firing
  const [stateBorrowerInfo, setStateBorrowerInfo] = useState<string>(new Date().getTime().toString());
  const [stateLoanInfo, setStateLoanInfo] = useState<string>(new Date().getTime().toString());
  const [stateAssetInfo, setStateAssetInfo] = useState<string>(new Date().getTime().toString());
  // independent xml sections (not affiliated with connections)
  const [stateProposedCostsDetails, setStateProposedCostsDetails] = useState<string>(new Date().getTime().toString());

  // these are the DDL options that depend on the loaded Connection
  const [stateBorrowerOptions, setStateBorrowerOptions] = useState<any[]>([]);
  const [stateLoanOptions, setStateLoanOptions] = useState<any[]>([]);
  const [stateAssetOptions, setStateAssetOptions] = useState<any[]>([]);

  // using these state to send a Connection Id to the 1:N sections to delete all data in those components that have same connId
  const [stateBorrowerConnId2Del, setStateBorrowerConnId2Del] = useState<string>('');
  const [stateLoanConnId2Del, setStateLoanConnId2Del] = useState<string>('');
  const [stateAssetConnId2Del, setStateAssetConnId2Del] = useState<string>('');

  // these are associated connections for each category (to send to sub components for 'Add' button)
  const [stateBorrowerObjects, setStateBorrowerObjects] = useState<ANConnection[]>([]);
  const [stateLoanObjects, setStateLoanObjects] = useState<ANConnection[]>([]);
  const [stateAssetObjects, setStateAssetObjects] = useState<ANConnection[]>([]);

  // save 1:N sections data here (will be converted to xml to save back to ANNote item)
  const [stateAssetInfoItems, setStateAssetInfoItems] = useState<AssetInfoItem[]>([]);
  const [stateLoanInfoItems, setStateLoanInfoItems] = useState<LoanInfoItem[]>([]);
  const [stateBorrowerInfoItems, setStateBorrowerInfoItems] = useState<BorrowerInfoItem[]>([]);
  // independent xml sections (not affiliated with connections)
  const [stateProposedCostsDetailsItems, setStateProposedCostsDetailsItems] = useState<ProposedCostsDetailsItem[]>([]);

  // saving objects back to XML for saving to SP Item
  const memoConnectionDetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtConnectionDetailsItem2Xml(stateSelUniqueConns);
    return xml;
  }, [stateSelUniqueConns]);

  const memoBorrowerInfoXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtBorrowerInfoItems2Xml(stateBorrowerInfoItems);
    return xml;
  }, [stateBorrowerInfoItems]);

  const memoLoanInfoXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtLoanInfoItems2Xml(stateLoanInfoItems);
    return xml;
  }, [stateLoanInfoItems]);

  const memoAssetInfoXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtAssetInfoItems2Xml(stateAssetInfoItems);
    return xml;
  }, [stateAssetInfoItems]);

  const memoProposedCostsDetailsXml = useMemo<string>(() => {
    let xml = ConvertToXml.cvtProposedCostsDetailsItems2Xml(stateProposedCostsDetailsItems);
    return xml;
  }, [stateProposedCostsDetailsItems]);


  function onUpdateSectionData(name: string, data: any) {
    // update 1:N state in this component when sub-components update
    if (GenUtil.eq(name, 'assets'))
      setStateAssetInfoItems(data);
    else if (GenUtil.eq(name, 'loans'))
      setStateLoanInfoItems(data);
    else if (GenUtil.eq(name, 'borrowers'))
      setStateBorrowerInfoItems(data);
    else if (GenUtil.eq(name, 'propcosts'))
      setStateProposedCostsDetailsItems(data);
  }



  const memoAssetConnDetailItems = useMemo<AssetConnDetail[]>(() => {
    // change 9/21/23, for REO type projects, and when an asset is added, user wants to see the associated connection and borrower information
    // this will also need to be saved into a field in SP for PDF reporting
    // OK to save as JSON later

    if (!memoSelANTypeIsREO || stateSelAllConns.length <= 0 || stateAssetInfoItems.length <= 0)
      return [];

    let ar: AssetConnDetail[] = [];

    let allConns = stateSelAllConns;
    let selAssets = stateAssetInfoItems;

    // get all unique connection ids from assets collection
    // NOTE: all the below connectionids are actually assetids, they were switched when loading the connections from SP because REOCO situation and using asset picker as connection picker
    let connIds = selAssets.filter(x => !isNull(x.cv_ConnId)).map(x => safeTrim(x.cv_ConnId));
    connIds = _.uniq(connIds);

    // foreach connection id, find a matching connection, and best-fill the requested information
    connIds.forEach(connId => {
      let obj: Partial<AssetConnDetail> = {};

      allConns
        .filter(conn => eq(conn.fields.Connection_x0020_ID, connId))
        .forEach(conn => {
          obj.borId = safeTrim(NVL(conn.fields.Borrower_x0020_ID, obj.borId));
          obj.borName = safeTrim(NVL(conn.fields.Borrower_x0020_Name, obj.borName));
          obj.connIdCes = safeTrim(NVL(conn.fields.AssetCESConnectionID, obj.connIdCes));
          obj.connIdSvr = safeTrim(NVL(conn.fields.ConnectionIdOrig, obj.connIdSvr)); // use the original connection id on purpose here
          obj.connName = safeTrim(NVL(conn.fields.Connection_x0020_Name, obj.connName));
        });

      ar.push(obj as AssetConnDetail);
    });

    // NOTE: current implementation above will only return a single borrower per connection row, this may need to change in the future

    return ar;
  }, [memoSelANTypeIsREO, stateSelAllConns, stateAssetInfoItems]);



  useEffect(function convertConnectionsToOptionsAndCache() {
    // fill each section DDL from connection objects loaded
    // also store the relevent connections associated with the DDL options

    /**
     * NOTES:
     * -Asset_x0020_ID is always unique in the Connections list, either unique or null/empty
     * -Servicer_x0020_Loan_x0020_ID is always unique in the Connections list, either unique or null/empty
     * -other fields that are unique or null: PassThruID, Loan_x0020_ID, Servicer_x0020_Loan_x0020_Number
     *
     * -Connection_x0020_ID is NOT unique (6627/16779 unique vs total filled vals out of 20238 rows)
     * -Connection_x0020_Name is NOT unique (5987/16773 unique vs total filled vals out of 20238 rows)
     * -Project is NOT unique (9/20238 unique vs total filled vals out of 20238 rows), so this is ALWAYS filled
     *
     * When ContentType = Assets, expect Asset_x0020_ID, Asset_x0020_Name,
     * when CT = Loans, expect Borrower_x0020_ID, Borrower_x0020_Name, Loan_x0020_ID, Servicer_x0020_Loan_x0020_ID, Servicer_x0020_Loan_x0020_Number
     *
     */

    let borrowerOptions: FluOption[] = [];
    let loanOptions: FluOption[] = [];
    let assetOptions: FluOption[] = [];

    // save the relevant connections for each set (will be passed to sub-components for "add items")
    let borrowerObjects: ANConnection[] = [];
    let loanObjects: ANConnection[] = [];
    let assetObjects: ANConnection[] = [];

    if (!memoSelANTypeIsREO) {
      // from: addConnectionIDData

      stateSelAllConns.forEach((o, i) => {

        // ============== process borrower item
        let borrowerId = GenUtil.safeTrim(o.fields.Borrower_x0020_ID);
        let borrowerName = GenUtil.safeTrim(o.fields.Borrower_x0020_Name);

        // NOTE: original way, using borrowerid as key, and borrower name in ddl label too
        if (borrowerId.length > 0 && borrowerName.length > 0 && borrowerOptions.findIndex(o2 => o2.key === borrowerId) < 0) {
          let t = GenUtil.safeToFluOption(borrowerId, `${borrowerId} - ${borrowerName}`);
          if (t) borrowerOptions.push(t);
          borrowerObjects.push({ ...o });
        }

        // NOTE: updated way, using only borrower name (this change needs a matching change in BorrowerInfoBody.tsx)
        //   hold on this, client hasn't decided what they want to use yet
        // if (borrowerId.length > 0 && borrowerName.length > 0 && borrowerOptions.findIndex(o2 => !GenUtil.eq(o2.key, borrowerName))) {
        //   let t = GenUtil.safeToFluOption(borrowerName, `${borrowerName}`);
        //   if (t) borrowerOptions.push(t);
        //   borrowerObjects.push({ ...o });
        // }

        // ============== process loan item
        if (eq(NVL(o.fields.ContentTypeImport, o.contentType.name), "Loans")) {

          let loanId = GenUtil.safeTrim(o.fields.Servicer_x0020_Loan_x0020_Number);

          if (loanId.length > 0 && loanOptions.findIndex(o2 => o2.key === loanId) < 0) {
            let t = GenUtil.safeToFluOption(loanId, loanId);
            if (t) loanOptions.push(t);
            loanObjects.push({ ...o });
          }
        }

        // ============== process asset item
        if (eq(NVL(o.fields.ContentTypeImport, o.contentType.name), "Assets")) {

          let assetId = GenUtil.safeTrim(o.fields.Asset_x0020_ID);

          if (assetId.length > 0 && assetOptions.findIndex(o2 => o2.key === assetId) < 0) {
            let t = GenUtil.safeToFluOption(assetId, assetId);
            if (t) assetOptions.push(t);
            assetObjects.push({ ...o });
          }
        }

      });

    }
    else {
      // from: addAssetIDData

      if (memoIsConnGroupId) {
        // handle connectiongroups (only the first!)
        if (stateSelAllConns != null && stateSelAllConns.length > 0) {

          let o = stateSelAllConns[0];

          let connGroupId = GenUtil.safeTrim(o.fields.ConnectionGroupID); // this is on purpose, use ConnectionGroupID as assetid here

          if (connGroupId.length > 0) {
            let t = GenUtil.safeToFluOption(connGroupId, connGroupId);
            if (t) assetOptions.push(t);
            assetObjects.push({ ...o });
          }
        }

      }
      else {
        // handle assets
        // NOTE: a loop is likely not needed since each AssetID is unique, copied from legacy system
        stateSelAllConns.forEach((o, i) => {

          if (eq(NVL(o.fields.ContentTypeImport, o.contentType.name), "Assets")) {

            let assetId = GenUtil.safeTrim(o.fields.Asset_x0020_ID);

            if (assetId.length > 0 && assetOptions.findIndex(o2 => o2.key === assetId) < 0) {
              let t = GenUtil.safeToFluOption(assetId, assetId);
              if (t) assetOptions.push(t);
              assetObjects.push({ ...o });
            }
          }
        });
      }
    }

    setStateBorrowerOptions(borrowerOptions);
    setStateLoanOptions(loanOptions);
    setStateAssetOptions(assetOptions);

    setStateBorrowerObjects(borrowerObjects);
    setStateLoanObjects(loanObjects);
    setStateAssetObjects(assetObjects);

  }, [stateSelAllConns]);


  const memoShowDeleteAllTablesButton = useMemo<boolean>(() => {
    // show the Delete All Tables button only when:
    //   a connection is loaded
    //   or, any 1:N section has data added (i.e. not empty)

    if (stateSelUniqueConns.length > 0 ||
      !GenUtil.isInt(stateBorrowerInfo) || !GenUtil.isInt(stateLoanInfo) || !GenUtil.isInt(stateAssetInfo)) {
      return true;
    }
    else
      return false;

  }, [stateSelUniqueConns, stateBorrowerInfo, stateLoanInfo, stateAssetInfo]);

  //#endregion


  //#region 'rich text fields (html)'
  //-------------------------

  // NOTE: init with default HTML (StaticData)

  const [statePropertyDetailsBgInfo, setStatePropertyDetailsBgInfo] = useState(StaticData.htmlDefaultPropertyDetailsBgInfo);
  const onChangePropertyDetailsBgInfo = useCallback((evt: any, v?: string) => { setStatePropertyDetailsBgInfo(v || ''); }, []);

  const [stateProposalInfo, setStateProposalInfo] = useState(StaticData.htmlDefaultProposalInfo);
  const onChangeProposalInfo = useCallback((evt: any, v?: string) => { setStateProposalInfo(v || ''); }, []);

  const [stateSupportingTables, setStateSupportingTables] = useState(StaticData.htmlDefaultSupportingTables);
  const onChangeSupportingTables = useCallback((evt: any, v?: string) => { setStateSupportingTables(v || ''); }, []);

  const [stateRecComments, setStateRecComments] = useState(StaticData.htmlDefaultRecComments);
  const onChangeRecComments = useCallback((evt: any, v?: string) => { setStateRecComments(v || ''); }, []);

  //#endregion


  //#region 'asset level metrics fields (flat section)'
  //-------------------------

  // slot number fields
  const [stateALMCollectionsUW, setStateALMCollectionsUW] = useState('');
  const onChangeALMCollectionsUW = useCallback((evt: any, v?: string) => { setStateALMCollectionsUW(v || ''); }, []);

  const [stateALMCollectionsRevBP, setStateALMCollectionsRevBP] = useState('');
  const onChangeALMCollectionsRevBP = useCallback((evt: any, v?: string) => { setStateALMCollectionsRevBP(v || ''); }, []);

  const [stateALMMultipleUW, setStateALMMultipleUW] = useState('');
  const onChangeALMMultipleUW = useCallback((evt: any, v?: string) => { setStateALMMultipleUW(v || ''); }, []);

  const [stateALMMultipleRevBP, setStateALMMultipleRevBP] = useState('');
  const onChangeALMMultipleRevBP = useCallback((evt: any, v?: string) => { setStateALMMultipleRevBP(v || ''); }, []);

  const [stateALMIRRUW, setStateALMIRRUW] = useState('');
  const onChangeALMIRRUW = useCallback((evt: any, v?: string) => { setStateALMIRRUW(v || ''); }, []);

  const [stateALMIRRRevBP, setStateALMIRRRevBP] = useState('');
  const onChangeALMIRRRevBP = useCallback((evt: any, v?: string) => { setStateALMIRRRevBP(v || ''); }, []);

  const [stateALMWALUW, setStateALMWALUW] = useState('');
  const onChangeALMWALUW = useCallback((evt: any, v?: string) => { setStateALMWALUW(v || ''); }, []);

  const [stateALMWALRevBP, setStateALMWALRevBP] = useState('');
  const onChangeALMWALRevBP = useCallback((evt: any, v?: string) => { setStateALMWALRevBP(v || ''); }, []);


  // deltas - calculated
  const memoDeltaALMColl = useMemo<string>(() => {
    return AppHelper.calcDelta(stateALMCollectionsRevBP, stateALMCollectionsUW);
  }, [stateALMCollectionsUW, stateALMCollectionsRevBP]);

  const memoDeltaALMMult = useMemo<string>(() => {
    return AppHelper.calcDelta(stateALMMultipleRevBP, stateALMMultipleUW);
  }, [stateALMMultipleUW, stateALMMultipleRevBP]);

  const memoDeltaALMIRR = useMemo<string>(() => {
    return AppHelper.calcDelta(stateALMIRRRevBP, stateALMIRRUW);
  }, [stateALMIRRUW, stateALMIRRRevBP]);

  const memoDeltaALMWAL = useMemo<string>(() => {
    return AppHelper.calcDelta(stateALMWALRevBP, stateALMWALUW);
  }, [stateALMWALUW, stateALMWALRevBP]);


  const memoALMIsEditable = useMemo<boolean>(() => {
    // ALM should be editable when status is Draft/Saved/Servicer/CES1
    if (!memoFormIsReadOnlyCES1)
      return true;
    else
      return false;
  }, [memoFormIsReadOnlyCES1]);

  //#endregion


  //#region 'Loan Servicer Case Manager section'
  //-------------------------

  const [stateSelLSCaseManagerUser, setStateSelLSCaseManagerUser] = useState<string>('');
  const onLSCaseManagerUserChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelLSCaseManagerUser(option ? option.key + '' : ''));


  const memoLSCaseManagerOptions = useMemo<any[]>(() => {
    // get case managers from new list
    // this list will contain a list of users (1 per row) and projecttype per user (LOANCO/REOCO)
    // return a list of LSCaseManager users based on ANType selected which determines LOANCO/REOCO

    if (stateAllANLSCaseManagers.length <= 0 || !stateSelANTypeObject)
      return [];

    let parts = stateAllANLSCaseManagers
      .filter(x => eq(x.fields.ProjectType, (memoSelANTypeIsREO ? "REOCO" : "LOANCO")))
      .map(x => safeTrim(x.fields.Title));

    parts = _.sortBy(parts); // sort alpha on Title (user displayname)

    let opts = parts.map(o => {
      return { key: o, text: o };
    });

    return opts;
  }, [stateAllANLSCaseManagers, stateSelANTypeObject, memoSelANTypeIsREO]);

  //#endregion


  //#region 'Case Manager Section'
  //-------------------------

  // NOTE: email is saved to ANNote (not dispname)

  const [stateSelCaseManagerUser, setStateSelCaseManagerUser] = useState<string>('');
  const onCaseManagerUserChange = (event: any, option?: IComboBoxOption, index?: number, value?: string) => (setStateSelCaseManagerUser(option ? option.key + '' : ''));


  const memoAllCaseManagerUsers = useMemo<SimpleUserInfo[]>(() => {
    // generate case manager userinfo collection from ANUsers list
    let col: SimpleUserInfo[] = [];

    stateAllANUsers.filter(o => GenUtil.eq(o.fields.Title, 'CES1')).forEach((o, i) => {
      if (i <= 0) {
        // only process first returned listitem
        let names = GenUtil.safeTrim(o.fields.Names);
        let emails = GenUtil.safeTrim(o.fields.Email);

        if (names.length > 0 && emails.length > 0) {
          let nameParts = names.replace(/,/ig, ';').split(';');
          let emailParts = emails.replace(/,/ig, ';').split(';');

          for (let i = 0; i < nameParts.length; i++) {
            try {
              col = [...col, { email: emailParts[i], name: nameParts[i] }];
            } catch (error) {
              console.error('ERROR @ memoCaseManagerCol', i, names, emails, nameParts, emailParts, error);
            }
          }
        }
      }
    });

    return col;
  }, [stateAllANUsers]);


  const memoSelCaseManagerItem = useMemo<SimpleUserInfo | null>(() => {
    if (isNull(stateSelCaseManagerUser) || memoAllCaseManagerUsers.length <= 0) {
      return null;
    }
    else {
      let match = memoAllCaseManagerUsers.find(x => eq(stateSelCaseManagerUser, x.email));
      return match ?? null;
    }
  }, [memoAllCaseManagerUsers, stateSelCaseManagerUser]);


  const memoCaseManagerOptions = useMemo<any[]>(() => {
    // generate case manager options from case manager userinfo collection
    let col = _.sortBy([...memoAllCaseManagerUsers], o => NVL(o.name, o.email));
    return col.map((o, i) => {
      return { key: o.email, text: o.name };
    });
  }, [memoAllCaseManagerUsers]);

  //#endregion


  //#region 'conditionally show each 1:N top section'
  //-------------------------

  // based on REO and portfolio level

  const memoShowSectionConnectionDetails = useMemo<boolean>(() => {
    if (stateSelProjectItem == null || stateSelANTypeObject == null) return false;
    else if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (memoSelANTypeIsREO || memoSelANTypeIsPortfolioLevel) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANTypeObject, memoSelANTypeIsREO, memoSelANTypeIsPortfolioLevel]);

  const memoShowSectionBorrowerInfo = useMemo<boolean>(() => {
    if (stateSelProjectItem == null || stateSelANTypeObject == null) return false;
    else if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (memoSelANTypeIsREO || memoSelANTypeIsPortfolioLevel) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANTypeObject, memoSelANTypeIsREO, memoSelANTypeIsPortfolioLevel]);

  const memoShowSectionLoanInfo = useMemo<boolean>(() => {
    if (stateSelProjectItem == null || stateSelANTypeObject == null) return false;
    else if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (memoSelANTypeIsREO || memoSelANTypeIsPortfolioLevel) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANTypeObject, memoSelANTypeIsREO, memoSelANTypeIsPortfolioLevel]);

  const memoShowSectionAssetInfo = useMemo<boolean>(() => {
    if (stateSelProjectItem == null || stateSelANTypeObject == null) return false;
    else if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (memoSelANTypeIsPortfolioLevel) return false;
    else return true;
  }, [stateSelProjectItem, stateSelANTypeObject, memoSelANTypeIsPortfolioLevel]);

  const memoShowAssetConnDetails = useMemo<boolean>(() => {
    return memoShowSectionAssetInfo && memoSelANTypeIsREO && memoAssetConnDetailItems.length > 0;
  }, [memoShowSectionAssetInfo, memoSelANTypeIsREO, memoAssetConnDetailItems]);


  // show/hide fields and sections based on ANType
  // based on Jesse excel document grid, put into new AN Types SP List columns

  const memoANTypeShowAssetLocation = useMemo<boolean>(() => {
    // inside asset info section (prod altamira has mixed yes/no for this)
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    return GenUtil.safeToBool(stateSelANTypeObject?.fields.AssetLocation);
  }, [stateSelANTypeObject]);

  const memoANTypeShowCommercialActivity = useMemo<boolean>(() => {
    // inside asset info section (prod altamira has mixed yes/no for this)
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    return GenUtil.safeToBool(stateSelANTypeObject?.fields.CommercialActivity);
  }, [stateSelANTypeObject]);

  const memoANTypeShowPublicationDate = useMemo<boolean>(() => {
    // inside asset info section (prod altamira has mixed yes/no for this)
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    return GenUtil.safeToBool(stateSelANTypeObject?.fields.PublicationDate);
  }, [stateSelANTypeObject]);

  const memoANTypeShowLoanInfoSection = useMemo<boolean>(() => {
    // the whole section (prod altamira has mixed yes/no for this)
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    else if (memoSelANTypeIsPortfolioLevel) return false; // special case: when ANType is 'portfolio level' loaninfo is not needed (#todo this clause may not be needed, this is the loan info 1:N section up top, which depends on connections, which are also not relevant for portfolio type)
    return GenUtil.safeToBool(stateSelANTypeObject?.fields.LoanInfo);
  }, [stateSelANTypeObject, memoSelANTypeIsPortfolioLevel]);

  const memoANTypeShowALMSection = useMemo<boolean>(() => {
    // the whole section (prod altamira has mixed yes/no for this)
    // NOTE: this limited check is used below with many more checks to show this section, based on admin overrides, status, permissions, role, etc
    return GenUtil.safeToBool(stateSelANTypeObject?.fields.ALM);
  }, [stateSelANTypeObject]);

  const memoANTypeShowSupportingTables = useMemo<boolean>(() => {
    // NOTE: as of 10-31-23, the prod altamira ANTypes list show/hide matrix has this field always hidden (the Jesse document has this field always hidden (not relevant) for all ANTypes, he confirmed this and is OK in later meeting)
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) return true;
    return GenUtil.safeToBool(stateSelANTypeObject?.fields.SupportingTables);
  }, [stateSelANTypeObject]);

  //#endregion


  //#region 'form permissions: using email address comparisons'
  //-------------------------

  // App3/Servicer (stateAllANUsers)
  const memoPermsApp3SRVUsers = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => GenUtil.eq(x.fields.Title, 'Servicers')).map(x => GenUtil.safeTrim(x.fields.Email).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp3SRV = useMemo<boolean>(() => {
    // NOTE: can be any user identified as a servicer, not just the user(servicer) that created the request
    return GenUtil.contains(`;${memoPermsApp3SRVUsers};`, memoCurUsername);
  }, [memoPermsApp3SRVUsers, memoCurUsername]);


  // App6/CES1 (stateSelCaseManagerUser)
  const memoPermsApp6CES1Users = useMemo<string>(() => {
    // correct way, use the ANUsers list, any CES1 user can jump in and approve (even if its not the selected case manager)
    let col = stateAllANUsers.filter(x => GenUtil.eq(x.fields.Title, 'CES1')).map(x => GenUtil.safeTrim(x.fields.Email).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
    // OLD WAY: this is wrong, use above
    // NOTE: use selected CaseManager instead of app6 CES1 lookup
    // let s = stateSelCaseManagerUser;
    // if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    // s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    // return s;
  }, [stateAllANUsers, stateSelCaseManagerUser, memoCurUsername]);

  const memoPermsIsCurUserApp6CES1 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp6CES1Users};`, memoCurUsername);
  }, [memoPermsApp6CES1Users, memoCurUsername]);


  // App1/CES2 (stateAllANUsers)
  const memoPermsApp1CES2Users = useMemo<string>(() => {
    let col = stateAllANUsers.filter(x => GenUtil.eq(x.fields.Title, 'CES2')).map(x => GenUtil.safeTrim(x.fields.Email).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANUsers, memoCurUsername]);

  const memoPermsIsCurUserApp1CES2 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp1CES2Users};`, memoCurUsername);
  }, [memoPermsApp1CES2Users, memoCurUsername]);


  // App7/CGI1 (stateAllANEntities)
  const memoPermsApp7CGI1Users = useMemo<string>(() => {
    let col = stateAllANEntities.filter(x => GenUtil.eq(x.fields.Title, stateSelProject)).map(x => GenUtil.safeTrim(x.fields.CGI).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANEntities, stateSelProject, memoCurUsername]);

  const memoPermsIsCurUserApp7CGI1 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp7CGI1Users};`, memoCurUsername);
  }, [memoPermsApp7CGI1Users, memoCurUsername]);


  // App2/CGI2 (stateAllANEntities)
  const memoPermsApp2CGI2Users = useMemo<string>(() => {
    let col = stateAllANEntities.filter(x => GenUtil.eq(x.fields.Title, stateSelProject)).map(x => GenUtil.safeTrim(x.fields.CGI).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANEntities, stateSelProject, memoCurUsername]);

  const memoPermsIsCurUserApp2CGI2 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp2CGI2Users};`, memoCurUsername);
  }, [memoPermsApp2CGI2Users, memoCurUsername]);


  // App5/LTH/EO (stateAllANEntities)
  const memoPermsApp5LTHUsers = useMemo<string>(() => {
    let col = stateAllANEntities.filter(x => GenUtil.eq(x.fields.Title, stateSelProject)).map(x => GenUtil.safeTrim(x.fields.LTH).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANEntities, stateSelProject, memoCurUsername]);

  const memoPermsIsCurUserApp5LTH = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp5LTHUsers};`, memoCurUsername);
  }, [memoPermsApp5LTHUsers, memoCurUsername]);


  // App4/REO1 (stateAllANEntities) (alias OWNER1)
  const memoPermsApp4REO1Users = useMemo<string>(() => {
    let col = stateAllANEntities.filter(x => GenUtil.eq(x.fields.Title, stateSelProject)).map(x => GenUtil.safeTrim(x.fields._x0052_EO1).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANEntities, stateSelProject, memoCurUsername]);

  const memoPermsIsCurUserApp4REO1 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp4REO1Users};`, memoCurUsername);
  }, [memoPermsApp4REO1Users, memoCurUsername]);


  // App10/REO2 (stateAllANEntities) (alias OWNER2)
  const memoPermsApp10REO2Users = useMemo<string>(() => {
    let col = stateAllANEntities.filter(x => GenUtil.eq(x.fields.Title, stateSelProject)).map(x => GenUtil.safeTrim(x.fields._x0052_EO2).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANEntities, stateSelProject, memoCurUsername]);

  const memoPermsIsCurUserApp10REO2 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp10REO2Users};`, memoCurUsername);
  }, [memoPermsApp10REO2Users, memoCurUsername]);


  // App10/REO2 (stateAllANEntities)
  // NOTE: special version using CCEmail as lookup from Entity list instead of REO2
  const memoPermsApp10CCREO2Users = useMemo<string>(() => {
    let col = stateAllANEntities.filter(x => GenUtil.eq(x.fields.Title, stateSelProject)).map(x => GenUtil.safeTrim(x.fields.CCEmail).replace(/,/ig, ';'));
    let s = col.join(';');
    if (memoAdmOvrAvail && Consts.admOvrAddCurUserToAllRoles()) s += `;${memoCurUsername};`;
    s = s.replace(/\s+/ig, '').replace(/;+/ig, ';');
    return s;
  }, [stateAllANEntities, stateSelProject, memoCurUsername]);

  const memoPermsIsCurUserApp10CCREO2 = useMemo<boolean>(() => {
    return GenUtil.contains(`;${memoPermsApp10CCREO2Users};`, memoCurUsername);
  }, [memoPermsApp10CCREO2Users, memoCurUsername]);


  const memoPermsIsCurUserAnyRole = useMemo<boolean>(() => {
    // check if current user is a member of ANY role
    return memoPermsIsCurUserApp3SRV || memoPermsIsCurUserApp6CES1 || memoPermsIsCurUserApp1CES2 ||
      memoPermsIsCurUserApp7CGI1 || memoPermsIsCurUserApp2CGI2 || memoPermsIsCurUserApp5LTH ||
      memoPermsIsCurUserApp4REO1 || memoPermsIsCurUserApp10REO2 || memoPermsIsCurUserApp10CCREO2;
  }, [
    memoPermsIsCurUserApp3SRV, memoPermsIsCurUserApp6CES1, memoPermsIsCurUserApp1CES2,
    memoPermsIsCurUserApp7CGI1, memoPermsIsCurUserApp2CGI2, memoPermsIsCurUserApp5LTH,
    memoPermsIsCurUserApp4REO1, memoPermsIsCurUserApp10REO2, memoPermsIsCurUserApp10CCREO2
  ]);


  const memoPermsIsCurUserAnyRole2 = useMemo<boolean>(() => {
    // check if current user is a member of ANY role (not including App3/SRV)
    return memoPermsIsCurUserApp6CES1 || memoPermsIsCurUserApp1CES2 ||
      memoPermsIsCurUserApp7CGI1 || memoPermsIsCurUserApp2CGI2 || memoPermsIsCurUserApp5LTH ||
      memoPermsIsCurUserApp4REO1 || memoPermsIsCurUserApp10REO2 || memoPermsIsCurUserApp10CCREO2;
  }, [
    memoPermsIsCurUserApp6CES1, memoPermsIsCurUserApp1CES2,
    memoPermsIsCurUserApp7CGI1, memoPermsIsCurUserApp2CGI2, memoPermsIsCurUserApp5LTH,
    memoPermsIsCurUserApp4REO1, memoPermsIsCurUserApp10REO2, memoPermsIsCurUserApp10CCREO2
  ]);

  //#endregion


  //#region 'workflow form fields'
  //-------------------------

  /*
    all: Servicer, CES1, CES2, CGI1, CGI2, LTH/EO, REO1, REO2

    always:
      App3/Servicer
      App6/CES1
      App1/CES2

    optionally:
      App7/CGI1
      App2/CGI2
      App5/LTH/EO
      App4/ReoCo1
      App10/ReoCo2
  */


  const memoWorkflowPath = useMemo<number>(() => {
    // get workflow path

    if (GenUtil.inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft)) {
      // if form has never been reviewed by CES1 then provide the default "0" value
      // when CES1 reviews request, no matter what the workflowpath will be calculated and saved as part of the record, which follows the usual 3 choices from above properties
      // this is important, because "Servicer" section is hidden when WorkflowPath==0, and should stay hidden until its submitted to CES1 and CES1 acts on it and submits (and if its returned to Svcr then would be vis and enabled)
      return 0;
    }

    /*
    for Altamira, always return wfpath=2
    NOTE: this path is as follows:
      SVR, CES1, CES2, REO1, REO2
      REO1 and REO2 are now OWNER1 and OWNER2
    FACTS:
      LTH/EO is never used
      CGI, CGI2 is never used
      loanco/reoco decision for workflow is not important
      dutch entity decision is not important
      payment of costs antype/subtype decision is not important
      bbp choice is not important
    */

    return 2;

  }, [memoANNote]);


  const memoShowBBP = useMemo<boolean>(() => {
    // determine if the Below Business Plan should be shown in the approval sections, based on workflow path
    return memoWorkflowPath === 4 ? true : false;
  }, [memoWorkflowPath]);


  //-------------------------
  // action fields


  const [stateSelApp3Action, setStateSelApp3Action] = useState<string>('');
  const onChangeApp3Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp3Action(option ? option.key + '' : ''); };

  const memoApp3ActionChoices = useMemo(() => {
    let actions = [];

    if (GenUtil.contains(memoANNote.WFStatus, 'Requests Additional Information') ||
      GenUtil.contains(memoANNote.WFStatus, 'Request Additional Information from Servicer'))
      actions.push(StaticData.wfActionSubmitAdditionalInfo);
    else
      actions.push(StaticData.wfActionSubmit);

    actions.push(StaticData.wfActionWithdraw);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp6Action, setStateSelApp6Action] = useState<string>('');
  const onChangeApp6Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp6Action(option ? option.key + '' : ''); }

  const memoApp6ActionChoices = useMemo(() => {
    let actions = [];

    if (GenUtil.eq(memoANNote.WFStatus, 'Additional Information from CES - Case Manager') ||
      GenUtil.eq(memoANNote.WFStatus, 'Request Additional Information from CES - Case Manager') ||
      GenUtil.eq(memoANNote.WFStatus, 'Additional Information from CES1') ||
      GenUtil.contains(memoANNote.WFStatus, 'Additional Information from CES1'))
      actions.push(StaticData.wfActionProvideAdditionalInfo);
    else
      actions.push(StaticData.wfActionRecommend);

    actions.push(StaticData.wfActionRequestAdditionalInfo);
    actions.push(StaticData.wfActionReassign);
    // actions.push(StaticData.wfActionReject); // change 3/21/23 as per Jesse/tax-team

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp1Action, setStateSelApp1Action] = useState<string>('');
  const onChangeApp1Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp1Action(option ? option.key + '' : ''); }

  const memoApp1ActionChoices = useMemo(() => {
    let actions = [];

    if (GenUtil.contains(memoANNote.WFStatus, 'Requests Additional Information'))
      actions.push(StaticData.wfActionProvideAdditionalInfo);
    else
      actions.push(StaticData.wfActionSignRecommend);

    actions.push(StaticData.wfActionRequestAdditionalInfo);
    //actions.push(StaticData.wfActionReject); // change 3/21/23 as per Jesse/tax-team

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp7Action, setStateSelApp7Action] = useState<string>('');
  const onChangeApp7Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp7Action(option ? option.key + '' : ''); }

  const memoApp7ActionChoices = useMemo(() => {
    let actions = [];

    //#todo more rules in notes, may not be relevant

    if (GenUtil.contains(memoANNote.WFStatus, 'Requests Additional Information'))
      actions.push(StaticData.wfActionProvideAdditionalInfo);
    else
      actions.push(StaticData.wfActionSignApprove);

    actions.push(StaticData.wfActionRequestAdditionalInfo);
    actions.push(StaticData.wfActionReject);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp2Action, setStateSelApp2Action] = useState<string>('');
  const onChangeApp2Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp2Action(option ? option.key + '' : ''); }

  const memoApp2ActionChoices = useMemo(() => {
    let actions = [];

    //#todo more rules in notes, may not be relevant

    if (GenUtil.contains(memoANNote.WFStatus, 'Requests Additional Information from CGI'))
      actions.push(StaticData.wfActionProvideAdditionalInfo);
    else
      actions.push(StaticData.wfActionSignApprove);

    actions.push(StaticData.wfActionRequestAdditionalInfo);
    actions.push(StaticData.wfActionReject);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp5Action, setStateSelApp5Action] = useState<string>('');
  const onChangeApp5Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp5Action(option ? option.key + '' : ''); }

  const memoApp5ActionChoices = useMemo(() => {
    let actions = [];

    if (GenUtil.contains(memoANNote.WFStatus, 'Requests Additional Information'))
      actions.push(StaticData.wfActionProvideAdditionalInfo);
    else
      actions.push(StaticData.wfActionSignApprove);

    actions.push(StaticData.wfActionRequestAdditionalInfo);
    actions.push(StaticData.wfActionReject);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp4Action, setStateSelApp4Action] = useState<string>('');
  const onChangeApp4Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp4Action(option ? option.key + '' : ''); }

  const memoApp4ActionChoices = useMemo(() => {
    let actions = [];

    if (GenUtil.contains(memoANNote.WFStatus, 'Requests Additional Information'))
      actions.push(StaticData.wfActionProvideAdditionalInfo);
    else
      actions.push(StaticData.wfActionSignApprove);

    actions.push(StaticData.wfActionRequestAdditionalInfo);
    actions.push(StaticData.wfActionReject);

    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  const [stateSelApp10Action, setStateSelApp10Action] = useState<string>('');
  const onChangeApp10Action = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp10Action(option ? option.key + '' : ''); }

  const memoApp10ActionChoices = useMemo(() => {
    let actions = [];
    actions.push(StaticData.wfActionSignApprove);
    actions.push(StaticData.wfActionRequestAdditionalInfo);
    actions.push(StaticData.wfActionReject);
    return actions.map(o => { return { key: o, text: o }; });
  }, [memoANNote]);


  //-------------------------
  // bbp fields


  const [stateSelApp6BBP, setStateSelApp6BBP] = useState<string>('');
  const onChangeApp6BBP = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp6BBP(option ? option.key + '' : ''); }


  const [stateSelApp7BBP, setStateSelApp7BBP] = useState<string>('');
  const onChangeApp7BBP = (event: any, option?: IComboBoxOption, index?: number, value?: string) => { setStateSelApp7BBP(option ? option.key + '' : ''); }


  //-------------------------
  // comment mlot fields (for all sections)

  const [stateApp3Comment, setStateApp3Comment] = useState('');
  const onChangeApp3Comment = useCallback((evt: any, v?: string) => { setStateApp3Comment(v || ''); }, []);

  const [stateApp6Comment, setStateApp6Comment] = useState('');
  const onChangeApp6Comment = useCallback((evt: any, v?: string) => { setStateApp6Comment(v || ''); }, []);

  const [stateApp1Comment, setStateApp1Comment] = useState('');
  const onChangeApp1Comment = useCallback((evt: any, v?: string) => { setStateApp1Comment(v || ''); }, []);

  const [stateApp7Comment, setStateApp7Comment] = useState('');
  const onChangeApp7Comment = useCallback((evt: any, v?: string) => { setStateApp7Comment(v || ''); }, []);

  const [stateApp2Comment, setStateApp2Comment] = useState('');
  const onChangeApp2Comment = useCallback((evt: any, v?: string) => { setStateApp2Comment(v || ''); }, []);

  const [stateApp5Comment, setStateApp5Comment] = useState('');
  const onChangeApp5Comment = useCallback((evt: any, v?: string) => { setStateApp5Comment(v || ''); }, []);

  const [stateApp4Comment, setStateApp4Comment] = useState('');
  const onChangeApp4Comment = useCallback((evt: any, v?: string) => { setStateApp4Comment(v || ''); }, []);

  const [stateApp10Comment, setStateApp10Comment] = useState('');
  const onChangeApp10Comment = useCallback((evt: any, v?: string) => { setStateApp10Comment(v || ''); }, []);

  //#endregion


  //#region 'workflow section show/hide and enable/disable'
  //-------------------------

  // App3/Servicer
  const memoWFSectionHideApp3 = useMemo<boolean>(() => {
    //hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    //hide: contains(viewedsrv, "0")
    else if (GenUtil.contains(memoANNote.ViewedSRV, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp3 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_srvusers), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp3SRV) return true;
    //disable: not(contains(formstatus, "Servicer"))
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusServicer)) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp3SRV]);


  // App6/CES1
  const memoWFSectionHideApp6 = useMemo<boolean>(() => {
    //hide: isnewmode || contains(formstatus, "Saved") || contains(formstatus, "Draft")
    if (memoIsNewItem || GenUtil.inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft)) return true;
    else return false;
  }, [memoFormStatus, memoIsNewItem]);

  const memoWFSectionDisableApp6 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_ces1users), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp6CES1) return true;
    //disable: not(contains(formstatus, "CES1"))
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusCES1)) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp6CES1]);

  // #todo can use this to wipe the comments field if the workflow section is visible and enabled (required a change in the order of setting ANNote in the effect to load existing AN)
  // useEffect(() => {
  //   if (!memoWFSectionHideApp6 && !memoWFSectionDisableApp6) {
  //     setStateApp6Comment('');
  //   }
  // }, [memoANNote, memoWFSectionHideApp6, memoWFSectionDisableApp6]);


  // App1/CES2
  const memoWFSectionHideApp1 = useMemo<boolean>(() => {
    //hide: isnewmode
    if (memoIsNewItem) return true;
    //hide: contains(ViewedCES2, "0")
    else if (GenUtil.contains(memoANNote.ViewedCES2, "0")) return true;
    else return false;
  }, [memoANNote, memoIsNewItem]);

  const memoWFSectionDisableApp1 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_ces2users), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp1CES2) return true;
    //disable: not(contains(formstatus, "CES2")) || contains(wfstatus, "Awaiting CES Review")
    //#todo: this is the special case here too for checking wfstatus, matching the submit function exception
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusCES2) || GenUtil.contains(memoANNote.WFStatus, "Awaiting CES Review")) return true;
    else return false;
  }, [memoANNote, memoFormStatus, memoPermsIsCurUserApp1CES2]);


  // App7/CGI1
  const memoWFSectionHideApp7 = useMemo<boolean>(() => {
    //hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    //hide: contains(workflowpath, "2")
    else if (memoWorkflowPath === 2) return true;
    //hide: contains(viewedCGI1, "0")
    else if (GenUtil.contains(memoANNote.ViewedCGI1, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp7 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_CGI1Users), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp7CGI1) return true;
    //disable: not(contains(formstatus, "CGI1"))
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusCGI1)) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp7CGI1]);


  // App2/CGI2
  const memoWFSectionHideApp2 = useMemo<boolean>(() => {
    //hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    //hide: contains(workflowpath, "2")
    else if (memoWorkflowPath === 2) return true;
    //hide: contains(viewedCGI2, "0")
    else if (GenUtil.contains(memoANNote.ViewedCGI2, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp2 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_cgi2users), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp2CGI2) return true;
    //disable: not(contains(formstatus, "CGI2"))
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusCGI2)) return true;
    //disable: equals(toLower(adm_app7email), toLower(current_user_email)) && not(contains(toLower(current_user_email), "kdcastillo")) && not(contains(toLower(current_user_email), "jvkuijk"))
    //*NOTE: this rule is to prevent the same user to be approver for both app7/CGI1 and app2/CGI2
    else if (!Consts.admOvrAddCurUserToAllRoles() && GenUtil.contains(`;${memoANNote.App7Email};`, memoCurUsername)) return true;
    else return false;
  }, [memoANNote, memoFormStatus, memoPermsIsCurUserApp2CGI2, memoCurUsername]);

  const memoHasSameCGIs = useMemo<boolean>(() => {
    // helper to determine if CGI1 and CGI2 are the same person, during the CGI2 WF Step
    if (GenUtil.eq(memoFormStatus, StaticData.wfStatusCGI2) && GenUtil.contains(`;${memoANNote.App7Email};`, memoCurUsername))
      // when form status is CGI2, make sure current user is not same person that signed at CGI1/app7 step
      return true;
    else
      return false;
  }, [memoANNote, memoFormStatus, memoCurUsername]);


  // App5/LTH/EO
  const memoWFSectionHideApp5 = useMemo<boolean>(() => {
    //hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    //hide: contains(workflowpath, "2")
    else if (memoWorkflowPath === 2) return true;
    //hide: contains(viewedLTH, "0")
    else if (GenUtil.contains(memoANNote.ViewedLTH, "0")) return true;
    //hide: contains(dd_belowBP_CGI1, "No")
    else if (GenUtil.contains(stateSelApp7BBP, "No")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath, stateSelApp7BBP]);

  const memoWFSectionDisableApp5 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_LTHUsers), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp5LTH) return true;
    //disable: not(contains(formstatus, "LTH")) && (contains(workflowpath, "1") || contains(workflowpath, "3") || contains(workflowpath, "4") || contains(workflowpath, "5"))
    //#todo: can likely remove all the workflowpath checks here, the section would be hidden otherwise
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusLTH) && (memoWorkflowPath === 1 || memoWorkflowPath === 3 || memoWorkflowPath === 4 || memoWorkflowPath === 5)) return true;
    else return false;
  }, [memoFormStatus, memoWorkflowPath, memoPermsIsCurUserApp5LTH]);


  // App4/REO1
  const memoWFSectionHideApp4 = useMemo<boolean>(() => {
    //hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    //hide: contains(viewedREO1, "0")
    else if (GenUtil.contains(memoANNote.ViewedREO1, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp4 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_REO1Users), toLower(current_user_email)))
    if (!memoPermsIsCurUserApp4REO1) return true;
    //disable: not(contains(formstatus, "REO1"))
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusREO1)) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp4REO1]);


  // App10/REO2
  const memoWFSectionHideApp10 = useMemo<boolean>(() => {
    //hide: contains(workflowpath, "0")
    if (memoWorkflowPath === 0) return true;
    //hide: contains(viewedREO2, "0")
    else if (GenUtil.contains(memoANNote.ViewedREO2, "0")) return true;
    else return false;
  }, [memoANNote, memoWorkflowPath]);

  const memoWFSectionDisableApp10 = useMemo<boolean>(() => {
    //disable: not(contains(toLower(adm_REO2Users), toLower(current_user_email)))
    //*NOTE: use adm_REO2Users here, but will be using the js_CCREO2 during the submit button click logic
    if (!memoPermsIsCurUserApp10REO2) return true;
    //disable: not(contains(formstatus, "REO2"))
    else if (!GenUtil.contains(memoFormStatus, StaticData.wfStatusREO2)) return true;
    else return false;
  }, [memoFormStatus, memoPermsIsCurUserApp10REO2]);


  const memoWFSectionIsAnyShownEnabled = useMemo<boolean>(() => {
    // determine if any workflow section is shown AND enabled
    // we can use this with the [memoPermsIsCurUserAnyRole] memo to determine if the current user has any role/permission and ability to submit a workflow action
    let r = false;
    r = r || (!memoWFSectionHideApp3 && !memoWFSectionDisableApp3);
    r = r || (!memoWFSectionHideApp6 && !memoWFSectionDisableApp6);
    r = r || (!memoWFSectionHideApp1 && !memoWFSectionDisableApp1);
    r = r || (!memoWFSectionHideApp7 && !memoWFSectionDisableApp7);
    r = r || (!memoWFSectionHideApp2 && !memoWFSectionDisableApp2);
    r = r || (!memoWFSectionHideApp5 && !memoWFSectionDisableApp5);
    r = r || (!memoWFSectionHideApp4 && !memoWFSectionDisableApp4);
    r = r || (!memoWFSectionHideApp10 && !memoWFSectionDisableApp10);
    return r;
  }, [
    memoWFSectionHideApp3, memoWFSectionDisableApp3,
    memoWFSectionHideApp6, memoWFSectionDisableApp6,
    memoWFSectionHideApp1, memoWFSectionDisableApp1,
    memoWFSectionHideApp7, memoWFSectionDisableApp7,
    memoWFSectionHideApp2, memoWFSectionDisableApp2,
    memoWFSectionHideApp5, memoWFSectionDisableApp5,
    memoWFSectionHideApp4, memoWFSectionDisableApp4,
    memoWFSectionHideApp10, memoWFSectionDisableApp10
  ]);

  //#endregion


  //#region 'saving/submitting form'
  //-------------------------

  // readonly check for case manager DDL

  const memoCaseManagerIsReadOnly = useMemo<boolean>(() => {
    // this field is enabled when: Is_New_Mode || FormStatus==Saved || FormStatus==Draft || FormStatus==CES1(and App6Action is Reassign)
    if (memoIsNewItem || GenUtil.inn(memoFormStatus, StaticData.wfStatusSaved, StaticData.wfStatusDraft))
      return false;
    else if (GenUtil.inn(memoFormStatus, StaticData.wfStatusCES1) && stateSelApp6Action && stateSelApp6Action === StaticData.wfActionReassign)
      return false;
    else
      return true;
  }, [memoIsNewItem, memoFormStatus, stateSelApp6Action]);


  // show/hide rules for save/submit/cancel buttons

  const memoShowSaveButton = useMemo<boolean>(() => {
    // enable if isnewmode or is Draft or Saved
    // NOTE: if status is Servicer, force the user to submit, saving is not an option (this is when "returned to servicer"), this also goes for CES1 (since they case edit most of form now too)
    if (memoIsNewItem || GenUtil.inn(memoFormStatus, StaticData.wfStatusDraft, StaticData.wfStatusSaved))
      return true;
    else
      return false;
  }, [memoIsNewItem, memoFormStatus]);


  const memoShowSaveButtonCES1 = useMemo<boolean>(() => {
    // special save button for CES1 step, separate than other save and submit buttons
    // CES1 wants to be able to save the form (the parts they are allowed to update) instead of only having to submit or return
    // enable the button when formstatus=CES1 and user has CES1 permissions
    if (GenUtil.inn(memoFormStatus, StaticData.wfStatusCES1) && memoPermsIsCurUserApp6CES1)
      return true;
    else
      return false;
  }, [memoFormStatus, memoPermsIsCurUserApp6CES1]);


  const memoEnableSubmitButton = useMemo<boolean>(() => {
    // disable if form status is terminal, otherwise allow submitting
    if (GenUtil.inn(memoFormStatus, StaticData.wfStatusCompleted, StaticData.wfStatusApproved, StaticData.wfStatusRejected, StaticData.wfStatusWithdrawn))
      return false;
    else {
      if (memoFormIsReadOnly) {
        // form is readonly, submit button is only useful to submit a workflow action, therefore user must have permission to use a workflow section to submit and the AN is has that section shown/enabled
        // #todo may have to revisit which readonly memo to use here
        return memoPermsIsCurUserAnyRole && memoWFSectionIsAnyShownEnabled;
      }
      else {
        return true;
      }
    }
  }, [memoFormStatus, memoFormIsReadOnly, memoPermsIsCurUserAnyRole, memoWFSectionIsAnyShownEnabled]);


  const memoEnableCancelButton = useMemo<boolean>(() => {
    // always allow user to cancel and navigate away
    return true;
  }, []);


  const memoShowALMSection = useMemo<boolean>(() => {
    // NOTE: as of 8-28-23 to show this section user needs to have permission to see it and not be at workflow start (app3/servicer)
    if (memoAdmOvrAvail && Consts.admOvrShowAllSectionsFields()) {
      // admin override: lets allow to see/use it
      return true;
    }
    else if (memoSelANTypeIsPortfolioLevel) {
      // special case: when ANType is 'portfolio level' metrics is not needed
      return false;
    }
    else if (memoANTypeShowALMSection === false) {
      // based on ANType hide the section
      return false;
    }
    else if (memoANTypeShowALMSection === true) {
      // based on ANType, the section could be shown, lets continue...
      if (GenUtil.inn(memoFormStatus, StaticData.wfStatusDraft, StaticData.wfStatusSaved, StaticData.wfStatusServicer)) {
        // status is draft/saved/servicer
        return false;
      }
      else {
        // for other status, lets make sure user is allowed to see this section
        if (!memoPermsIsCurUserAnyRole2) {
          // logged in user is not in any role (other than SRV), they don't have rights to see this section
          return false;
        }
        else {
          // logged in user is allowed to see this section
          return true;
        }
      }
    }
    return false; // default to hide
  }, [memoANTypeShowALMSection, memoSelANTypeIsPortfolioLevel, memoFormStatus, memoPermsIsCurUserAnyRole2]);


  function handleSave() {
    saveChanges(true);
  }


  function handleSubmit() {
    saveChanges(false);
  }


  function handleCancel() {
    // navigate back to the root ANNote list
    if (getWinDirty()) {
      // if window is dirty, then user will already be prompted, so do not prompt here
      (window as any).open(stateANNotesListWebUrl, "_top");
    }
    else if ((window as any).confirm("Are you sure?")) {
      (window as any).open(stateANNotesListWebUrl, "_top");
    }
  }


  function saveChanges(fromSaveButton: boolean) {
    // main save changes function!

    // validating
    // NOTE: only show toast warning saving/submitting should be stopped (first draft save has fewer conditions to prevent user from saving, submitting has full form validation)
    setStateFormSubmitted(true);

    if (fromSaveButton) {
      // limited validation for "save"
      if (!validateForm(true, false)) {
        AppHelper.toastWarn("Form is not valid, please correct any errors displayed before saving: " + __badFields);
        return;
      }
    }
    else {
      // full validation for "submit"
      if (!validateForm(false, false)) {
        AppHelper.toastWarn("Form is not valid, please correct any errors displayed before submitting: " + __badFields);
        return;
      }
    }


    // create new empty element for sending inserts/updates to SP
    let an: Partial<ANNoteFields> = {};


    // setup main wf fields and defaults
    let curFormStatus: string = memoFormStatus;

    let curActionGroup: string = GenUtil.eq(curFormStatus, StaticData.wfStatusDraft) ? StaticData.wfStatusServicer : curFormStatus;

    if (GenUtil.eq(curFormStatus, StaticData.wfStatusSaved))
      curFormStatus = StaticData.wfStatusDraft; // the order of this LOC is important

    let curWFStatus: string = GenUtil.safeTrim(memoANNote.WFStatus);

    let curWFPath: number = memoWorkflowPath;

    let app6BBP = stateSelApp6BBP;
    let app7BBP = stateSelApp7BBP;
    if (GenUtil.isNull(app7BBP)) {
      app7BBP = app6BBP;
    }

    let beaconCurrent = GenUtil.safeTrim(memoANNote.BeaconStage);

    let sendEmailTo = "";


    console.log('curFormStatus', curFormStatus);
    console.log('curActionGroup', curActionGroup);
    console.log('curWFStatus', curWFStatus);
    console.log('curWFPath', curWFPath);
    console.log('app6BBP', app6BBP);
    console.log('app7BBP', app7BBP);
    console.log('beaconCurrent', beaconCurrent);


    __tracking += `curFormStatus=${curFormStatus}/curWFStatus=${curWFStatus}/curWFPath=${curWFPath}/curUsername=${memoCurUsername}/`;


    updatePayloadForEverySave(an, curActionGroup);

    if (memoIsNewItem) {
      // saving a NEW record
      // NOTE: this branch will only run a single time

      updatePayloadForNew(an);
      updatePayloadSavingMainFormPart1(an);
      updatePayloadSavingMainFormPart2(an);
      updatePayloadForSavingCaseManager(an);
      updatePayloadDefaultWorkflowValues(an);

      if (fromSaveButton) {
        // Save button clicked (save and quit, user will come back and submit later)
        // 1-save new record (full save NEW, set to Saved)
        __tracking += `save/`;

        an.FormStatus = StaticData.wfStatusSaved;

      }
      else {
        // Submit button clicked (save and submit immed into workflow)
        // 3-submit for appr new record (full save NEW, set to CES1)
        __tracking += `submit/`;

        updatePayloadSubmitToCES1(an);
      }

      updatePayloadSaveTracking(an);

      graphCreateRecord(an, "Advisory Note Created");

    }
    else {
      // update an EXISTING record
      // NOTE: this branch will run on all subsequent saves/submits

      if (!memoFormIsReadOnly) {
        updatePayloadSavingMainFormPart1(an);
        updatePayloadSavingMainFormPart2(an);
      }

      if (!memoFormIsReadOnlyCES1) {
        updatePayloadSavingMainFormPart2(an);
      }

      if (!memoCaseManagerIsReadOnly) {
        updatePayloadForSavingCaseManager(an); // for reassign
      }

      if (memoFormIsReadOnly && memoALMIsEditable)
        updatePayloadMetrics(an); // special case, CES1 can edit ALM section

      if (fromSaveButton) {
        // Save button clicked
        // 2-save existing record (full save EXISTING, set to Saved)
        __tracking += `save/`;

        an.FormStatus = StaticData.wfStatusSaved;

        updatePayloadSaveTracking(an);

        graphUpdateRecord(an, "Advisory Note Saved");

      }
      else {
        // Submit button clicked
        // 4-if curstatus is Draft/Saved/Servicer, full save EXISTING, set to CES1
        // 5-else limited save, wf action section determines next steps

        __tracking += `submit/`;


        //#region 'DRAFT AND RECOMMEND'

        if (GenUtil.eq(curFormStatus, StaticData.wfStatusDraft) ||
          (GenUtil.eq(curFormStatus, StaticData.wfStatusCES1) && GenUtil.eq(stateSelApp6Action, StaticData.wfActionReassign))) {

          updatePayloadSubmitToCES1(an);

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES1) && GenUtil.eq(stateSelApp6Action, StaticData.wfActionRecommend)) {

          console.log("***WFSTEP", "1986");
          __tracking += `1986/`;

          an.FormStatus = StaticData.wfStatusCES2;
          an.WFStatus = "Recommend to CES2 - Recommenders";
          an.ViewedCES2 = "1";

          an.App6Action = "Recommend to CES2";
          an.App6Date = today.toISOString();
          an.App6Email = memoCurUsername;
          an.App6Name = memoCurUserDispName;

          an.App1Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES2;

          an.SendEmailTo = memoPermsApp1CES2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusServicer) && GenUtil.eq(stateSelApp3Action, StaticData.wfActionSubmit)) {

          console.log("***WFSTEP", "2010");
          __tracking += `2010/`;

          an.FormStatus = StaticData.wfStatusCES1;
          an.WFStatus = "Submit to CES Review";
          an.ViewedCES1 = "1";
          an.ViewedSRV = "1"; // #todo is this right?

          an.App3Action = "Submit to CES Review";
          an.App3Date = today.toISOString();
          an.App3Email = memoCurUsername;
          an.App3Name = memoCurUserDispName;

          an.App6Action = "";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES1;

          an.SendEmailTo = stateSelCaseManagerUser;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES2) && GenUtil.eq(stateSelApp1Action, StaticData.wfActionSignRecommend)) {

          if (curWFPath === 1 || curWFPath === 3 || curWFPath === 4 || curWFPath === 5) {

            console.log("***WFSTEP", "2036");
            __tracking += `2036/`;

            an.FormStatus = StaticData.wfStatusCGI1;
            an.WFStatus = "CES recommends to CGI";
            an.ViewedCGI1 = "1";

            an.App1Action = "CES recommends to CGI";
            an.App1Date = today.toISOString();
            an.App1Email = memoCurUsername;
            an.App1Name = memoCurUserDispName;

            an.App7Action = "";

            an.SendEmailTo = memoPermsApp7CGI1Users;
            an.SendEmail = "Yes";
            an.EmailSubject = StaticData.defaultEmailSubject;

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = StaticData.wfStatusCGI1;

          }
          else {

            console.log("***WFSTEP", "2060");
            __tracking += `2060/`;

            an.FormStatus = StaticData.wfStatusREO1;
            an.WFStatus = "CES recommends to OWNER1";
            an.ViewedREO1 = "1";

            an.App1Action = "CES recommends to OWNER1";
            an.App1Date = today.toISOString();
            an.App1Email = memoCurUsername;
            an.App1Name = memoCurUserDispName;

            an.App4Action = "";

            an.SendEmailTo = memoPermsApp4REO1Users;
            an.SendEmail = "Yes";
            an.EmailSubject = StaticData.defaultEmailSubject;

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = StaticData.wfStatusREO1;

          }

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI1) && GenUtil.eq(stateSelApp7Action, StaticData.wfActionSignApprove)) {

          console.log("***WFSTEP", "2086");
          __tracking += `2086/`;

          an.FormStatus = StaticData.wfStatusCGI2;
          an.WFStatus = "Signed by CGI - Signature 1";
          an.ViewedCGI2 = "1";

          an.App7Action = "Signed by CGI - Signature 1";
          an.App7Date = today.toISOString();
          an.App7Email = memoCurUsername;
          an.App7Name = memoCurUserDispName;
          an.App7BBP = app7BBP;

          an.App2Action = "";
          an.App2BBP = app7BBP;

          an.SendEmailTo = memoPermsApp2CGI2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCGI2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI2) && GenUtil.eq(stateSelApp2Action, StaticData.wfActionSignApprove)) {

          if (GenUtil.eq(app7BBP, "Yes") && curWFPath === 4) {

            console.log("***WFSTEP", "2114");
            __tracking += `2114/`;

            an.FormStatus = StaticData.wfStatusLTH;
            an.WFStatus = "CGI recommends to Economic Owner";
            an.ViewedLTH = "1";

            an.App2Action = "CGI recommends to EO";
            an.App2Date = today.toISOString();
            an.App2Email = memoCurUsername;
            an.App2Name = memoCurUserDispName;

            an.App5Action = "";

            an.SendEmailTo = memoPermsApp5LTHUsers;
            an.SendEmail = "Yes";
            an.EmailSubject = StaticData.defaultEmailSubject;

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = StaticData.wfStatusLTH;

          }
          else {

            console.log("***WFSTEP", "2138");
            __tracking += `2138/`;

            an.FormStatus = StaticData.wfStatusApproved;
            an.WFStatus = StaticData.wfStatusApproved;

            an.App2Action = "Signed by CGI - Signature 2";
            an.App2Date = today.toISOString();
            an.App2Email = memoCurUsername;
            an.App2Name = memoCurUserDispName;

            an.SendEmailTo = GenUtil.safeTrim(memoANNote.CreatedByEmail);
            an.SendEmail = "Yes";
            an.EmailSubject = "Advisory Note Approved";

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = StaticData.wfStatusApproved;

          }

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusLTH) && GenUtil.eq(stateSelApp5Action, StaticData.wfActionSignApprove)) {

          console.log("***WFSTEP", "2161");
          __tracking += `2161/`;

          an.FormStatus = StaticData.wfStatusApproved;
          an.WFStatus = StaticData.wfStatusApproved;

          an.App5Action = StaticData.wfStatusApproved;
          an.App5Date = today.toISOString();
          an.App5Email = memoCurUsername;
          an.App5Name = memoCurUserDispName;

          an.SendEmailTo = GenUtil.safeTrim(memoANNote.CreatedByEmail);
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Approved";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Approve"; // NOTE: not "Approved"?

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO1) && GenUtil.eq(stateSelApp4Action, StaticData.wfActionSignApprove)) {

          console.log("***WFSTEP", "2182");
          __tracking += `2182/`;

          an.FormStatus = StaticData.wfStatusREO2;
          an.WFStatus = "Seeks Advice from OWNER2";
          an.ViewedREO2 = "1";

          an.App4Action = "Seeks Advice from OWNER2";
          an.App4Date = today.toISOString();
          an.App4Email = memoCurUsername;
          an.App4Name = memoCurUserDispName;

          an.App10Action = "";

          an.SendEmailTo = memoPermsApp10REO2Users + ";" + memoPermsApp10CCREO2Users; // using special CC users here; NOTE: updated 1-30-24 include both
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusREO2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO2) && GenUtil.eq(stateSelApp10Action, StaticData.wfActionSignApprove)) {

          console.log("***WFSTEP", "2206");
          __tracking += `2206/`;

          an.FormStatus = StaticData.wfStatusApproved;
          an.WFStatus = StaticData.wfStatusApproved;

          an.App10Action = StaticData.wfStatusApproved;
          an.App10Date = today.toISOString();
          an.App10Email = memoCurUsername;
          an.App10Name = memoCurUserDispName;

          an.SendEmailTo = GenUtil.safeTrim(memoANNote.CreatedByEmail);
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Approved";

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = "Approve"; // NOTE: not "Approved"?

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusServicer) && GenUtil.eq(curWFStatus, "CES Requests Additional Information from Servicer")) {
          // #todo this is the only IF condition where curWFStatus is used, copied from Kent JS file, but looks wrong, it should be based on App3Action selected, not WFStatus
          // #todo this branch may be the broken one, the program should only run through a single branch, but this branch and another run together in the early workflow lifecycle

          console.log("***WFSTEP", "2228");
          __tracking += `2228/`;

          an.FormStatus = StaticData.wfStatusCES1;
          an.WFStatus = "Awaiting CES Review";
          an.ViewedCES1 = "1";
          an.ViewedSRV = "1"; // #todo is this right?

          // an.CreatedByEmail = memoCurUsername; // do not reset the createdby person
          // an.CreatedByName = memoCurUserDispName;

          // #todo these next seem missing? I'm adding here
          an.App3Action = "Submit to CES Review";
          an.App3Date = today.toISOString();
          an.App3Email = memoCurUsername;
          an.App3Name = memoCurUserDispName;

          an.App6Action = "";
          // #todo since clearing the action, why set the following?
          an.App6Email = stateSelCaseManagerUser;
          if (memoSelCaseManagerItem) {
            an.App6Name = memoSelCaseManagerItem.name;
          }

          an.SendEmailTo = stateSelCaseManagerUser;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "YES";
          an.BeaconStage = StaticData.wfStatusCES1;

        }

        //#endregion


        //#region 'REQUEST ADDITIONAL INFO'

        if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES1) && GenUtil.eq(stateSelApp6Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2257");
          __tracking += `2257/`;

          an.FormStatus = StaticData.wfStatusServicer;
          an.WFStatus = "CES Requests Additional Information from Servicer";
          an.ViewedSRV = "1";
          an.CESReviewed = "No";

          an.App6Action = "CES Requests Additional Information from Servicer";
          an.App6Date = today.toISOString();
          an.App6Email = memoCurUsername;
          an.App6Name = memoCurUserDispName;

          an.App3Action = "";

          an.SendEmailTo = GenUtil.safeTrim(memoANNote.CreatedByEmail);
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusServicer;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES2) && GenUtil.eq(stateSelApp1Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2282");
          __tracking += `2282/`;

          an.FormStatus = StaticData.wfStatusCES1;
          an.WFStatus = "CES2 Requests Additional Information from CES1";

          an.App1Action = "CES2 Requests Additional Information from CES1";
          an.App1Date = today.toISOString();
          an.App1Email = memoCurUsername;
          an.App1Name = memoCurUserDispName;

          an.App6Action = "";

          an.App2BBP = app7BBP;
          an.App7BBP = app7BBP;

          an.SendEmailTo = memoPermsApp6CES1Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES1;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI1) && GenUtil.eq(stateSelApp7Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2308");
          __tracking += `2308/`;

          an.FormStatus = StaticData.wfStatusCES2;
          an.WFStatus = "CGI Requests Additional Information from CES";

          an.App7Action = "CGI Requests Additional Information from CES";
          an.App7Date = today.toISOString();
          an.App7Email = memoCurUsername;
          an.App7Name = memoCurUserDispName;

          an.App1Action = "";

          an.App2Action = "";

          an.App2BBP = app7BBP;
          an.App7BBP = app7BBP;

          an.SendEmailTo = memoPermsApp1CES2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI2) && GenUtil.eq(stateSelApp2Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2336");
          __tracking += `2336/`;

          an.FormStatus = StaticData.wfStatusCGI1;
          an.WFStatus = "CGI2 Requests Additional Information from CGI1";

          an.App2Action = "CGI2 Requests Additional Information from CGI1";
          an.App2Date = today.toISOString();
          an.App2Email = memoCurUsername;
          an.App2Name = memoCurUserDispName;

          an.App7Action = "";

          an.SendEmailTo = memoPermsApp7CGI1Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCGI1;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO2) && GenUtil.eq(stateSelApp10Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2359");
          __tracking += `2359/`;

          an.FormStatus = StaticData.wfStatusREO1;
          an.WFStatus = "OWNER2 Requests Additional Information from OWNER1";

          an.App10Action = "Request Additional Information from OWNER1";
          an.App10Date = today.toISOString();
          an.App10Email = memoCurUsername;
          an.App10Name = memoCurUserDispName;

          an.App4Action = "";

          an.SendEmailTo = memoPermsApp4REO1Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusREO1;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO1) && GenUtil.eq(stateSelApp4Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2382");
          __tracking += `2382/`;

          an.FormStatus = StaticData.wfStatusCES2;
          an.WFStatus = "OWNER1 Requests Additional Information from CES2";

          an.App4Action = "Request Additional Information from CES2";
          an.App4Date = today.toISOString();
          an.App4Email = memoCurUsername;
          an.App4Name = memoCurUserDispName;

          an.App1Action = "";

          an.SendEmailTo = memoPermsApp1CES2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusLTH) && GenUtil.eq(stateSelApp5Action, StaticData.wfActionRequestAdditionalInfo)) {

          console.log("***WFSTEP", "2405");
          __tracking += `2405/`;

          an.FormStatus = StaticData.wfStatusCGI2;
          an.WFStatus = "Economic Owner Requests Additional Information from CGI2";

          an.App5Action = "Request Additional Information from CGI2";
          an.App5Date = today.toISOString();
          an.App5Email = memoCurUsername;
          an.App5Name = memoCurUserDispName;

          an.App2Action = "";

          an.SendEmailTo = memoPermsApp2CGI2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCGI2;

        }

        //#endregion


        //#region 'PROVIDE ADDITIONAL INFO'

        if (GenUtil.eq(curFormStatus, StaticData.wfStatusServicer) && GenUtil.eq(stateSelApp3Action, StaticData.wfActionSubmitAdditionalInfo)) {

          console.log("***WFSTEP", "2431");
          __tracking += `2431/`;

          an.FormStatus = StaticData.wfStatusCES1;
          an.WFStatus = "Servicer Provides Additional Information to CES - Case Manager";
          an.ViewedSRV = "1"; // #todo is this right?

          // an.App3Action = StaticData.wfActionProvideAdditionalInfo;
          an.App3Action = "Submit to CES Review"; // #todo is this right?
          an.App3Date = today.toISOString();
          an.App3Email = memoCurUsername;
          an.App3Name = memoCurUserDispName;

          an.App6Action = "";

          an.SendEmailTo = memoPermsApp6CES1Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES1;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO1) && GenUtil.eq(stateSelApp4Action, StaticData.wfActionProvideAdditionalInfo)) {

          console.log("***WFSTEP", "2454");
          __tracking += `2454/`;

          an.FormStatus = StaticData.wfStatusREO2;
          an.WFStatus = "Provide Additional Information to OWNER2";

          an.App4Action = StaticData.wfActionProvideAdditionalInfo;
          an.App4Date = today.toISOString();
          an.App4Email = memoCurUsername;
          an.App4Name = memoCurUserDispName;

          an.App10Action = "";

          an.SendEmailTo = memoPermsApp10REO2Users + ";" + memoPermsApp10CCREO2Users; // using special CC users here; NOTE: updated 1-30-24 include both
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusREO2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES1) && GenUtil.eq(stateSelApp6Action, StaticData.wfActionProvideAdditionalInfo)) {

          console.log("***WFSTEP", "2477");
          __tracking += `2477/`;

          an.FormStatus = StaticData.wfStatusCES2;
          an.WFStatus = "Case Manager Provides Additional Information to CES - Recommenders";

          an.App6Action = "Case Manager Provides Additional Information";
          an.App6Date = today.toISOString();
          an.App6Email = memoCurUsername;
          an.App6Name = memoCurUserDispName;

          an.App1Action = "";

          an.SendEmailTo = memoPermsApp1CES2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCES2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES2) && GenUtil.eq(stateSelApp1Action, StaticData.wfActionProvideAdditionalInfo)) {

          if (curWFPath === 1 || curWFPath === 3 || curWFPath === 4 || curWFPath === 5) {

            console.log("***WFSTEP", "2502");
            __tracking += `2502/`;

            an.FormStatus = StaticData.wfStatusCGI1;
            an.WFStatus = "CES Provides Additional Information to CGI";

            an.App1Action = "CES Provides Additional Information";
            an.App1Date = today.toISOString();
            an.App1Email = memoCurUsername;
            an.App1Name = memoCurUserDispName;

            an.App2Action = "";

            an.App7Action = "";

            an.SendEmailTo = memoPermsApp7CGI1Users;
            an.SendEmail = "Yes";
            an.EmailSubject = StaticData.defaultEmailSubject;

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = StaticData.wfStatusCGI1;

          }
          else {

            console.log("***WFSTEP", "2527");
            __tracking += `2527/`;

            an.FormStatus = StaticData.wfStatusREO1;
            an.WFStatus = "CES2 Provides Additional Information to OWNER - Signature 1";

            an.App1Action = "CES2 Provides Additional Information";
            an.App1Date = today.toISOString();
            an.App1Email = memoCurUsername;
            an.App1Name = memoCurUserDispName;

            an.App4Action = "";

            an.SendEmailTo = memoPermsApp4REO1Users;
            an.SendEmail = "Yes";
            an.EmailSubject = StaticData.defaultEmailSubject;

            an.BeaconStart = "NO";
            an.BeaconStageEnd = beaconCurrent;
            an.BeaconStage = StaticData.wfStatusREO1;

          }

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI1) && GenUtil.eq(stateSelApp7Action, StaticData.wfActionProvideAdditionalInfo)) {

          console.log("***WFSTEP", "2552");
          __tracking += `2552/`;

          an.FormStatus = StaticData.wfStatusCGI2;
          an.WFStatus = "CGI1 Provides Additional Information to CGI2";

          an.App7Action = "CGI1 Provides Additional Information";
          an.App7Date = today.toISOString();
          an.App7Email = memoCurUsername;
          an.App7Name = memoCurUserDispName;

          an.App2Action = "";

          an.SendEmailTo = memoPermsApp2CGI2Users;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusCGI2;

        }
        else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI2) && GenUtil.eq(stateSelApp2Action, StaticData.wfActionProvideAdditionalInfo)) {

          console.log("***WFSTEP", "2575");
          __tracking += `2575/`;

          an.FormStatus = StaticData.wfStatusLTH;
          an.WFStatus = "Signed by CGI - Signature 2";

          an.App2Action = "Signed by CGI - Signature 2";
          an.App2Date = today.toISOString();
          an.App2Email = memoCurUsername;
          an.App2Name = memoCurUserDispName;

          an.App5Action = "";

          an.SendEmailTo = memoPermsApp5LTHUsers;
          an.SendEmail = "Yes";
          an.EmailSubject = StaticData.defaultEmailSubject;

          an.BeaconStart = "NO";
          an.BeaconStageEnd = beaconCurrent;
          an.BeaconStage = StaticData.wfStatusLTH;

        }

        //#endregion


        //#region 'WITHDRAWN BY SERVICER, CES - CASE MANAGER'

        if (GenUtil.eq(stateSelApp3Action, StaticData.wfActionWithdraw) ||
          GenUtil.eq(stateSelApp6Action, StaticData.wfActionWithdraw)) {

          console.log("***WFSTEP", "2601");
          __tracking += `2601/`;

          an.FormStatus = StaticData.wfStatusWithdrawn;
          an.WFStatus = StaticData.wfStatusWithdrawn;

          if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES1)) {
            // #todo legacy path? CES1 cannot withdraw
            an.App6Action = StaticData.wfStatusWithdrawn;
            an.App6Date = today.toISOString();
            an.App6Email = memoCurUsername;
            an.App6Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";" + GenUtil.safeTrim(memoANNote.App3Email);
          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusServicer)) {
            // #todo I added this else condition and block as seems to be missing
            an.App3Action = StaticData.wfStatusWithdrawn;
            an.App3Date = today.toISOString();
            an.App3Email = memoCurUsername;
            an.App3Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";" + GenUtil.safeTrim(memoANNote.App3Email);
          }

          an.SendEmailTo = sendEmailTo;
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Withdrawn";

        }

        //#endregion


        //#region 'REJECTED'

        if (
          GenUtil.eq(stateSelApp6Action, StaticData.wfActionReject) ||
          GenUtil.eq(stateSelApp1Action, StaticData.wfActionReject) ||
          GenUtil.eq(stateSelApp7Action, StaticData.wfActionReject) ||
          GenUtil.eq(stateSelApp2Action, StaticData.wfActionReject) ||
          GenUtil.eq(stateSelApp5Action, StaticData.wfActionReject) ||
          GenUtil.eq(stateSelApp4Action, StaticData.wfActionReject) || // app4/reo1 was missing, adding 3-21-23
          GenUtil.eq(stateSelApp10Action, StaticData.wfActionReject)
        ) {

          if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES1)) {

            console.log("***WFSTEP", "2636");
            __tracking += `2636/`;

            an.App6Action = StaticData.wfStatusRejected;
            an.App6Date = today.toISOString();
            an.App6Email = memoCurUsername;
            an.App6Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";" +
              GenUtil.safeTrim(memoANNote.App3Email);

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCES2)) {

            console.log("***WFSTEP", "2648");
            __tracking += `2648/`;

            an.App1Action = StaticData.wfStatusRejected;
            an.App1Date = today.toISOString();
            an.App1Email = memoCurUsername;
            an.App1Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";" +
              GenUtil.safeTrim(memoANNote.App3Email) + ";" +
              GenUtil.safeTrim(memoANNote.App6Email);

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI1)) {

            console.log("***WFSTEP", "2661");
            __tracking += `2661/`;

            an.App7Action = StaticData.wfStatusRejected;
            an.App7Date = today.toISOString();
            an.App7Email = memoCurUsername;
            an.App7Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";"
              + GenUtil.safeTrim(memoANNote.App1Email) + ";"
              + GenUtil.safeTrim(memoANNote.App3Email) + ";"
              + GenUtil.safeTrim(memoANNote.App6Email);

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusCGI2)) {

            console.log("***WFSTEP", "2675");
            __tracking += `2675/`;

            if (curWFPath === 1) {
              // #todo legacy path?
              sendEmailTo = memoCurUsername + ";"
                + GenUtil.safeTrim(memoANNote.App1Email) + ";"
                + GenUtil.safeTrim(memoANNote.App3Email) + ";"
                + GenUtil.safeTrim(memoANNote.App6Email) + ";"
                + GenUtil.safeTrim(memoANNote.App7Email);
            }
            else if (curWFPath === 2) {
              // #todo legacy path?
              sendEmailTo = memoCurUsername + ";"
                + GenUtil.safeTrim(memoANNote.App1Email) + ";"
                + GenUtil.safeTrim(memoANNote.App3Email) + ";"
                + GenUtil.safeTrim(memoANNote.App4Email) + ";"
                + GenUtil.safeTrim(memoANNote.App6Email) + ";"
                + GenUtil.safeTrim(memoANNote.App7Email);
            }

            an.App2Action = StaticData.wfStatusRejected;
            an.App2Date = today.toISOString();
            an.App2Email = memoCurUsername;
            an.App2Name = memoCurUserDispName;

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusServicer)) {

            console.log("***WFSTEP", "2701");
            __tracking += `2701/`;

            an.App3Action = StaticData.wfStatusRejected;
            an.App3Date = today.toISOString();
            an.App3Email = memoCurUsername;
            an.App3Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";" +
              GenUtil.safeTrim(memoANNote.App6Email);

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusLTH)) {

            console.log("***WFSTEP", "2713");
            __tracking += `2713/`;

            if (curWFPath === 1) {
              // #todo missing app2?
              sendEmailTo = memoCurUsername + ";" +
                GenUtil.safeTrim(memoANNote.App1Email) + ";" +
                GenUtil.safeTrim(memoANNote.App3Email) + ";" +
                GenUtil.safeTrim(memoANNote.App6Email) + ";" +
                GenUtil.safeTrim(memoANNote.App7Email);
            }
            // #todo no email for other paths?

            an.App5Action = StaticData.wfStatusRejected;
            an.App5Date = today.toISOString();
            an.App5Email = memoCurUsername;
            an.App5Name = memoCurUserDispName;

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO2)) {

            console.log("***WFSTEP", "2731");
            __tracking += `2731/`;

            if (curWFPath === 1 && GenUtil.eq(app7BBP, "Yes")) {
              // #todo legacy path?
              sendEmailTo = memoCurUsername + ";" +
                GenUtil.safeTrim(memoANNote.App5Email) + ";" +
                GenUtil.safeTrim(memoANNote.App2Email) + ";" +
                GenUtil.safeTrim(memoANNote.App7Email) + ";" +
                GenUtil.safeTrim(memoANNote.App1Email) + ";" +
                GenUtil.safeTrim(memoANNote.App6Email) + ";" +
                GenUtil.safeTrim(memoANNote.App3Email);
            }
            else if (curWFPath === 1 && GenUtil.eq(app7BBP, "No")) {
              // #todo legacy path?
              sendEmailTo = memoCurUsername + ";" +
                GenUtil.safeTrim(memoANNote.App2Email) + ";" +
                GenUtil.safeTrim(memoANNote.App7Email) + ";" +
                GenUtil.safeTrim(memoANNote.App1Email) + ";" +
                GenUtil.safeTrim(memoANNote.App6Email) + ";" +
                GenUtil.safeTrim(memoANNote.App3Email);
            }
            else if (curWFPath === 2) {
              sendEmailTo = memoCurUsername + ";" +
                GenUtil.safeTrim(memoANNote.App4Email) + ";" +
                GenUtil.safeTrim(memoANNote.App1Email) + ";" +
                GenUtil.safeTrim(memoANNote.App6Email) + ";" +
                GenUtil.safeTrim(memoANNote.App3Email);
            }

            an.App10Action = StaticData.wfStatusRejected;
            an.App10Date = today.toISOString();
            an.App10Email = memoCurUsername;
            an.App10Name = memoCurUserDispName;

          }
          else if (GenUtil.eq(curFormStatus, StaticData.wfStatusREO1)) {

            console.log("***WFSTEP", "2766");
            __tracking += `2766/`;

            an.App4Action = StaticData.wfStatusRejected;
            an.App4Date = today.toISOString();
            an.App4Email = memoCurUsername;
            an.App4Name = memoCurUserDispName;
            sendEmailTo = memoCurUsername + ";" +
              GenUtil.safeTrim(memoANNote.App1Email) + ";" +
              GenUtil.safeTrim(memoANNote.App6Email) + ";" +
              GenUtil.safeTrim(memoANNote.App3Email);

          }

          an.FormStatus = StaticData.wfStatusRejected;
          an.WFStatus = StaticData.wfStatusRejected;

          an.SendEmailTo = sendEmailTo;
          an.SendEmail = "Yes";
          an.EmailSubject = "Advisory Note Rejected";

        }

        //#endregion


        //#region 'APPROVED'

        if (GenUtil.eq(curFormStatus, StaticData.wfStatusApproved)) {
          /* Already approved, do nothing */
        }

        //#endregion


        updatePayloadSaveTracking(an);

        graphUpdateRecord(an, "Advisory Note Submitted");

      }

    }
  }


  function handleSaveCES1() {
    // special save function for CES1 role to save instead of submitting
    // lets assume the button is visible and user has permission to use it

    setStateFormSubmitted(true);

    if (!validateForm(false, true)) {
      AppHelper.toastWarn("Form is not valid, please correct any errors displayed before submitting: " + __badFields);
      return;
    }

    // create new empty element for sending inserts/updates to SP
    let an: Partial<ANNoteFields> = {};

    // setup main wf fields and defaults
    let curFormStatus: string = memoFormStatus;

    let curActionGroup: string = GenUtil.eq(curFormStatus, StaticData.wfStatusDraft) ? StaticData.wfStatusServicer : curFormStatus;

    if (GenUtil.eq(curFormStatus, StaticData.wfStatusSaved))
      curFormStatus = StaticData.wfStatusDraft; // the order of this LOC is important

    let curWFStatus: string = GenUtil.safeTrim(memoANNote.WFStatus);

    let curWFPath: number = memoWorkflowPath;

    __tracking += `curFormStatus=${curFormStatus}/curWFStatus=${curWFStatus}/curWFPath=${curWFPath}/curUsername=${memoCurUsername}/`;

    updatePayloadForEverySave(an, curActionGroup);

    if (!memoFormIsReadOnly) {
      updatePayloadSavingMainFormPart1(an);
      updatePayloadSavingMainFormPart2(an);
    }

    if (!memoFormIsReadOnlyCES1) {
      updatePayloadSavingMainFormPart2(an);
    }

    if (!memoCaseManagerIsReadOnly) {
      updatePayloadForSavingCaseManager(an); // for reassign
    }

    if (memoFormIsReadOnly && memoALMIsEditable) {
      updatePayloadMetrics(an); // special case, CES1 can edit ALM section
    }

    __tracking += `save/`;

    updatePayloadSaveTracking(an);

    // clear these so no emails are sent
    an.SendEmailTo = "";
    an.SendEmail = "";

    graphUpdateRecord(an, "Advisory Note Saved");

  }

  //#endregion


  //#region 'update AN payload functions'
  //-------------------------

  function updatePayloadSubmitToCES1(an: Partial<ANNoteFields>) {

    console.log("***WFSTEP", "2856");
    __tracking += `2856/`;

    an.FormStatus = StaticData.wfStatusCES1;
    an.WFStatus = "Awaiting CES Review";
    an.ViewedCES1 = "1";
    an.ViewedSRV = "1"; // #todo is this right?

    an.App6Action = "";
    an.App6Email = stateSelCaseManagerUser;

    // #todo shouldn't we be tracking app3 info too (date, email/name=curuser)
    // i'm adding these app3 tracking
    an.App3Action = "Submit to CES Review";
    an.App3Date = today.toISOString();
    an.App3Email = memoCurUsername;
    an.App3Name = memoCurUserDispName;

    an.EmailSubject = StaticData.defaultEmailSubject;
    an.SendEmailTo = stateSelCaseManagerUser;
    an.SendEmail = "Yes";

    // an.CreatedByEmail = memoCurUsername; // do not reset the createdby person
    // an.CreatedByName = memoCurUserDispName;

    an.BeaconStart = "YES";
    an.BeaconStage = StaticData.wfStatusCES1;
  }


  function updatePayloadForNew(an: Partial<ANNoteFields>) {
    // update payload for a first time insert

    // these fields only set when creating new ANNote
    an.Title = `AN-${today.toISOString()}`; // = 'AN-2022-12-09T18:54:51.044Z'
    an.CreatedByEmail = memoCurUsername;
    an.CreatedByName = memoCurUserDispName;
    an.jsVersion = Consts.version;

    // constants
    an.Servicer = StaticData.defaultServicerName;
    an.BrokerCode = StaticData.defaultBrokerCode;
  }


  function updatePayloadDefaultWorkflowValues(an: Partial<ANNoteFields>) {

    an.FormStatus = StaticData.wfStatusDraft;
    an.WFStatus = StaticData.wfStatusDraft;
    an.WorkflowPath = "0";

    an.App10Action = "";
    an.App10Comments = "";
    an.App10Date = "";
    an.App10Email = "";
    an.App10Name = "";
    an.App1Action = "";
    an.App1Comments = "";
    an.App1Date = "";
    an.App1Email = "";
    an.App1Name = "";
    an.App2Action = "";
    an.App2ActionKeyNo = "";
    an.App2ActionKeyYes = "";
    an.App2BBP = "";
    an.App2Comments = "";
    an.App2Date = "";
    an.App2Email = "";
    an.App2Name = "";
    an.App3Action = "";
    an.App3Comments = "";
    an.App3Date = "";
    an.App3Email = "";
    an.App3Name = "";
    an.App4Action = "";
    an.App4ActionReq = "";
    an.App4Comments = "";
    an.App4Date = "";
    an.App4Email = "";
    an.App4Name = "";
    an.App5Action = "";
    an.App5Comments = "";
    an.App5Date = "";
    an.App5Email = "";
    an.App5Name = "";
    an.App6Action = "";
    an.App6BBP = "";
    an.App6Comments = "";
    an.App6Date = "";
    an.App6Email = "";
    an.App6Name = "";
    an.App7Action = "";
    an.App7BBP = "";
    an.App7Comments = "";
    an.App7Date = "";
    an.App7Email = "";
    an.App7Name = "";

    an.BeaconStart = "YES";
    an.BeaconStage = "";
    an.BeaconStageEnd = "";

    an.CESReviewed = "No";
    an.CurActionGroup = StaticData.wfStatusServicer;

    an.EmailSubject = "";
    an.SendEmail = "";
    an.SendEmailTo = "";

    an.ViewedCES1 = "0";
    an.ViewedCES2 = "0";
    an.ViewedCGI1 = "0";
    an.ViewedCGI2 = "0";
    an.ViewedEO = "0";
    an.ViewedLTH = "0";
    an.ViewedPSI = "0";
    an.ViewedREO1 = "0";
    an.ViewedREO2 = "0";
    an.ViewedRPE1 = "0";
    an.ViewedRPE2 = "0";
    an.ViewedSRV = "0";

    an.Addtl_Info = ""; // reset this custom tracking field, i use this as a helper for tracking workflow info/path per step
    an.Browser = ""; // reset browser/user tracking
  }


  function updatePayloadForEverySave(an: Partial<ANNoteFields>, curActionGroup: string) {
    // update payload for every insert/update "save" to graph/sp

    an.Browser = GenUtil.safeTrim(memoANNote.Browser) + `${memoCurUsername} at ${today.toISOString()} using ${(window as any).navigator.userAgent}||`; // concat each time
    an.WorkflowPath = memoWorkflowPath + "";
    an.CurActionGroup = curActionGroup;

    // need to save these manually on each save, the "form save/submit" process doesn't set these, they were connected fields in the Nintext form, and thus auto saved per submit action

    // NOTE: do not save actions from DDLs here, they are handled in the workflow steps, the "overall" action (pretty) is saved, does not exactly match the action DDL values

    an.App6BBP = stateSelApp6BBP;
    an.App7BBP = stateSelApp7BBP;

    an.App3Comments = GenUtil.safeTrim(stateApp3Comment);
    an.App6Comments = GenUtil.safeTrim(stateApp6Comment);
    an.App1Comments = GenUtil.safeTrim(stateApp1Comment);
    an.App7Comments = GenUtil.safeTrim(stateApp7Comment);
    an.App2Comments = GenUtil.safeTrim(stateApp2Comment);
    an.App5Comments = GenUtil.safeTrim(stateApp5Comment);
    an.App4Comments = GenUtil.safeTrim(stateApp4Comment);
    an.App10Comments = GenUtil.safeTrim(stateApp10Comment);
  }


  function updatePayloadSaveTracking(an: Partial<ANNoteFields>) {

    let prefix = '';
    if (!GenUtil.isNull(memoANNote.Addtl_Info))
      prefix = '||';

    an.Addtl_Info = GenUtil.safeTrim(memoANNote.Addtl_Info)
      + prefix
      + 'trigger=' + GenUtil.NVL(
        stateSelApp3Action,
        stateSelApp6Action,
        stateSelApp1Action,
        stateSelApp7Action,
        stateSelApp2Action,
        stateSelApp5Action,
        stateSelApp4Action,
        stateSelApp10Action,
        'Button')
      + ';info=' + __tracking;
  }


  function updatePayloadForSavingCaseManager(an: Partial<ANNoteFields>) {
    // update payload for saving Case Manager only

    an.CaseManager = GenUtil.safeTrim(stateSelCaseManagerUser);
  }


  function updatePayloadSavingMainFormPart1(an: Partial<ANNoteFields>) {
    // update payload for saving all the data in the main form
    // not including Case Manager
    // not including WF sections
    // NOTE: some of the updates are moved to the "Limited" function, since CES1 can edit only some of the form
    // make sure if full form has to be saved, that both this function and "Limited" function are called

    // top section
    an.Project = GenUtil.safeTrim(stateSelProject);
    an.Entities = stateSelProjectItem ? GenUtil.safeTrim(stateSelProjectItem.fields.Projects) : "";
    an.LTHName = GenUtil.safeTrim(memoSelProjectLegalTitleHolder);

    an.AdvisoryNoteType = GenUtil.safeTrim(stateSelANType);

    an.MarkAsUrgent = stateMarkAsUrgent;

    // connection shortcuts
    if (!memoSelANTypeIsREO && stateSelUniqueConns.length > 0) {
      // do not save these for REO type projects
      an.Connection_ID = stateSelUniqueConns[0].rpt_ConnecID;
      an.Connection_Name = stateSelUniqueConns[0].rpt_ConnectionName;
      an.Connection_status = stateSelUniqueConns[0].rpt_ConnectionStatus;
    }
    else if (memoSelANTypeIsREO && memoAssetConnDetailItems.length > 0) {
      // as of 9/2023, client requests saving assoc conn info too for asset only REOCO ANs
      an.Connection_ID = memoAssetConnDetailItems[0].connIdSvr;
      an.Connection_Name = memoAssetConnDetailItems[0].connName;
      an.Connection_status = "";
    }
    else {
      an.Connection_ID = "";
      an.Connection_Name = "";
      an.Connection_status = "";
    }

    // borrower and loan shortcuts
    if (stateBorrowerInfoItems.length > 0) an.BorrowerID = stateBorrowerInfoItems[0].cv_BorrowerID;
    else an.BorrowerID = "";

    if (stateLoanInfoItems.length > 0) an.LoanID1 = stateLoanInfoItems[0].rpt_ServicerLoanID;
    else an.LoanID1 = "";

    // totals shortcuts
    let TotalREValue: number = 0;
    memoShowSectionAssetInfo && stateAssetInfoItems.forEach(o => {
      TotalREValue += GenUtil.safeToNumber(o.rpt_decimal_RealEstateValue);
    });
    an.TotalREValue = GenUtil.numberToCurrency(TotalREValue);

    let UPBTotal = 0;
    memoShowSectionLoanInfo && stateLoanInfoItems.forEach(o => {
      UPBTotal += GenUtil.safeToNumber(o.cv_decimal_UPB);
    });
    an.UPBTotal = GenUtil.numberToCurrency(UPBTotal);

    // moved to other function
    // let TotalPropCosts = 0;
    // ...
    // an.TotalPropCosts = GenUtil.numberToCurrency(TotalPropCosts);

    updatePayloadMetrics(an);

    an.ReqConnMetricsCES = ""; // set to blank, we are not using this anymore

    // mlots/richtext
    // moved to other function

    // special 1:N sections
    an.ConnectionDetails = memoConnectionDetailsXml;
    an.AssetInfo = memoAssetInfoXml;
    an.BorrowerInfo = memoBorrowerInfoXml;
    an.LoanInfo = memoLoanInfoXml;
    // an.ProposedCostsDetails = memoProposedCostsDetailsXml; // moved to other function

    an.AssetConnInfo = JSON.stringify(memoAssetConnDetailItems); // new 1:N section that depends on selected asset(s)

    // approver (regular case manager handled in other function)
    an.LSCaseManager = stateSelLSCaseManagerUser;
  }


  function updatePayloadSavingMainFormPart2(an: Partial<ANNoteFields>) {
    // these fields are editable when status is: draft/saved/servicer AND ces1
    // CES1 is the new situation, when this is the status "some" of the form can be edited, not all (especially not top sections)

    // totals shortcuts
    let TotalPropCosts = 0;
    stateProposedCostsDetailsItems.forEach(o => {
      TotalPropCosts += GenUtil.safeToNumber(o.rpt_decimal_Costs);
    });
    an.TotalPropCosts = GenUtil.numberToCurrency(TotalPropCosts);

    // mlots/richtext
    an.Property_Details = GenUtil.safeTrim(statePropertyDetailsBgInfo); // Supporting Details
    an.Proposal = GenUtil.safeTrim(stateProposalInfo); // Proposal
    an.SupportingTables = GenUtil.safeTrim(stateSupportingTables); // Supporting Tables (likely always hidden in page due to AN Type rules)
    an.Comments = GenUtil.safeTrim(stateRecComments); // Recommendation

    // special 1:N sections
    an.ProposedCostsDetails = memoProposedCostsDetailsXml;
  }


  function updatePayloadMetrics(an: Partial<ANNoteFields>) {

    an.CMGrossUW = GenUtil.safeToNumberOrNull(stateALMCollectionsUW);
    an.CMMultUW = GenUtil.safeToNumberOrNull(stateALMMultipleUW);
    an.CMIRRUW = GenUtil.safeToNumberOrNull(stateALMIRRUW);
    an.CMWALUM = GenUtil.safeToNumberOrNull(stateALMWALUW);
    an.CMGrossBP = GenUtil.safeToNumberOrNull(stateALMCollectionsRevBP);
    an.CMMultBP = GenUtil.safeToNumberOrNull(stateALMMultipleRevBP);
    an.CMIRRBP = GenUtil.safeToNumberOrNull(stateALMIRRRevBP);
    an.CMWALBP = GenUtil.safeToNumberOrNull(stateALMWALRevBP);
    an.CMGrossDelta = GenUtil.safeToNumberOrNull(memoDeltaALMColl);
    an.CMMultDelta = GenUtil.safeToNumberOrNull(memoDeltaALMMult);
    an.CMIRRDelta = GenUtil.safeToNumberOrNull(memoDeltaALMIRR);
    an.CMWALDelta = GenUtil.safeToNumberOrNull(memoDeltaALMWAL);
  }

  //#endregion


  //#region 'graph create/update AN wrappers'
  //-------------------------

  // state and function to control the Fluent UI modal/dialog for showing the save/submit result and navigate back to the list
  const [stateShowDialog, setStateShowDialog] = useState<boolean>(false);
  const [stateDialogMsg, setStateDialogMsg] = useState<string>('');

  function handleBackToList() {
    // redirect back to the SP List, do not close the dialog
    //setStateShowDialog(false);
    window.location.href = stateANNotesListWebUrl;
  }


  function graphCreateRecord(an: Partial<ANNoteFields>, msg: string, skipDialog: boolean = false) {

    setStateSaving(true);

    setTimeout(async () => {

      let payload: any = { fields: { ...an } };

      AppHelper.aiTrackData(StaticData.defaultBrokerCode, stateANItemId, "Calling graphCreateRecord", an);

      let resp = await insertItem(accounts, instance, Config.Settings().SiteRelPathAltamira, Config.Settings().ListTitleANNotesAltamira, payload);

      if (resp.httpStatus < 400) {

        AppHelper.toastSuccess(msg);

        if (memoAdmOvrAvail && (Consts.admOvrUsername() || Consts.admOvrShowDebugInfo() ||
          Consts.admOvrShowAdminSection() || Consts.admOvrEditMode() ||
          Consts.admOvrShowAllSectionsFields())) {

          if (!skipDialog) {
            setStateDialogMsg(msg);
            setStateShowDialog(true);
          }

          setStateSaving(false);

        }
        else {
          setTimeout(() => {
            window.location.href = stateANNotesListWebUrl;
          }, 100);
        }

      }
      else {
        AppHelper.toastError(`Error saving Advisory Note to SharePoint: Msg=${resp.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, "Calling graphCreateRecord", resp);
      }

    }, Consts.sleepMsAjax);
  }


  function graphUpdateRecord(an: Partial<ANNoteFields>, msg: string, skipDialog: boolean = false) {

    setStateSaving(true);

    setTimeout(async () => {

      let payload: any = { ...an };

      AppHelper.aiTrackData(StaticData.defaultBrokerCode, stateANItemId, "Calling graphUpdateRecord", an);

      let resp = await updateItem(accounts, instance, Config.Settings().SiteRelPathAltamira, Config.Settings().ListTitleANNotesAltamira, stateANItemId, payload);

      if (resp.httpStatus < 400) {

        AppHelper.toastSuccess(msg);

        if (memoAdmOvrAvail && (Consts.admOvrUsername() || Consts.admOvrShowDebugInfo() ||
          Consts.admOvrShowAdminSection() || Consts.admOvrEditMode() ||
          Consts.admOvrShowAllSectionsFields())) {

          if (!skipDialog) {
            setStateDialogMsg(msg);
            setStateShowDialog(true);
          }

          setStateSaving(false);

        }
        else {
          setTimeout(() => {
            window.location.href = stateANNotesListWebUrl;
          }, 100);
        }

      }
      else {
        AppHelper.toastError(`Error updating existing Advisory Note in SharePoint: Msg=${resp.httpStatusText}`);
        AppHelper.aiTrackErr(StaticData.defaultBrokerCode, stateANItemId, "Calling graphUpdateRecord", resp);
      }

    }, Consts.sleepMsAjax);
  }

  //#endregion


  //#region 'admin overrides'
  //-------------------------

  function adminSaveForm(mode: number) {

    let an: Partial<ANNoteFields> = {};

    if (mode === 1) {
      // Reset To Draft
      // NOTE: also reset all WF fields to defaults
      updatePayloadDefaultWorkflowValues(an);

    }
    else if (mode === 3) {
      // Admin Override: Edit Mode is activated
      // NOTE: record is tricked into having current Form Status set to DRAFT, so entire form can be saved, but DO NOT SAVE workflow/status related fields
      updatePayloadSavingMainFormPart1(an);
      updatePayloadSavingMainFormPart2(an);
      updatePayloadForSavingCaseManager(an);

    }
    else {
      return;
    }

    graphUpdateRecord(an, 'Admin Override Complete');
  }

  //#endregion


  //#region 'form validation'
  //-------------------------

  // do not show validation errors until the user attempts to save/submit
  const [stateFormSubmitted, setStateFormSubmitted] = useState<boolean>(false); // track if the submit/save button was clicked by user, to trigger validation checks


  function validateForm(isSaveOnly: boolean, skipWorkflow: boolean): boolean {

    let isErr = false;

    __badFields = "";
    let fields: string[] = [];

    isErr = isErr || isErrProject();
    isErrProject() && fields.push('Project');
    console.log("validation:", isErr, "isErrProject");

    isErr = isErr || isErrANType();
    isErrANType() && fields.push('AN Type');
    console.log("validation:", isErr, "isErrANType");

    isErr = isErr || isErrConnection();
    isErrConnection() && fields.push('Connection');
    console.log("validation:", isErr, "isErrConnection");

    isErr = isErr || isErrConnectionStatus();
    isErrConnectionStatus() && fields.push('Connection Status');
    console.log("validation:", isErr, "isErrConnectionStatus");

    isErr = isErr || isErrLSCaseManager();
    isErrLSCaseManager() && fields.push('LS Case Manager');
    console.log("validation:", isErr, "isErrLSCaseManager");

    if (!isSaveOnly) {
      // only validate these next when submitting, not saving (draft/saved)

      isErr = isErr || isErrBorrowerInfo();
      isErrBorrowerInfo() && fields.push('Borrower Section');
      console.log("validation:", isErr, "isErrBorrowerInfo");

      isErr = isErr || isErrLoanInfo();
      isErrLoanInfo() && fields.push('Loan Section');
      console.log("validation:", isErr, "isErrLoanInfo");

      isErr = isErr || isErrAssetInfo();
      isErrAssetInfo() && fields.push('Asset Section');
      console.log("validation:", isErr, "isErrAssetInfo");

      // metrics (when visible)
      isErr = isErr || isErrALMCollectionsUW();
      isErrALMCollectionsUW() && fields.push('Metrics Collections UW');
      console.log("validation:", isErr, "isErrALMCollectionsUW");

      isErr = isErr || isErrALMCollectionsRevBP();
      isErrALMCollectionsRevBP() && fields.push('Metrics Collections RevBP');
      console.log("validation:", isErr, "isErrALMCollectionsRevBP");

      isErr = isErr || isErrALMMultipleUW();
      isErrALMMultipleUW() && fields.push('Metrics Multiple UW');
      console.log("validation:", isErr, "isErrALMMultipleUW");

      isErr = isErr || isErrALMMultipleRevBP();
      isErrALMMultipleRevBP() && fields.push('Metrics Multiple RevBP');
      console.log("validation:", isErr, "isErrALMMultipleRevBP");

      isErr = isErr || isErrALMIRRUW();
      isErrALMIRRUW() && fields.push('Metrics IRR UW');
      console.log("validation:", isErr, "isErrALMIRRUW");

      isErr = isErr || isErrALMIRRRevBP();
      isErrALMIRRRevBP() && fields.push('Metrics IRR RevBP');
      console.log("validation:", isErr, "isErrALMIRRRevBP");

      isErr = isErr || isErrALMWALUW();
      isErrALMWALUW() && fields.push('Metrics WAL UW');
      console.log("validation:", isErr, "isErrALMWALUW");

      isErr = isErr || isErrALMWALRevBP();
      isErrALMWALRevBP() && fields.push('Metrics WAL RevBP');
      console.log("validation:", isErr, "isErrALMWALRevBP");

      isErr = isErr || isErrPropCostInfo();
      isErrPropCostInfo() && fields.push('Proposed Costs');
      console.log("validation:", isErr, "isErrPropCostInfo");

      isErr = isErr || isErrCaseManager();
      isErrCaseManager() && fields.push('Case Manager');
      console.log("validation:", isErr, "isErrCaseManager");

      if (!skipWorkflow) {
        // workflow related fields
        isErr = isErr || isErrApp3Action();
        isErrApp3Action() && fields.push('Servicer Action');
        console.log("validation:", isErr, "isErrApp3Action");

        isErr = isErr || isErrApp6Action();
        isErrApp6Action() && fields.push('CES1 Action');
        console.log("validation:", isErr, "isErrApp6Action");

        isErr = isErr || isErrApp1Action();
        isErrApp1Action() && fields.push('CES2 Action');
        console.log("validation:", isErr, "isErrApp1Action");

        isErr = isErr || isErrApp7Action();
        isErrApp7Action() && fields.push('CGI1 Action');
        console.log("validation:", isErr, "isErrApp7Action");

        isErr = isErr || isErrApp2Action();
        isErrApp2Action() && fields.push('CGI2 Action');
        console.log("validation:", isErr, "isErrApp2Action");

        isErr = isErr || isErrApp5Action();
        isErrApp5Action() && fields.push('LTH Action');
        console.log("validation:", isErr, "isErrApp5Action");

        isErr = isErr || isErrApp4Action();
        isErrApp4Action() && fields.push('OWNER1 Action');
        console.log("validation:", isErr, "isErrApp4Action");

        isErr = isErr || isErrApp10Action();
        isErrApp10Action() && fields.push('OWNER2 Action');
        console.log("validation:", isErr, "isErrApp10Action");

        isErr = isErr || isErrApp6BBP();
        isErrApp6BBP() && fields.push('CES1 BBP');
        console.log("validation:", isErr, "isErrApp6BBP");

        isErr = isErr || isErrApp7BBP();
        isErrApp7BBP() && fields.push('CGI1 BBP');
        console.log("validation:", isErr, "isErrApp7BBP");

        isErr = isErr || isErrApp3Comment();
        isErrApp3Comment() && fields.push('Servicer Comment');
        console.log("validation:", isErr, "isErrApp3Comment");

        isErr = isErr || isErrApp6Comment();
        isErrApp6Comment() && fields.push('CES1 Comment');
        console.log("validation:", isErr, "isErrApp6Comment");

        isErr = isErr || isErrApp1Comment();
        isErrApp1Comment() && fields.push('CES2 Comment');
        console.log("validation:", isErr, "isErrApp1Comment");

        isErr = isErr || isErrApp7Comment();
        isErrApp7Comment() && fields.push('CGI1 Comment');
        console.log("validation:", isErr, "isErrApp7Comment");

        isErr = isErr || isErrApp2Comment();
        isErrApp2Comment() && fields.push('CGI2 Comment');
        console.log("validation:", isErr, "isErrApp2Comment");

        isErr = isErr || isErrApp5Comment();
        isErrApp5Comment() && fields.push('LTH Comment');
        console.log("validation:", isErr, "isErrApp5Comment");

        isErr = isErr || isErrApp4Comment();
        isErrApp4Comment() && fields.push('OWNER1 Comment');
        console.log("validation:", isErr, "isErrApp4Comment");

        isErr = isErr || isErrApp10Comment();
        isErrApp10Comment() && fields.push('OWNER2 Comment');
        console.log("validation:", isErr, "isErrApp10Comment");
      }
    }

    __badFields = fields.join(", ");

    return !isErr;
  }


  const memoIsErrProject = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrProject();
  }, [stateFormSubmitted, stateSelProjectItem]);

  function isErrProject() {
    // always required
    return stateSelProjectItem == null;
  }


  const memoIsErrANType = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrANType();
  }, [stateFormSubmitted, stateSelANTypeObject]);

  function isErrANType() {
    // always required
    return stateSelANTypeObject == null;
  }


  const memoIsErrConnection = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrConnection();
  }, [stateFormSubmitted, memoFormIsReadOnly, stateSelUniqueConns, memoSelANTypeIsPortfolioLevel]);

  function isErrConnection() {
    // connection is always required, even when project is REO doesn't show the connection section, an asset id still must be searched and added by user
    // special case: when ANType is 'portfolio level' connections are not needed
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly || memoSelANTypeIsPortfolioLevel)
      return false;
    return stateSelUniqueConns.length <= 0;
  }

  function isErrConnectionStatus() {
    // when connection details are shown, make sure a status is selected for each connection
    // this function mirrors the ComboBox errorMessage validation in the ANConnectionDetail component
    // it is only used to stop progress in this ANDetail component, let the other component show the errorMessage
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly) return false;
    let count = stateSelUniqueConns.filter(x => GenUtil.isNull(x.rpt_ConnectionStatus)).length;
    return memoShowSectionConnectionDetails && count > 0;
  }


  const memoIsErrBorrowerInfo = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrBorrowerInfo();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoShowSectionBorrowerInfo, stateBorrowerOptions, stateBorrowerInfoItems]);

  function isErrBorrowerInfo() {
    // 1 or more required when visible
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly) return false;
    return memoShowSectionBorrowerInfo && stateBorrowerOptions.length > 0 && stateBorrowerInfoItems.length <= 0;
  }


  const memoIsErrLoanInfo = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrLoanInfo();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoANTypeShowLoanInfoSection, memoShowSectionLoanInfo, stateLoanOptions, stateLoanInfoItems]);

  function isErrLoanInfo() {
    // 1 or more required when visible
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly) return false;
    return memoANTypeShowLoanInfoSection && memoShowSectionLoanInfo && stateLoanOptions.length > 0 && stateLoanInfoItems.length <= 0;
  }


  const memoIsErrAssetInfo = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrAssetInfo();
  }, [stateFormSubmitted, memoFormIsReadOnly, memoShowSectionAssetInfo, stateAssetOptions, stateAssetInfoItems]);

  function isErrAssetInfo() {
    // 1 or more required when visible
    // latest: only validate when form is not readonly
    if (memoFormIsReadOnly) return false;
    return memoShowSectionAssetInfo && stateAssetOptions.length > 0 && stateAssetInfoItems.length <= 0;
  }


  const memoIsErrPropCostInfo = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrPropCostInfo();
  }, [stateFormSubmitted, stateProposedCostsDetailsItems]);

  function isErrPropCostInfo() {
    // section is always vis, but optional
    // validate each row
    let err = false;
    stateProposedCostsDetailsItems.forEach(o => {
      // vendor is required
      if (GenUtil.isNull(o.dd_Vendors) || GenUtil.isNull(o.cv_VendorsValue)) err = true;
      // type of cost is required sometimes
      else if (o.dd_Vendors === '-999' && GenUtil.isNull(o.dd_TypeOfCosts)) err = true;
      // cost is required
      else if (GenUtil.safeToNumberOrNull(o.rpt_decimal_Costs) == null) err = true;
    });
    return err;
  }


  const memoIsErrLSCaseManager = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrLSCaseManager();
  }, [stateFormSubmitted, stateSelLSCaseManagerUser]);

  function isErrLSCaseManager() {
    // required for submitting
    return GenUtil.isNull(stateSelLSCaseManagerUser);
  }


  const memoIsErrCaseManager = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrCaseManager();
  }, [stateFormSubmitted, stateSelCaseManagerUser]);

  function isErrCaseManager() {
    // required for submitting
    return GenUtil.isNull(stateSelCaseManagerUser);
  }


  //------ metrics related


  function validateALMField(a: string) {
    // return true when error
    if (memoShowALMSection) {
      if (GenUtil.isNull(a)) {
        return true; //error
      }
      else {
        if (!GenUtil.hasNumber(a)) return true; //error
      }
    }
    return false; //ok
  }


  const memoIsErrALMCollectionsUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMCollectionsUW();
  }, [stateFormSubmitted, memoShowALMSection, stateALMCollectionsUW]);

  function isErrALMCollectionsUW() {
    return validateALMField(stateALMCollectionsUW);
  }


  const memoIsErrALMCollectionsRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMCollectionsRevBP();
  }, [stateFormSubmitted, memoShowALMSection, stateALMCollectionsRevBP]);

  function isErrALMCollectionsRevBP() {
    return validateALMField(stateALMCollectionsRevBP);
  }


  const memoIsErrALMMultipleUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMMultipleUW();
  }, [stateFormSubmitted, memoShowALMSection, stateALMMultipleUW]);

  function isErrALMMultipleUW() {
    return validateALMField(stateALMMultipleUW);
  }


  const memoIsErrALMMultipleRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMMultipleRevBP();
  }, [stateFormSubmitted, memoShowALMSection, stateALMMultipleRevBP]);

  function isErrALMMultipleRevBP() {
    return validateALMField(stateALMMultipleRevBP);
  }


  const memoIsErrALMIRRUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMIRRUW();
  }, [stateFormSubmitted, memoShowALMSection, stateALMIRRUW]);

  function isErrALMIRRUW() {
    return validateALMField(stateALMIRRUW);
  }


  const memoIsErrALMIRRRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMIRRRevBP();
  }, [stateFormSubmitted, memoShowALMSection, stateALMIRRRevBP]);

  function isErrALMIRRRevBP() {
    return validateALMField(stateALMIRRRevBP);
  }


  const memoIsErrALMWALUW = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMWALUW();
  }, [stateFormSubmitted, memoShowALMSection, stateALMWALUW]);

  function isErrALMWALUW() {
    return validateALMField(stateALMWALUW);
  }


  const memoIsErrALMWALRevBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrALMWALRevBP();
  }, [stateFormSubmitted, memoShowALMSection, stateALMWALRevBP]);

  function isErrALMWALRevBP() {
    return validateALMField(stateALMWALRevBP);
  }


  //------ workflow sections


  const memoIsErrApp3Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp3Action();
  }, [stateFormSubmitted, memoWFSectionHideApp3, memoWFSectionDisableApp3, stateSelApp3Action]);

  function isErrApp3Action() {
    return !memoWFSectionHideApp3 && !memoWFSectionDisableApp3 && !stateSelApp3Action;
  }


  const memoIsErrApp3Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp3Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp3, memoWFSectionDisableApp3, stateApp3Comment, stateSelApp3Action]);

  function isErrApp3Comment() {
    // NOTE: App3/Servicer can never reject or send back for more comments, so likely this is useless
    return !memoWFSectionHideApp3 && !memoWFSectionDisableApp3 && GenUtil.isNull(stateApp3Comment) && GenUtil.inn(stateSelApp3Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp6Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp6Action();
  }, [stateFormSubmitted, memoWFSectionHideApp6, memoWFSectionDisableApp6, stateSelApp6Action]);

  function isErrApp6Action() {
    return !memoWFSectionHideApp6 && !memoWFSectionDisableApp6 && GenUtil.isNull(stateSelApp6Action);
  }


  const memoIsErrApp6BBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp6BBP();
  }, [stateFormSubmitted, memoWFSectionHideApp6, memoWFSectionDisableApp6, memoWorkflowPath, stateSelApp6BBP]);

  function isErrApp6BBP() {
    return !memoWFSectionHideApp6 && !memoWFSectionDisableApp6 && memoWorkflowPath === 4 && GenUtil.isNull(stateSelApp6BBP);
  }


  const memoIsErrApp6Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp6Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp6, memoWFSectionDisableApp6, stateApp6Comment, stateSelApp6Action]);

  function isErrApp6Comment() {
    return !memoWFSectionHideApp6 && !memoWFSectionDisableApp6 && GenUtil.isNull(stateApp6Comment) && GenUtil.inn(stateSelApp6Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp1Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp1Action();
  }, [stateFormSubmitted, memoWFSectionHideApp1, memoWFSectionDisableApp1, stateSelApp1Action]);

  function isErrApp1Action() {
    return !memoWFSectionHideApp1 && !memoWFSectionDisableApp1 && !stateSelApp1Action;
  }


  const memoIsErrApp1Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp1Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp1, memoWFSectionDisableApp1, stateApp1Comment, stateSelApp1Action]);

  function isErrApp1Comment() {
    return !memoWFSectionHideApp1 && !memoWFSectionDisableApp1 && GenUtil.isNull(stateApp1Comment) && GenUtil.inn(stateSelApp1Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp7Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp7Action();
  }, [stateFormSubmitted, memoWFSectionHideApp7, memoWFSectionDisableApp7, stateSelApp7Action]);

  function isErrApp7Action() {
    return !memoWFSectionHideApp7 && !memoWFSectionDisableApp7 && !stateSelApp7Action;
  }


  const memoIsErrApp7BBP = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp7BBP();
  }, [stateFormSubmitted, memoWFSectionHideApp7, memoWFSectionDisableApp7, memoWorkflowPath, stateSelApp7BBP]);

  function isErrApp7BBP() {
    return !memoWFSectionHideApp7 && !memoWFSectionDisableApp7 && memoWorkflowPath === 4 && GenUtil.isNull(stateSelApp7BBP);
  }


  const memoIsErrApp7Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp7Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp7, memoWFSectionDisableApp7, stateApp7Comment, stateSelApp7Action]);

  function isErrApp7Comment() {
    return !memoWFSectionHideApp7 && !memoWFSectionDisableApp7 && GenUtil.isNull(stateApp7Comment) && GenUtil.inn(stateSelApp7Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp2Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp2Action();
  }, [stateFormSubmitted, memoWFSectionHideApp2, memoWFSectionDisableApp2, stateSelApp2Action]);

  function isErrApp2Action() {
    return !memoWFSectionHideApp2 && !memoWFSectionDisableApp2 && !stateSelApp2Action;
  }


  const memoIsErrApp2Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp2Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp2, memoWFSectionDisableApp2, stateApp2Comment, stateSelApp2Action]);

  function isErrApp2Comment() {
    return !memoWFSectionHideApp2 && !memoWFSectionDisableApp2 && GenUtil.isNull(stateApp2Comment) && GenUtil.inn(stateSelApp2Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp5Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp5Action();
  }, [stateFormSubmitted, memoWFSectionHideApp5, memoWFSectionDisableApp5, stateSelApp5Action]);

  function isErrApp5Action() {
    return !memoWFSectionHideApp5 && !memoWFSectionDisableApp5 && !stateSelApp5Action;
  }


  const memoIsErrApp5Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp5Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp5, memoWFSectionDisableApp5, stateApp5Comment, stateSelApp5Action]);

  function isErrApp5Comment() {
    return !memoWFSectionHideApp5 && !memoWFSectionDisableApp5 && GenUtil.isNull(stateApp5Comment) && GenUtil.inn(stateSelApp5Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp4Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp4Action();
  }, [stateFormSubmitted, memoWFSectionHideApp4, memoWFSectionDisableApp4, stateSelApp4Action]);

  function isErrApp4Action() {
    return !memoWFSectionHideApp4 && !memoWFSectionDisableApp4 && !stateSelApp4Action;
  }


  const memoIsErrApp4Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp4Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp4, memoWFSectionDisableApp4, stateApp4Comment, stateSelApp4Action]);

  function isErrApp4Comment() {
    return !memoWFSectionHideApp4 && !memoWFSectionDisableApp4 && GenUtil.isNull(stateApp4Comment) && GenUtil.inn(stateSelApp4Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }


  const memoIsErrApp10Action = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp10Action();
  }, [stateFormSubmitted, memoWFSectionHideApp10, memoWFSectionDisableApp10, stateSelApp10Action]);

  function isErrApp10Action() {
    return !memoWFSectionHideApp10 && !memoWFSectionDisableApp10 && !stateSelApp10Action;
  }


  const memoIsErrApp10Comment = useMemo<boolean>(() => {
    if (!stateFormSubmitted) return false;
    return isErrApp10Comment();
  }, [stateFormSubmitted, memoWFSectionHideApp10, memoWFSectionDisableApp10, stateApp10Comment, stateSelApp10Action]);

  function isErrApp10Comment() {
    return !memoWFSectionHideApp10 && !memoWFSectionDisableApp10 && GenUtil.isNull(stateApp10Comment) && GenUtil.inn(stateSelApp10Action, StaticData.wfActionReject, StaticData.wfActionRequestAdditionalInfo);
  }

  //#endregion


  //#region 'RENDER'
  //-------------------------

  /*

########  ######## ##    ## ########  ######## ########
##     ## ##       ###   ## ##     ## ##       ##     ##
##     ## ##       ####  ## ##     ## ##       ##     ##
########  ######   ## ## ## ##     ## ######   ########
##   ##   ##       ##  #### ##     ## ##       ##   ##
##    ##  ##       ##   ### ##     ## ##       ##    ##
##     ## ######## ##    ## ########  ######## ##     ##

  */


  return (
    <>
      <Stack tokens={Consts.stackTokens}>

        <h1 className="flu-page-title2 flu-bottomborder1 wbss">Advisory Note<span className="flu-page-title-sub2">{StaticData.defaultServicerName}</span></h1>

        {Consts.isDevEnv() && (
          <MessageBar messageBarType={MessageBarType.warning} className='wbss'>
            {`DEVELOPER ENVIRONMENT`}
          </MessageBar>
        )}

        <MessageBar messageBarType={MessageBarType.warning} className='wbss'>
          {`Please only use the latest Chrome or Edge browsers or else unexpected results may occur.`}
        </MessageBar>

        {
          (statePageLoading || stateLoadingANItem) && (
            <div className='wrapper25'>
              <Spinner label="Loading data, please wait..." size={SpinnerSize.large} />
            </div>
          )
        }

        {
          (statePageLoading === false && stateLoadingANItem === false) && (
            <>




              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Advisory Note Details</h2>


                <div className="ms-Grid" dir="ltr">

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      <Label>Status:</Label>
                      <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.WFStatus, StaticData.wfStatusDraft)}</Label>
                    </div>
                    {
                      !GenUtil.isNull(memoANNote.AN_No) && (
                        <div className="ms-Grid-col ms-sm6">
                          <Label>AN:</Label>
                          <Label className='ms-fontWeight-regular'>{memoANNote.AN_No}</Label>
                        </div>
                      )
                    }
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      {
                        memoFormIsReadOnly ? (
                          <>
                            <Label>Project:</Label>
                            <Label className='ms-fontWeight-regular'>{stateSelProject}</Label>
                          </>
                        ) : (
                          <>
                            <ComboBox
                              disabled={stateSelUniqueConns.length > 0}
                              selectedKey={stateSelProject}
                              label="Project:"
                              placeholder='Select a Project'
                              options={stateAllANEntities.map((o, i) => {
                                return { key: o.fields.Title, text: o.fields.Title };
                              })}
                              // autoComplete={'on'}
                              onChange={onProjectNameChange}
                              errorMessage={memoIsErrProject ? 'Project is required.' : ''}
                            />
                          </>
                        )
                      }

                    </div>
                    <div className="ms-Grid-col ms-sm6">
                      <Label>Entity:</Label>
                      <Label className='ms-fontWeight-regular'>{stateSelProjectItem ? stateSelProjectItem.fields.Projects : 'Select a Project'}</Label>
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                      {
                        memoFormIsReadOnly ? (
                          <>
                            <Label>Type of AN:</Label>
                            <Label className='ms-fontWeight-regular'>{stateSelANType}</Label>
                          </>
                        ) : (
                          <>
                            <ComboBox
                              disabled={stateSelProjectItem == null || stateCurANConnIDs.length > 0}
                              selectedKey={stateSelANType}
                              label="Type of AN:"
                              placeholder='Select a Type'
                              options={stateANTypeOptions}
                              // autoComplete={'on'}
                              onChange={onANTypeChange}
                              errorMessage={memoIsErrANType ? 'AN Type is required.' : ''}
                            />
                          </>
                        )
                      }

                    </div>

                  </div>

                  {
                    // NOTE: connection group is deprecated, not used
                    !memoFormIsReadOnly && memoShowIsConnGroupId && (
                      <>
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm6">
                            <Dropdown
                              label='Is there a Connection Group ID?'
                              selectedKey={stateSelIsConnGroupId ? stateSelIsConnGroupId.key : undefined}
                              onChange={onChangeIsConnGroupId}
                              placeholder="Please select a value"
                              options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }

                  {
                    !memoFormIsReadOnly && memoShowConnectionPickerSection && (
                      <>
                        <Stack tokens={{ childrenGap: 2 }}>
                          <Label>{`${memoConnectionPickerLabel}:`}</Label>
                          <Stack tokens={Consts.stackTokens} horizontal>
                            <AjaxPicker
                              disabled={false}
                              itemLimit={1}
                              getSuggestedTags={getSuggestedANConnections}
                              onTagsChanged={onTagsChangedANConnections}
                              selectedTags={stateSelANConnectionPicker}
                              cssClassNames='w400'
                              noResultsFoundText=''
                              suggestionsHeaderText={`Search for ${memoConnectionPickerLabel.replace(/ID/ig, '').trim()}s using ${memoConnectionPickerLabel}`}
                              searchingText='Loading...'
                              placeholder={`Enter ${memoConnectionPickerLabel}`}
                            />
                            <PrimaryButton text={"Add ID"} allowDisabledFocus onClick={handleAddConnectionId} disabled={stateLoadingConnection} />
                            {memoShowDeleteAllTablesButton && <PrimaryButton text="Delete All Tables" allowDisabledFocus onClick={handleDeleteAllTables} disabled={stateLoadingConnection} />}
                            {stateLoadingConnection && <Spinner label='Loading...' size={SpinnerSize.small} labelPosition={'right'} />}
                          </Stack>
                        </Stack>

                        {
                          memoIsErrConnection && (
                            <>
                              <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                                {`${memoConnectionPickerLabel} is required.`}
                              </MessageBar>
                            </>
                          )
                        }

                      </>
                    )
                  }

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 wtss">
                      <Checkbox label="Mark as Urgent" checked={stateMarkAsUrgent} onChange={onChangeMarkAsUrgent} disabled={memoFormIsReadOnly} />
                    </div>
                  </div>

                </div>

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateSelProject: {stateSelProject}</li>
                      <li>stateSelProjectItem: {JSON.stringify(stateSelProjectItem, null, 2)}</li>
                      <li>memoSelProjectLegalTitleHolder: {memoSelProjectLegalTitleHolder}</li>
                      <li>memoSelProjectIsCountryDutch: {memoSelProjectIsCountryDutch + ''}</li>
                      <li>memoSelProjectIsLoanCo: {memoSelProjectIsLoanCo + ''}</li>
                      <hr></hr>
                      {/* <li>stateANTypeOptions: {JSON.stringify(stateANTypeOptions, null, 2)}</li> */}
                      <li>stateSelANType: {stateSelANType}</li>
                      <li>stateSelANTypeObject: {JSON.stringify(stateSelANTypeObject, null, 2)}</li>
                      <li>memoSelANTypeIsREO: {memoSelANTypeIsREO + ''}</li>
                      <li>memoSelANTypeIsPortfolioLevel: {memoSelANTypeIsPortfolioLevel + ''}</li>
                      <li>memoSelANTypeIsPaymentOfCosts: {memoSelANTypeIsPaymentOfCosts + ''}</li>
                      <hr></hr>
                      <li>stateSelIsConnGroupId: {JSON.stringify(stateSelIsConnGroupId, null, 2)}</li>
                      <hr></hr>
                      <li>stateSelANConnectionPicker: {JSON.stringify(stateSelANConnectionPicker, null, 2)}</li>
                      <li>memoConnPickerId: {memoConnPickerId}</li>
                      <hr></hr>
                      <li>stateANNote.fields.ConnectionDetails: {stateANNote && stateANNote.fields.ConnectionDetails}</li>
                      <li>stateANSavedConnDetails: {stateANSavedConnDetails.length}, {JSON.stringify(stateANSavedConnDetails, null, 2)}</li>
                      <hr></hr>
                      <li>stateCurANConnIDs: {stateCurANConnIDs.length}, {JSON.stringify(stateCurANConnIDs, null, 2)}</li>
                      <li>stateSelUniqueConns: {stateSelUniqueConns.length}, {JSON.stringify(stateSelUniqueConns, null, 2)}</li>
                      <li>stateSelAllConns: {stateSelAllConns.length}</li>
                      {/* <li>stateSelAllConns: {stateSelAllConns.length}, {JSON.stringify(stateSelAllConns, null, 2)}</li> */}
                      <hr></hr>
                    </ul>
                  )
                }

              </div>





              {
                memoShowSectionConnectionDetails && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Connection Details</h2>

                      {
                        memoFormIsReadOnly && stateANNote && (
                          <ANConnectionDetailRO
                            data={stateANNote.fields.ConnectionDetails} />
                        )
                      }

                      {
                        !memoFormIsReadOnly && stateSelUniqueConns.length > 0 && (
                          <ANConnectionDetail
                            connections={stateSelUniqueConns}
                            onChangeConn={onChangeConnectionDetail}
                            onDeleteConn={onDeleteConnectionDetail}
                            stateFormSubmitted={stateFormSubmitted} />
                        )
                      }

                      {
                        memoIsErrConnection && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`Please add a Connection.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateSelUniqueConns: {stateSelUniqueConns.length}, {JSON.stringify(stateSelUniqueConns, null, 2)}</li>
                          </ul>
                        )
                      }

                    </div>
                  </>
                )
              }






              {
                memoShowSectionBorrowerInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Borrower Information</h2>

                      <BorrowerInfoBody
                        options={stateBorrowerOptions}
                        data={stateBorrowerInfo}
                        connections={stateBorrowerObjects}
                        onDataUpdated={onUpdateSectionData}
                        isReadOnly={memoFormIsReadOnly}
                        removeConnId={stateBorrowerConnId2Del} />

                      {
                        memoIsErrBorrowerInfo && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Borrowers are required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateBorrowerInfoItems: {stateBorrowerInfoItems.length}, {JSON.stringify(stateBorrowerInfoItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              }






              {
                memoANTypeShowLoanInfoSection && memoShowSectionLoanInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Loan Information</h2>

                      <LoanInfoBody
                        options={stateLoanOptions}
                        data={stateLoanInfo}
                        connections={stateLoanObjects}
                        onDataUpdated={onUpdateSectionData}
                        isReadOnly={memoFormIsReadOnly}
                        currencys={stateAllANCurrencys}
                        removeConnId={stateLoanConnId2Del} />

                      {
                        memoIsErrLoanInfo && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Loans are required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateLoanInfoItems: {stateLoanInfoItems.length}, {JSON.stringify(stateLoanInfoItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              }






              {
                memoShowSectionAssetInfo && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Asset Information</h2>

                      <AssetInfoBody
                        options={stateAssetOptions} // down
                        data={stateAssetInfo} // down(xml str)
                        connections={stateAssetObjects} // down
                        showAssetLocation={memoANTypeShowAssetLocation} // bool
                        showCommercialActivity={memoANTypeShowCommercialActivity} // bool
                        showPublicationDate={memoANTypeShowPublicationDate} // bool
                        onDataUpdated={onUpdateSectionData} // up
                        isReadOnly={memoFormIsReadOnly} // bool
                        removeConnId={stateAssetConnId2Del}
                      />

                      {
                        memoIsErrAssetInfo && (
                          <>
                            <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                              {`One or more Assets are required.`}
                            </MessageBar>
                          </>
                        )
                      }

                      {
                        Consts.admOvrShowDebugInfo() && (
                          <ul className='debug-ul'>
                            <li>stateAssetInfoItems: {stateAssetInfoItems.length}, {JSON.stringify(stateAssetInfoItems, null, 2)}</li>
                            <li>memoAssetConnDetailItems: {JSON.stringify(memoAssetConnDetailItems, null, 2)}</li>
                          </ul>
                        )
                      }
                    </div>
                  </>
                )
              }







              {
                memoShowAssetConnDetails && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">{`Connection & Borrower Details`}</h2>

                      <ANAssetConnDetailRO
                        data={memoAssetConnDetailItems}
                      />

                    </div>
                  </>
                )
              }





              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Proposed Costs</h2>

                <ProposedCostsBody
                  vendors={stateAllANVendors}
                  typeOfCosts={stateAllTypeOfCosts}
                  data={stateProposedCostsDetails}
                  onDataUpdated={onUpdateSectionData}
                  stateFormSubmitted={stateFormSubmitted}
                  isReadOnly={memoFormIsReadOnlyCES1} />

                {
                  memoIsErrPropCostInfo && (
                    <>
                      <MessageBar messageBarType={MessageBarType.error} className='wts12'>
                        {`One or more Proposed Costs rows are invalid.`}
                      </MessageBar>
                    </>
                  )
                }

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateProposedCostsDetailsItems: {JSON.stringify(stateProposedCostsDetailsItems, null, 2)}</li>
                    </ul>
                  )
                }
              </div>






              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Supporting Details</h2>

                {
                  memoFormIsReadOnlyCES1 ? (
                    <>
                      {AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${statePropertyDetailsBgInfo || ''}</div>`)}
                    </>
                  ) : (
                    <>
                      <RichTextArea content={statePropertyDetailsBgInfo} onUpdate={() => { }} onUpdate2={onChangePropertyDetailsBgInfo}></RichTextArea>
                    </>
                  )
                }

              </div>







              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Proposal</h2>

                {
                  memoFormIsReadOnlyCES1 ? (
                    <>
                      {AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateProposalInfo || ''}</div>`)}
                    </>
                  ) : (
                    <>
                      <RichTextArea content={stateProposalInfo} onUpdate={() => { }} onUpdate2={onChangeProposalInfo}></RichTextArea>
                    </>
                  )
                }

              </div>








              {
                memoANTypeShowSupportingTables && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Supporting Tables</h2>

                      {
                        memoFormIsReadOnlyCES1 ? (
                          <>
                            {AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateSupportingTables || ''}</div>`)}
                          </>
                        ) : (
                          <>
                            <RichTextArea content={stateSupportingTables} onUpdate={() => { }} onUpdate2={onChangeSupportingTables}></RichTextArea>
                          </>
                        )
                      }

                    </div>
                  </>
                )
              }









              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Recommendation</h2>

                {
                  memoFormIsReadOnlyCES1 ? (
                    <>
                      {AppHelper.renderDSIH(`<div class='div-richtext-wrapper'>${stateRecComments || ''}</div>`)}
                    </>
                  ) : (
                    <>
                      <RichTextArea content={stateRecComments} onUpdate={() => { }} onUpdate2={onChangeRecComments}></RichTextArea>
                    </>
                  )
                }

              </div>





              {
                memoShowALMSection && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Metrics</h2>

                      <table className={`sub-table3 wbss ${!memoALMIsEditable ? 'w600' : ''}`}>
                        <thead>

                          <tr>
                            <th>
                            </th>
                            <th>
                              {`UW`}
                            </th>
                            <th>
                            </th>
                            <th>
                              {`Rev-BP`}
                            </th>
                            <th>
                            </th>
                            <th>
                              {`Delta`}
                            </th>
                            <th>
                            </th>
                          </tr>

                        </thead>
                        <tbody>

                          <tr>
                            <td>
                              {`Collections`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMCollectionsUW))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMCollectionsUW} value={stateALMCollectionsUW} className='' errorMessage={memoIsErrALMCollectionsUW ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`EUR (€)`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMCollectionsRevBP))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMCollectionsRevBP} value={stateALMCollectionsRevBP} className='' errorMessage={memoIsErrALMCollectionsRevBP ? 'Missing or invalid currency.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`EUR (€)`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {memoDeltaALMColl}
                                  </>
                                ) : (
                                  <>
                                    <TextField value={memoDeltaALMColl} readOnly onChange={() => { }} className='bgGrey' />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`EUR (€)`}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {`Multiple`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMMultipleUW))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMMultipleUW} value={stateALMMultipleUW} className='' errorMessage={memoIsErrALMMultipleUW ? 'Missing or invalid number.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`X`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMMultipleRevBP))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMMultipleRevBP} value={stateALMMultipleRevBP} className='' errorMessage={memoIsErrALMMultipleRevBP ? 'Missing or invalid number.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`X`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {memoDeltaALMMult}
                                  </>
                                ) : (
                                  <>
                                    <TextField value={memoDeltaALMMult} readOnly onChange={() => { }} className='bgGrey' />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`X`}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {`IRR`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMIRRUW))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMIRRUW} value={stateALMIRRUW} className='' errorMessage={memoIsErrALMIRRUW ? 'Missing or invalid number.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`%`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMIRRRevBP))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMIRRRevBP} value={stateALMIRRRevBP} className='' errorMessage={memoIsErrALMIRRRevBP ? 'Missing or invalid number.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`%`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {memoDeltaALMIRR}
                                  </>
                                ) : (
                                  <>
                                    <TextField value={memoDeltaALMIRR} readOnly onChange={() => { }} className='bgGrey' />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`%`}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {`WAL`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMWALUW))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMWALUW} value={stateALMWALUW} className='' errorMessage={memoIsErrALMWALUW ? 'Missing or invalid number.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`Yrs`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {GenUtil.numberToCurrency(GenUtil.safeToNumber(stateALMWALRevBP))}
                                  </>
                                ) : (
                                  <>
                                    <TextField onChange={onChangeALMWALRevBP} value={stateALMWALRevBP} className='' errorMessage={memoIsErrALMWALRevBP ? 'Missing or invalid number.' : ''} maxLength={255} />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`Yrs`}
                            </td>
                            <td>
                              {
                                !memoALMIsEditable ? (
                                  <>
                                    {memoDeltaALMWAL}
                                  </>
                                ) : (
                                  <>
                                    <TextField value={memoDeltaALMWAL} readOnly onChange={() => { }} className='bgGrey' />
                                  </>
                                )
                              }
                            </td>
                            <td>
                              {`Yrs`}
                            </td>
                          </tr>

                        </tbody>
                      </table>

                    </div>
                  </>
                )
              }





              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Loan Servicer Case Manager</h2>

                <ComboBox
                  // className='w400'
                  style={{ maxWidth: 400 }}
                  selectedKey={stateSelLSCaseManagerUser}
                  placeholder='Please select a user'
                  options={memoLSCaseManagerOptions}
                  // autoComplete={'on'}
                  onChange={onLSCaseManagerUserChange}
                  errorMessage={memoIsErrLSCaseManager ? 'Loan Servicer Case Manager is required.' : ''}
                  disabled={memoFormIsReadOnly}
                />

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateSelLSCaseManagerUser: {stateSelLSCaseManagerUser}</li>
                    </ul>
                  )
                }

              </div>



              <div className='flu-section'>

                <h2 className="flu-heading1 wbsss">Case Manager</h2>

                <ComboBox
                  // className='w400'
                  style={{ maxWidth: 400 }}
                  selectedKey={stateSelCaseManagerUser}
                  placeholder='Please select a user'
                  options={memoCaseManagerOptions}
                  // autoComplete={'on'}
                  onChange={onCaseManagerUserChange}
                  errorMessage={memoIsErrCaseManager ? 'Case Manager is required.' : ''}
                  disabled={memoCaseManagerIsReadOnly}
                />

                {
                  Consts.admOvrShowDebugInfo() && (
                    <ul className='debug-ul'>
                      <li>stateSelCaseManagerUser: {stateSelCaseManagerUser}</li>
                    </ul>
                  )
                }

              </div>





              {
                stateANNote && (
                  <>


                    {/*

##      ##  #######  ########  ##    ## ######## ##        #######  ##      ##
##  ##  ## ##     ## ##     ## ##   ##  ##       ##       ##     ## ##  ##  ##
##  ##  ## ##     ## ##     ## ##  ##   ##       ##       ##     ## ##  ##  ##
##  ##  ## ##     ## ########  #####    ######   ##       ##     ## ##  ##  ##
##  ##  ## ##     ## ##   ##   ##  ##   ##       ##       ##     ## ##  ##  ##
##  ##  ## ##     ## ##    ##  ##   ##  ##       ##       ##     ## ##  ##  ##
###  ###   #######  ##     ## ##    ## ##       ########  #######   ###  ###

                    */}






                    {
                      !memoWFSectionHideApp3 && (
                        <>
                          {
                            memoWFSectionDisableApp3 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Servicer ${Consts.admOvrShowDebugInfo() ? '(App3)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App3Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App3Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App3Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {false && memoShowBBP && ( // never show BBP in APP3
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App3Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusServicer) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Servicer ${Consts.admOvrShowDebugInfo() ? '(App3)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp3Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp3ActionChoices}
                                          onChange={onChangeApp3Action}
                                          errorMessage={memoIsErrApp3Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {false && memoShowBBP && ( // never show BBP in APP3
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp3Comment} value={stateApp3Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp3Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }










                    {
                      !memoWFSectionHideApp6 && (
                        <>
                          {
                            memoWFSectionDisableApp6 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Case Manager ${Consts.admOvrShowDebugInfo() ? '(App6/CES1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App6Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App6Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App6Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App6BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App6Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusCES1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Case Manager ${Consts.admOvrShowDebugInfo() ? '(App6/CES1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp6Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp6ActionChoices}
                                          onChange={onChangeApp6Action}
                                          errorMessage={memoIsErrApp6Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <ComboBox
                                              className='m150'
                                              disabled={false}
                                              selectedKey={stateSelApp6BBP}
                                              label="Below Business Plan?"
                                              placeholder=''
                                              options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                                              onChange={onChangeApp6BBP}
                                              errorMessage={memoIsErrApp6BBP ? 'BBP is required.' : ''}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm7">
                                        <TextField label='Comments:' onChange={onChangeApp6Comment} value={stateApp6Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp6Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }










                    {
                      !memoWFSectionHideApp1 && (
                        <>
                          {
                            memoWFSectionDisableApp1 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Recommender ${Consts.admOvrShowDebugInfo() ? '(App1/CES2)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App1Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App1Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App1Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App1Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusCES2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CES - Recommender ${Consts.admOvrShowDebugInfo() ? '(App1/CES2)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp1Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp1ActionChoices}
                                          onChange={onChangeApp1Action}
                                          errorMessage={memoIsErrApp1Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp1Comment} value={stateApp1Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp1Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }








                    {
                      !memoWFSectionHideApp7 && (
                        <>
                          {
                            memoWFSectionDisableApp7 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 1 - ${memoSelProjectAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App7/CGI1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App7Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App7Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App7Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{memoANNote.App7BBP}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App7Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusCGI1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 1 - ${memoSelProjectAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App7/CGI1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp7Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp7ActionChoices}
                                          onChange={onChangeApp7Action}
                                          errorMessage={memoIsErrApp7Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <ComboBox
                                              className='m150'
                                              disabled={false}
                                              selectedKey={stateSelApp7BBP}
                                              label="Below Business Plan?"
                                              placeholder=''
                                              options={StaticData.luYesNo.split(',').map(o => { return { key: o, text: o }; })}
                                              onChange={onChangeApp7BBP}
                                              errorMessage={memoIsErrApp7BBP ? 'BBP is required.' : ''}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp7Comment} value={stateApp7Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp7Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }








                    {
                      !memoWFSectionHideApp2 && (
                        <>
                          {
                            memoWFSectionDisableApp2 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 2 - ${memoSelProjectAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App2/CGI2)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App2Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App2Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App2Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App2Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    !memoHasSameCGIs && Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusCGI2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                  {
                                    memoHasSameCGIs && Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusCGI2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`CGI 2 Approver must be different than CGI 1 Approver.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`CGI - Signature 2 - ${memoSelProjectAssetManager} ${Consts.admOvrShowDebugInfo() ? '(App2/CGI2)' : ''}`}</h2>

                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp2Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp2ActionChoices}
                                          onChange={onChangeApp2Action}
                                          errorMessage={memoIsErrApp2Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp2Comment} value={stateApp2Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp2Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }









                    {
                      !memoWFSectionHideApp5 && (
                        <>
                          {
                            memoWFSectionDisableApp5 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`${memoSelProjectLegalTitleHolder} ${Consts.admOvrShowDebugInfo() ? '(App5/LTH/EO)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App5Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App5Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App5Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App5Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusLTH) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`${memoSelProjectLegalTitleHolder} ${Consts.admOvrShowDebugInfo() ? '(App5/LTH/EO)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp5Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp5ActionChoices}
                                          onChange={onChangeApp5Action}
                                          errorMessage={memoIsErrApp5Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {memoShowBBP && (
                                          <>
                                            <Label>Below Business Plan?</Label>
                                            <Label className='ms-fontWeight-regular'>{GenUtil.NVL(memoANNote.App7BBP, memoANNote.App6BBP, '')}</Label>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp5Comment} value={stateApp5Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp5Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }




                    {
                      !memoWFSectionHideApp4 && (
                        <>
                          {
                            memoWFSectionDisableApp4 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Owner - Signature 1 - ${memoSelProjectLegalTitleHolder} ${Consts.admOvrShowDebugInfo() ? '(App4/ReoCo1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App4Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App4Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App4Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* not relevant */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App4Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusREO1) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Owner - Signature 1 - ${memoSelProjectLegalTitleHolder} ${Consts.admOvrShowDebugInfo() ? '(App4/ReoCo1)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp4Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp4ActionChoices}
                                          onChange={onChangeApp4Action}
                                          errorMessage={memoIsErrApp4Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* not relevant */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp4Comment} value={stateApp4Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp4Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }





                    {
                      !memoWFSectionHideApp10 && (
                        <>
                          {
                            memoWFSectionDisableApp10 ? (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Owner - Signature 2 - ${memoSelProjectLegalTitleHolder} ${Consts.admOvrShowDebugInfo() ? '(App10/ReoCo2)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App10Name}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm4">
                                        <Label>Action:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoANNote.App10Action}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(memoANNote.App10Date)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* not relevant */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm12">
                                        <Label>Comments:</Label>
                                        <Label className='ms-fontWeight-regular'>{AppHelper.renderDSIH(GenUtil.plainTextToHtml(memoANNote.App10Comments))}</Label>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    Consts.showAccessDeniedWFSection && GenUtil.inn(memoFormStatus, StaticData.wfStatusREO2) && (
                                      <MessageBar messageBarType={MessageBarType.severeWarning} className='wts12'>
                                        {`Access Denied: The current user does not have permissions to submit the Advisory Note at this step.`}
                                      </MessageBar>
                                    )
                                  }

                                </div>
                              </>
                            ) : (
                              <>
                                <div className='flu-section'>

                                  <h2 className="flu-heading1 wbsss">{`Owner - Signature 2 - ${memoSelProjectLegalTitleHolder} ${Consts.admOvrShowDebugInfo() ? '(App10/ReoCo2)' : ''}`}</h2>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm3">
                                        <Label>Name:</Label>
                                        <Label className='ms-fontWeight-regular'>{memoCurUserDispName}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm5">
                                        <ComboBox
                                          // className='w300'
                                          style={{ maxWidth: 300 }}
                                          disabled={false}
                                          selectedKey={stateSelApp10Action}
                                          label="Action:"
                                          placeholder='Please select an action'
                                          options={memoApp10ActionChoices}
                                          onChange={onChangeApp10Action}
                                          errorMessage={memoIsErrApp10Action ? 'Action is required.' : ''}
                                        />
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        <Label>Date:</Label>
                                        <Label className='ms-fontWeight-regular'>{GenUtil.getCalDate(today)}</Label>
                                      </div>
                                      <div className="ms-Grid-col ms-sm2">
                                        {/* not relevant */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                      <div className="ms-Grid-col ms-sm8">
                                        <TextField label='Comments:' onChange={onChangeApp10Comment} value={stateApp10Comment} placeholder='Enter comments here' className='' multiline rows={2} errorMessage={memoIsErrApp10Comment ? 'Comment is required.' : ''} maxLength={Consts.maxLengthComments} />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }






                    {
                      Consts.admOvrShowDebugInfo() && (
                        <ul className='debug-ul'>
                          <li>memoWFSectionIsAnyShownEnabled: {memoWFSectionIsAnyShownEnabled + ''}</li>
                          <li>memoPermsIsCurUserAnyRole: {memoPermsIsCurUserAnyRole + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp3SRVUsers: {memoPermsApp3SRVUsers.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp3SRV: {memoPermsIsCurUserApp3SRV + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp6CES1Users: {memoPermsApp6CES1Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp6CES1: {memoPermsIsCurUserApp6CES1 + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp1CES2Users: {memoPermsApp1CES2Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp1CES2: {memoPermsIsCurUserApp1CES2 + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp7CGI1Users(/project): {memoPermsApp7CGI1Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp7CGI1: {memoPermsIsCurUserApp7CGI1 + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp2CGI2Users(/project): {memoPermsApp2CGI2Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp2CGI2: {memoPermsIsCurUserApp2CGI2 + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp5LTHUsers(/project): {memoPermsApp5LTHUsers.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp5LTH: {memoPermsIsCurUserApp5LTH + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp4REO1Users(/project): {memoPermsApp4REO1Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp4REO1: {memoPermsIsCurUserApp4REO1 + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp10REO2Users(/project): {memoPermsApp10REO2Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp10REO2: {memoPermsIsCurUserApp10REO2 + ''}</li>
                          <hr></hr>
                          <li>memoPermsApp10CCREO2Users(/project): {memoPermsApp10CCREO2Users.replace(/;/ig, '; ')}</li>
                          <li>memoPermsIsCurUserApp10CCREO2: {memoPermsIsCurUserApp10CCREO2 + ''}</li>
                        </ul>
                      )
                    }


                  </>
                )
              }



              {
                !Consts.admOvrEditMode() && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Submit Form</h2>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        {memoShowSaveButton && <PrimaryButton text="Save" allowDisabledFocus onClick={handleSave} disabled={stateSaving} />}
                        {memoShowSaveButtonCES1 && <PrimaryButton text="Save" allowDisabledFocus onClick={handleSaveCES1} disabled={stateSaving} />}
                        <PrimaryButton text="Submit Form" allowDisabledFocus onClick={handleSubmit} disabled={stateSaving || !memoEnableSubmitButton} />
                        <DefaultButton text="Cancel" allowDisabledFocus onClick={handleCancel} disabled={stateSaving || !memoEnableCancelButton} />
                      </Stack>

                    </div>
                  </>
                )
              }

              {
                memoAdmOvrAvail && Consts.admOvrEditMode() && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Admin Submit Form</h2>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        <PrimaryButton text="Draft Override Save" allowDisabledFocus onClick={() => { adminSaveForm(3); }} disabled={stateSaving} />
                      </Stack>

                    </div>
                  </>
                )

              }







              {
                stateANNote && Consts.admOvrShowAdminSection() && (
                  <>
                    <div className='flu-section'>

                      <h2 className="flu-heading1 wbsss">Admin Override Actions</h2>

                      <Stack tokens={Consts.stackTokens} horizontal horizontalAlign='center'>
                        <PrimaryButton text="Reset To Draft" allowDisabledFocus onClick={() => { adminSaveForm(1); }} disabled={stateSaving} />
                      </Stack>

                    </div>
                  </>
                )
              }








              {/* end main form after loading here */}
            </>
          )
        }





        {
          Consts.admOvrShowDebugInfo() && (
            <>
              <div className='debug-wrapper' style={{ margin: '50px 0' }}>

                <h2 className="flu-heading1 wbsss">Debug Info</h2>

                <Stack tokens={Consts.stackTokens}>

                  <TextField label="stateANItemId" readOnly value={stateANItemId + ''} />
                  <TextField label="memoIsNewItem" readOnly value={memoIsNewItem + ''} />
                  <TextField label="stateFormSubmitted" readOnly value={stateFormSubmitted + ''} />

                  <TextField label="memoCurUserDispName" readOnly value={memoCurUserDispName} />
                  <TextField label="memoCurUsername" readOnly value={memoCurUsername} />

                  <TextField label="ListTitleANNotesAltamira" readOnly value={Config.Settings().ListTitleANNotesAltamira} />
                  <TextField label="ListTitleConnectionsAltamira" readOnly value={Config.Settings().ListTitleConnectionsAltamira} />

                  <TextField label="stateANNote" readOnly value={JSON.stringify(stateANNote ? stateANNote : null, null, 2)} multiline rows={6} />
                  <TextField label="memoANNote" readOnly value={JSON.stringify(memoANNote, null, 2)} multiline rows={6} />
                  <TextField label="memoFormIsReadOnly" readOnly value={memoFormIsReadOnly + ''} />
                  <TextField label="memoFormIsReadOnlyCES1" readOnly value={memoFormIsReadOnlyCES1 + ''} />

                  <TextField label="FormStatus (Actual)" readOnly value={memoANNote.FormStatus || ''} />
                  <TextField label="FormStatus" readOnly value={memoFormStatus || ''} />
                  <TextField label="WFStatus" readOnly value={memoANNote.WFStatus || ''} />
                  <TextField label="WorkflowPath" readOnly value={memoANNote.WorkflowPath || ''} />
                  <TextField label="memoShowBBP" readOnly value={memoShowBBP + ''} />

                </Stack>

              </div>
            </>
          )
        }






        {
          (memoAdmOvrAvail && (Consts.admOvrUsername() || Consts.admOvrShowDebugInfo() ||
            Consts.admOvrShowAdminSection() || Consts.admOvrEditMode() ||
            Consts.admOvrAddCurUserToAllRoles() || Consts.admOvrShowAllSectionsFields())) && (
            <>
              <div className='debug-wrapper' style={{ margin: '50px 0' }}>

                <h2 className="flu-heading1 wbsss">Admin Overrides Enabled <Label>Samples are shown, not real values</Label></h2>

                <Stack className='wbs'>
                  <Toggle inlineLabel={true} label="admOvrShowDebugInfo=1" checked={!!Consts.admOvrShowDebugInfo()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrShowAdminSection=1" checked={!!Consts.admOvrShowAdminSection()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrAddCurUserToAllRoles=1" checked={!!Consts.admOvrAddCurUserToAllRoles()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrShowAllSectionsFields=1" checked={!!Consts.admOvrShowAllSectionsFields()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrEditMode=1" checked={!!Consts.admOvrEditMode()} onChange={() => { }} />
                  <Toggle inlineLabel={true} label="admOvrUsername=user@domain.onmicrosoft.com" checked={!!Consts.admOvrUsername()} onChange={() => { }} />
                </Stack>

                <div className='wbs'>
                  <TextField label="Admin Override Path:" readOnly value={
                    (function () {
                      let path = '?';
                      if (Consts.admOvrShowDebugInfo())
                        path += '&admOvrShowDebugInfo=1';
                      if (Consts.admOvrShowAdminSection())
                        path += '&admOvrShowAdminSection=1';
                      if (Consts.admOvrAddCurUserToAllRoles())
                        path += '&admOvrAddCurUserToAllRoles=1';
                      if (Consts.admOvrShowAllSectionsFields())
                        path += '&admOvrShowAllSectionsFields=1';
                      if (Consts.admOvrEditMode())
                        path += '&admOvrEditMode=1';
                      if (Consts.admOvrUsername())
                        path += `&admOvrUsername=${Consts.admOvrUsername()}`;
                      return path.length <= 1 ? '' : path.replace(/\?&/ig, '?');
                    })()
                  } />
                </div>

              </div>
            </>
          )
        }

      </Stack>




      <Dialog
        hidden={!stateShowDialog}
        // onDismiss={toggleHideDialog} // do not allow dismiss, user must make a choice
        dialogContentProps={{
          type: DialogType.largeHeader
        }}
        maxWidth={400}
        minWidth={400}
        modalProps={{
          isBlocking: true
        }}
      >
        <Stack tokens={Consts.stackTokens} horizontalAlign='center'>

          <div className='wbs25'>
            <img src={BigConsts.imgSuccess} style={{ width: '72px' }} alt='Success'></img>
          </div>

          <div className='wbs25 ms-fontSize-28'>
            {stateDialogMsg}
          </div>

          <Stack tokens={Consts.stackTokens} horizontal className=''>
            <PrimaryButton onClick={handleBackToList} text="Back to List" />
            {Consts.admOvrShowAdminSection() && (<PrimaryButton onClick={() => { (window as any).open(GenUtil.getLocationInfo('href'), "_top"); }} text="Reload" />)}
          </Stack>

        </Stack>
      </Dialog>



    </>
  );

  //#endregion

};
