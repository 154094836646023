/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Label,
  PrimaryButton,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";

import * as Consts from "../../../Helpers/Consts";
import * as GenUtil from "../../../Helpers/GenUtil2";
import * as AppHelper from "../../../Helpers/AppHelper";
import * as StaticData from "../../../StaticData/Altamira/StaticData";

import { AssetInfoRow } from "./AssetInfoRow";
import {
  Item,
  AssetInfo1,
  AssetInfoItem,
} from "../../../Models/Altamira/GridModels/AssetInfo1";
import { AssetInfoN } from "../../../Models/Altamira/GridModels/AssetInfoN";
import { ANConnection } from "../../../Models/Altamira/ANConnection";

export interface IAssetInfoBodyProps {
  data: string | undefined;
  options: IDropdownOption[];
  connections: ANConnection[];
  showAssetLocation: boolean;
  showCommercialActivity: boolean;
  showPublicationDate: boolean;
  onDataUpdated: (s: string, d: any) => void;
  isReadOnly: boolean;
  removeConnId: string;
}

export const AssetInfoBody: React.FunctionComponent<IAssetInfoBodyProps> = (
  props: React.PropsWithChildren<IAssetInfoBodyProps>
) => {
  useEffect(() => {
    Consts.showMounted && console.log("[MOUNTED: AssetInfoBody]");
  }, []);

  const [stateRows, setStateRows] = useState<AssetInfoItem[]>([]);

  const [stateSelItem, setStateSelItem] = useState<IDropdownOption>();
  const onChange = (event: any, option?: IDropdownOption, index?: number) => {
    setStateSelItem(option);
  };

  const [stateOptions, setStateOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    props.onDataUpdated("assets", stateRows);
  }, [stateRows]);

  useEffect(() => {
    // use local state for options, since we have to remove options that are already added to the list
    // remove from options any item that is added to the Grid
    let ids = stateRows.map((o) => o.rpt_AssetID);
    let col = [...props.options].filter((o) => ids.indexOf(o.key + "") < 0);
    setStateOptions(col);
  }, [props.options, stateRows]);

  useEffect(() => {
    // convert the xml data saved in the field to JSON
    // then convert each custom JSON object to a plain object

    const col: AssetInfoItem[] = [];

    if (
      !!props.data &&
      !GenUtil.isNull(props.data) &&
      !GenUtil.isInt(props.data)
    ) {
      try {
        if (!AppHelper.xmlHasMultipleItems(props.data)) {
          const obj = AppHelper.getJsonObjFromXmlStr(
            "AssetInfo",
            props.data
          ) as AssetInfo1;
          const _t = obj.RepeaterData.Items.Item;
          const item: AssetInfoItem = mapItemToAssetInfoItem(_t);

          // only add if the object is not nintex empty xml record
          if (GenUtil.safeTrim(item.rpt_AssetID).length > 0) {
            col.push(item);
          }
        } else {
          const obj = AppHelper.getJsonObjFromXmlStr(
            "AssetInfo",
            props.data
          ) as AssetInfoN;
          const _t = obj;
          const _col: AssetInfoItem[] = _t.RepeaterData.Items.Item.map((_t) => mapItemToAssetInfoItem(_t));

          // only add if the object is not nintex empty xml record
          for (const item of _col.filter(
            (o) => !GenUtil.isNull(o.rpt_AssetID)
          )) {
            col.push(item);
          }
        }
      } catch (error) {
        console.warn("Error parsing Xml in AssetInfoBody", props.data, error);
      }
    }

    setStateRows(col);
  }, [props.data]);

  function onClickAddID() {
    // add specific item from DDL to grid

    if (!stateSelItem) {
      return;
    }

    const selKey = GenUtil.safeTrim(stateSelItem.key);

    if (GenUtil.isNull(selKey)) {
      return;
    }

    // prevent same item adding more than once
    if (stateRows.filter((x) => GenUtil.eq(x.rpt_AssetID, selKey)).length > 0) {
      return;
    }

    const col = props.connections
      .filter((o) => GenUtil.eq(o.fields.Asset_x0020_ID, selKey))
      .map((o) => mapConnectionDetailsToAssetInfoItem(o));

    if (col.length <= 0) {
      return;
    }

    const obj = col[0];

    setStateRows((p) => {
      return [...p, obj];
    });
  }

  function onClickAddAll() {
    // add all items from DDL to grid

    const col = [...stateRows];

    const col2 = props.connections
      .filter((a) => {
        return (
          col.findIndex((b) => b.rpt_AssetID === a.fields.Asset_x0020_ID) < 0 &&
          !GenUtil.isNull(a.fields.Asset_x0020_ID)
        );
      })
      .map((o) => mapConnectionDetailsToAssetInfoItem(o));

    for (const item of col2) {
      col.push(item);
    }

    setStateRows(col);
  }

  function mapItemToAssetInfoItem(i: Item): AssetInfoItem {
    return {
      cv_ConnId: GenUtil.safeTrim(AppHelper.getText(i.cv_ConnId)),

      rpt_CESAssetID: GenUtil.safeTrim(AppHelper.getText(i.rpt_CESAssetID)),

      rpt_AssetID: GenUtil.safeTrim(AppHelper.getText(i.rpt_AssetID)),
      cv_AssetID: GenUtil.safeTrim(AppHelper.getText(i.cv_AssetID)),

      rpt_ProjectName: GenUtil.safeTrim(AppHelper.getText(i.rpt_ProjectName)),
      cv_ProjectName: GenUtil.safeTrim(AppHelper.getText(i.cv_ProjectName)),

      rpt_decimal_RealEstateValue: GenUtil.safeToNumber(AppHelper.getText(i.rpt_decimal_RealEstateValue)),

      cv_REValue: GenUtil.safeToNumber(AppHelper.getText(i.cv_REValue)),

      rpt_District: GenUtil.safeTrim(AppHelper.getText(i.rpt_District)),
      cv_District: GenUtil.safeTrim(AppHelper.getText(i.cv_District)),

      rpt_AssetAddress: GenUtil.safeTrim(AppHelper.getText(i.rpt_AssetAddress)),
      cv_AssetAddr: GenUtil.safeTrim(AppHelper.getText(i.cv_AssetAddr)),

      rpt_AssetType: GenUtil.safeTrim(AppHelper.getText(i.rpt_AssetType)),
      cv_AssetType: GenUtil.safeTrim(AppHelper.getText(i.cv_AssetType)),

      rpt_SalesAgreedPrice: GenUtil.safeToNumber(AppHelper.getText(i.rpt_SalesAgreedPrice)),
      cv_SalesAgreedPrice: GenUtil.safeToNumber(AppHelper.getText(i.cv_SalesAgreedPrice)),

      rpt_MarketingAskingPrice: GenUtil.safeToNumber(AppHelper.getText(i.rpt_MarketingAskingPrice)),
      cv_MarketingAskingPrice: GenUtil.safeToNumber(AppHelper.getText(i.cv_MarketingAskingPrice)),

      rpt_SqmUnits: GenUtil.safeTrim(AppHelper.getText(i.rpt_SqmUnits)),
      rpt_Vacant: GenUtil.safeTrim(AppHelper.getText(i.rpt_Vacant)),

      rpt_PubDate: GenUtil.safeTrim(AppHelper.getText(i.rpt_PubDate)).replace(/"/gi, "" ),
      cv_PubDate: GenUtil.safeTrim(AppHelper.getText(i.cv_PubDate)),

      rpt_CommAct: GenUtil.safeTrim(AppHelper.getText(i.rpt_CommAct)),
    };
  }

  function mapConnectionDetailsToAssetInfoItem(o: ANConnection): AssetInfoItem {
    return {
        cv_ConnId: GenUtil.safeTrim(o.fields.Connection_x0020_ID),
        rpt_CESAssetID: GenUtil.safeTrim(o.fields.CESID),
        rpt_AssetID: GenUtil.safeTrim(o.fields.Asset_x0020_ID),
        cv_AssetID: GenUtil.safeTrim(o.fields.Asset_x0020_ID),
        rpt_ProjectName: GenUtil.safeTrim(o.fields.ProjectSaleName),
        cv_ProjectName: GenUtil.safeTrim(o.fields.ProjectSaleName),
        rpt_AssetAddress: GenUtil.safeTrim(o.fields.Asset_x0020_District),
        cv_AssetAddr: GenUtil.safeTrim(o.fields.Asset_x0020_District),
        rpt_decimal_RealEstateValue: GenUtil.safeToNumber(
          o.fields.Real_x0020_Estate_x0020_Value
        ),
        cv_REValue: GenUtil.safeToNumber(
          o.fields.Real_x0020_Estate_x0020_Value
        ),
        rpt_District: GenUtil.NVL(
          o.fields.Asset_x0020_Parish,
          o.fields.Asset_x0020_District,
          o.fields.Asset_x0020_Zip_x0020_Code_x0020
        ), // change 9/18/23 use Parish if avail, then District if not
        cv_District: GenUtil.NVL(
          o.fields.Asset_x0020_Parish,
          o.fields.Asset_x0020_District,
          o.fields.Asset_x0020_Zip_x0020_Code_x0020
        ),
        rpt_AssetType: GenUtil.safeTrim(o.fields.AssetType),
        cv_AssetType: GenUtil.safeTrim(o.fields.AssetType),
        rpt_SalesAgreedPrice: GenUtil.safeToNumber(o.fields.SalesAgreedPrice),
        cv_SalesAgreedPrice: GenUtil.safeToNumber(o.fields.SalesAgreedPrice),
        rpt_MarketingAskingPrice: GenUtil.safeToNumber(o.fields.MarketingAskingPrice),
        cv_MarketingAskingPrice: GenUtil.safeToNumber(o.fields.MarketingAskingPrice),
        rpt_SqmUnits: "",
        rpt_Vacant: StaticData.luUnknown,
        rpt_PubDate: "",
        cv_PubDate: "",
        rpt_CommAct: "",
      };
  }

  function handleDeleteItem(id: string) {
    // remove the item from the grid

    setStateRows((p) => {
      return p.filter((o) => !GenUtil.eq(o.rpt_AssetID, id));
    });
  }

  useEffect(() => {
    // delete all rows with the connId sent from parent
    const id = GenUtil.safeTrim(props.removeConnId);

    if (!GenUtil.isNull(id)) {
      setStateRows((p) => {
        return p.filter((o) => !GenUtil.eq(o.cv_ConnId, id)); // when AN is not an REO, this will be a real ConnId, not an asset id, and work as expected.  when AN is a REO, the connection section is hidden therefore the individual connection cannot be deleted this way, the user would delete each asset id using the delete button local in the row
      });
    }
  }, [props.removeConnId]);

  function updateFieldVal(id: string, fieldName: string, fieldVal: any) {
    setStateRows((p) => {
      const t = [...p];
      const idx = t.findIndex((o) => o.rpt_AssetID === id);
      if (idx >= 0) {
        const o = t[idx];
        if (GenUtil.eq(fieldName, "sqm"))
          o.rpt_SqmUnits = GenUtil.safeTrim(fieldVal);
        else if (GenUtil.eq(fieldName, "vacant"))
          o.rpt_Vacant = GenUtil.safeTrim(fieldVal);
        else if (GenUtil.eq(fieldName, "commAct"))
          o.rpt_CommAct = GenUtil.safeTrim(fieldVal);
        else if (GenUtil.eq(fieldName, "pubDate")) {
          o.cv_PubDate = GenUtil.safeTrim(fieldVal).replace(/"/gi, "");
          o.rpt_PubDate = fieldVal ? GenUtil.getCalDate(fieldVal) : "";
        }
      }
      return t;
    });
  }

  function onClickReset() {
    setStateRows([]);
    setStateOptions([...props.options]);
    if (props.options.length > 1) setStateSelItem(undefined);
    // setStateSelItem(undefined); // do not reset this, single value DDLs get stuck, cannot trigger an onchange
  }

  const memoTotalCalc = useMemo(() => {
    let tot: number = 0;
    stateRows.forEach(
      (o) => (tot += GenUtil.safeToNumber(o.rpt_decimal_RealEstateValue))
    );
    return tot;
  }, [stateRows]);

  function colCount() {
    let n = 6;
    n += Consts.isWorkbench() ? 1 : 0;
    n += props.showAssetLocation ? 1 : 0;
    return n;
  }

  return (
    <>
      <Stack tokens={Consts.stackTokens}>
        {!props.isReadOnly && (
          <>
            <Stack
              tokens={Consts.stackTokens}
              horizontal
              className="wbss"
              verticalAlign="baseline"
            >
              <div>Asset ID:</div>
              <Dropdown
                className="w400"
                selectedKey={stateSelItem ? stateSelItem.key : undefined}
                onChange={onChange}
                placeholder={
                  stateOptions.length > 0
                    ? "Select an Asset"
                    : "No Assets Found"
                }
                options={stateOptions}
              />
              <PrimaryButton
                text="Add Asset ID"
                allowDisabledFocus
                onClick={onClickAddID}
                disabled={stateOptions.length <= 0}
              />
              <PrimaryButton
                text="Add All"
                allowDisabledFocus
                onClick={onClickAddAll}
                disabled={stateOptions.length <= 0}
              />
              {stateRows.length > 0 && (
                <PrimaryButton
                  text="Reset"
                  allowDisabledFocus
                  onClick={onClickReset}
                />
              )}
            </Stack>
          </>
        )}

        <table className="sub-table3">
          <thead>
            <tr>
              {Consts.isWorkbench() && (
                <th style={{ color: "purple" }}>{"Connection ID"}</th>
              )}
              <th>{"Servicer Asset ID"}</th>
              <th>{"Project Code"}</th>
              <th>{"Project Name"}</th>
              {props.showAssetLocation && <th>{"Asset Location"}</th>}
              <th>{"Asking Price"}</th>
              <th>{"ESP Value (€)"}</th>
              <th>{"Sales Agreed Price"}</th>
              <th>{"Town"}</th>
              <th>{"Type"}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stateRows.map((o, i) => (
              <AssetInfoRow
                key={i}
                data={o}
                showAssetLocation={props.showAssetLocation}
                showCommercialActivity={props.showCommercialActivity}
                showPublicationDate={props.showPublicationDate}
                handleDeleteItem={handleDeleteItem}
                updateFieldVal={updateFieldVal}
                isReadOnly={props.isReadOnly}
              />
            ))}

            {stateRows.length > 0 && (
              <tr className="sep">
                <td style={{ textAlign: "right" }} colSpan={colCount() - 4}>
                  <Label>{"Total:"}</Label>
                </td>
                <td>
                  <Label className="ms-fontWeight-regular">
                    {GenUtil.numberToCurrency(memoTotalCalc)}
                  </Label>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {Consts.admOvrShowDebugInfo() && (
          <ul className="debug-ul">
            <li>props.data: {JSON.stringify(props.data, null, 2)}</li>
            <li>props.options: {JSON.stringify(props.options, null, 2)}</li>
            <li>stateOptions: {JSON.stringify(stateOptions, null, 2)}</li>
            <li>stateSelItem: {JSON.stringify(stateSelItem, null, 2)}</li>
            <li>stateRows: {JSON.stringify(stateRows, null, 2)}</li>
          </ul>
        )}
      </Stack>
    </>
  );
};
